import { createLogicMiddleware } from 'redux-logic';

import PubSub from '../common/PubSub/PubSub.logic';
import Reservations from '../pages/Reservations/Reservations.logic';
import Equipment from '../pages/Equipment/Equipment.logic';
import Categories from '../pages/Categories/Categories.logic';
import Locations from '../pages/Locations/Locations.logic';
import Settings from '../pages/Settings/Settings.logic';
import Users from '../pages/Users/Users.logic';
import Login from '../pages/Login/Login.logic';
import Loans from '../pages/Loans/Loans.logic';
import Staff from '../pages/Staff/Staff.logic';
import OrganizationsLogic from "../pages/Organizations/Organizations.logic";
import EquipmentManager from '../common/EquipmentManager/EquipmentManager.logic';
import LocationWrapper from '../common/LocationWrapper/LocationWrapper.logic';
import GlobalLocations from '../common/GlobalLocations/GlobalLocations.logic';
import VerificationLogic from "../common/Verification/Verification.logic";

const logics = [
  ...PubSub,
  ...Reservations,
  ...Equipment,
  ...Categories,
  ...Staff,
  ...Locations,
  ...Settings,
  ...Users,
  ...Login,
  ...Loans,
  ...EquipmentManager,
  ...GlobalLocations,
  ...LocationWrapper,
  ...VerificationLogic,
  ...OrganizationsLogic
];

export default createLogicMiddleware(logics);
