import { createGlobalStyle } from 'styled-components';

const UtilitiesGlobalStyles = createGlobalStyle`
  .pull-left {
    float: left;
  }
  
  .pull-right {
    float: right;

    & + & {
      margin-right: 12px;
    }
  }

  .list{
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: 8px;
    }
  }

  .flex {
    display: flex;
    flex: 1;
  }

  .hidden {
    display: none !important;
  }

  .clickable {
    cursor:pointer;
  }

  .text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .no-scrolling {
    overflow:hidden;
  }

  .comma-list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      display: inline-block;
    }

    li:not(:last-child)::after {
      white-space: pre;
      content: ', '
    }
  }

  .basic-list {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0;
    }
  }
`;

export default UtilitiesGlobalStyles;