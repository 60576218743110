import { createGlobalStyle } from 'styled-components';

const BoxSizingGlobalStyles = createGlobalStyle`
  html {
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
`;

export default BoxSizingGlobalStyles;
