import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledBounds = styled.i`
  width: ${props => (props.$width ? `${props.$width}px` : 'auto')};
  height: ${props => (props.$height ? `${props.$height}px` : 'auto')};
  display: flex;
  align-items: center;
  background: transparent;

  svg {
    margin: auto;
  }
`;

const withBounds = WrappedSvg => {
  return class withBounds extends React.Component {
    static propTypes = {
      width: PropTypes.number,
      height: PropTypes.number,
      className: PropTypes.string
    };

    render() {
      const { width, height, className, ...ownProps } = this.props;
      
      return (
        <StyledBounds className={className} $width={width} $height={height}>
          <WrappedSvg {...ownProps} />
        </StyledBounds>
      );
    }
  };
};

export default withBounds;
