import { css } from 'styled-components';

const labels = ['xs', 'sm', 'md', 'lg', 'xl'];

export const media = breakpoints => ({
  ...breakpoints.reduce((a, l, i) => {
    a[labels[i + 1]] = (...rules) => css`
      @media (min-width: ${l}px) {
        ${css(...rules)}
      }
    `;
    return a;
  }, {}),
  [labels[0]]: (...rules) => css`
    @media (max-width: ${breakpoints[0] - 1}px) {
      ${css(...rules)}
    }
  `
});

export const percentageQueries = (props, property, values) => {
  const arr =
    typeof values === 'string' || typeof values === 'number'
      ? [values]
      : values;
  if (arr) {
    return arr
      .map((value, index) => {
        const breakpoint =
          index > 0 &&
          props.theme.breakpoints &&
          props.theme.breakpoints[index - 1]
            ? props.theme.breakpoints[index - 1]
            : null;
        return (
          (breakpoint ? '@media (min-width: ' + breakpoint + ') {' : '') +
          (breakpoint || !index ? property + ': ' + value * 100 + '%;' : '') +
          (breakpoint ? '}' : '')
        );
      })
      .join('');
  }
  return null;
};

export default media;
