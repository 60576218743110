import { combineReducers } from 'redux';

import { images } from '../common/Images/Images.reducers';
import {
  saveImageSuccess,
  deleteImageSuccess,
  patchImagesSuccess
} from '../common/Images/Images.actions';

import {
  FETCH_CATEGORIES,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_ERROR,
  FETCH_CATEGORY_ERROR,
  FETCH_CATEGORY,
  FETCH_CATEGORY_SUCCESS,
  SAVE_CATEGORY,
  SAVE_CATEGORY_SUCCESS,
  SAVE_CATEGORIES,
  SAVE_CATEGORIES_ERROR,
  SAVE_CATEGORIES_SUCCESS,
  SAVE_EQUIPMENT_ATTRIBUTE_SUCCESS,
  SAVE_EQUIPMENT_ATTRIBUTES_SUCCESS,
  SAVE_VARIANT_ATTRIBUTE_SUCCESS,
  SAVE_VARIANT_ATTRIBUTES_SUCCESS,
  REMOVE_CATEGORY_SUCCESS,
  SAVE_CATEGORY_IMAGE_SUCCESS,
  DELETE_CATEGORY_IMAGE_SUCCESS,
  PATCH_CATEGORY_IMAGES_SUCCESS,
  REMOVE_EQUIPMENT_ATTRIBUTE_SUCCESS,
  REMOVE_VARIANT_ATTRIBUTE_SUCCESS,
  SET_SELECTED_CATEGORY,
  REMOVE_CATEGORY,
  CANCEL_REMOVE_CATEGORY
} from './Categories.actions';

// META

const loading = (state = false, action) => {
  switch (action.type) {
    case FETCH_CATEGORIES:
    case SAVE_CATEGORIES:
      return true;

    case FETCH_CATEGORIES_SUCCESS:
    case FETCH_CATEGORIES_ERROR:
    case SAVE_CATEGORIES_SUCCESS:
    case SAVE_CATEGORIES_ERROR:
    case CANCEL_REMOVE_CATEGORY:
      return false;

    default:
      return state;
  }
};

const list = (state = {}, action) => {
  switch (action.type) {
    case FETCH_CATEGORIES_SUCCESS:
      return action.payload.meta;

    default:
      return state;
  }
};

const deletingProcess = (state = false, action) => {
  switch (action.type) {
    case REMOVE_CATEGORY:
      return true;

    case REMOVE_CATEGORY_SUCCESS:
      return false;

    default:
      return state;
  }
};

const meta = combineReducers({
  loading,
  list,
  deletingProcess
});

// ITEMS

const category = (state = {}, action) => {
  switch (action.type) {
    case SAVE_CATEGORIES_SUCCESS:
    case FETCH_CATEGORIES_SUCCESS:
      return Object.assign(state.attributes, {
        id: state.id,
        type: state.type,
        loading: false,
        equipmentCount: state.equipmentCount,
        images:
          state.relationships && state.relationships.images
            ? state.relationships.images.data
            : [],
        brands: brands(state.brands, {
          type: action.type,
          payload: { data: state }
        }),
        equipmentAttributes: equipmentAttributes(state.equipmentAttributes, {
          type: action.type,
          payload: { data: state }
        }),
        variantAttributes: variantAttributes(state.variantAttributes, {
          type: action.type,
          payload: { data: state }
        }),
        relatedCategories: relatedCategories(state.relatedCategories, {
          type: action.type,
          payload: { data: state },
          categories: action.payload.data
        })
      });

    case FETCH_CATEGORY:
      return {
        id: action.id,
        loading: true
      };

    case SAVE_CATEGORY_SUCCESS:
    case FETCH_CATEGORY_SUCCESS:
      return Object.assign(
        {
          id: action.payload.data.id,
          type: action.payload.data.type,
          loading: false
        },
        action.payload.data.attributes,
        {
          images:
            action.payload.data.relationships &&
            action.payload.data.relationships.images
              ? action.payload.data.relationships.images.data
              : [],
          brands: brands(state.brands, action),
          equipmentAttributes: equipmentAttributes(
            state.equipmentAttributes,
            action
          ),
          variantAttributes: variantAttributes(state.variantAttributes, action),
          relatedCategories: relatedCategories(state.relatedCategories, action)
        }
      );

    case SAVE_EQUIPMENT_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        loading: false,
        equipmentAttributes: equipmentAttributes(
          state.equipmentAttributes,
          action
        )
      };

    case REMOVE_EQUIPMENT_ATTRIBUTE_SUCCESS:
    case SAVE_EQUIPMENT_ATTRIBUTE_SUCCESS:
      if (
        state.brandAttributes &&
        action.payload &&
        action.payload.data &&
        String(state.brandAttributes[0]) === String(action.payload.data.id)
      ) {
        return {
          ...state,
          loading: false,
          brands: brands(state.brands, action)
        };
      }

      return {
        ...state,
        equipmentAttributes: equipmentAttributes(
          state.equipmentAttributes,
          action
        )
      };

    case SAVE_VARIANT_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        loading: false,
        variantAttributes: variantAttributes(state.variantAttributes, action)
      };

    case REMOVE_VARIANT_ATTRIBUTE_SUCCESS:
    case SAVE_VARIANT_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        variantAttributes: variantAttributes(state.variantAttributes, action)
      };

    default:
      return state;
  }
};

const items = (state = [], action) => {
  switch (action.type) {
    case FETCH_CATEGORIES_SUCCESS:
      return action.payload.data.map(l => category(l, action));

    case SAVE_CATEGORY_SUCCESS: {
      const current = state.find(item => String(item.id) === String(action.payload.data.id));

      if (current) {
        return state.map(item => String(action.payload.data.id) === String(item.id) ? category(item, action) : item);

      } else {
        return [...state, category({}, action)];
      }
    }
    case SAVE_CATEGORIES_SUCCESS:
      return state.map(oldItem => {
        const newItem = action.payload.data.find(i => String(i.id) === String(oldItem.id));

        return newItem 
          ? category(newItem, action) 
          : oldItem;
      });

    case REMOVE_EQUIPMENT_ATTRIBUTE_SUCCESS:
    case REMOVE_VARIANT_ATTRIBUTE_SUCCESS: {
      const current = state.find(item => String(item.id) === String(action.category));

      if (current) {
        return state.map(item => {
          if (String(current.id) === String(item.id)) {
            return category(item, action);
          }

          return item;
        });
      }

      return state;
    }
    case SAVE_EQUIPMENT_ATTRIBUTE_SUCCESS:
    case SAVE_VARIANT_ATTRIBUTE_SUCCESS: {
      const current = state.find(item => String(item.id) === String(action.payload.data.attributes.category));

      if (current) {
        return state.map(item => {
          if (String(current.id) === String(item.id)) {
            return category(item, action);
          }

          return item;
        });
      }

      return state;
    }
    case SAVE_EQUIPMENT_ATTRIBUTES_SUCCESS:
    case SAVE_VARIANT_ATTRIBUTES_SUCCESS: {
      let current = state.find(item => String(item.id) === String(action.payload.data[0].attributes.category));

      if (current) {
        return state.map(item => {
          if (String(current.id) === String(item.id)) {
            return category(item, action);
          }
          
          return item;
        });
      }

      return state;
    }
    case REMOVE_CATEGORY_SUCCESS:
      return state.filter(item => String(item.id) !== String(parseInt(action.id)));

    default:
      return state;
  }
};

// SELECTED

const selected = (state = {}, action) => {
  switch (action.type) {
    case SAVE_CATEGORY:
      return {
        ...state,
        loading: true
      };
    case FETCH_CATEGORY_SUCCESS:
    case SAVE_CATEGORY_SUCCESS:
    case FETCH_CATEGORY:
    case SAVE_EQUIPMENT_ATTRIBUTE_SUCCESS:
    case SAVE_EQUIPMENT_ATTRIBUTES_SUCCESS:
    case SAVE_VARIANT_ATTRIBUTE_SUCCESS:
    case SAVE_VARIANT_ATTRIBUTES_SUCCESS:
    case REMOVE_EQUIPMENT_ATTRIBUTE_SUCCESS:
    case REMOVE_VARIANT_ATTRIBUTE_SUCCESS:
      return category(state, action);
    case SAVE_CATEGORY_IMAGE_SUCCESS:
      if (String(state.id) !== String(action.category)) {
        return state;
      }

      return Object.assign({}, state, {
        images: images(state.images, saveImageSuccess(action))
      });

    case DELETE_CATEGORY_IMAGE_SUCCESS:
      if (String(state.id) !== String(action.category)) {
        return state;
      }

      return Object.assign({}, state, {
        images: images(state.images, deleteImageSuccess(action))
      });

    case PATCH_CATEGORY_IMAGES_SUCCESS:
      if (String(state.id) !== String(action.category)) {
        return state;
      }

      return Object.assign({}, state, {
        images: images(state.images, patchImagesSuccess(action))
      });

    case REMOVE_CATEGORY:
      return {
        loading: true
      };

    case FETCH_CATEGORY_ERROR:
      return {
        loading: false
      };

    case REMOVE_CATEGORY_SUCCESS:
      return {};

    case SET_SELECTED_CATEGORY:
      return action.payload;

    default:
      return state;
  }
};

// ATTRIBUTES

// TODO: First item relate to current category, the rest inherited from parent categories
const brands = (state = [], action) => {
  switch (action.type) {
    case SAVE_CATEGORY_SUCCESS:
    case FETCH_CATEGORIES_SUCCESS:
    case FETCH_CATEGORY_SUCCESS: {
      const relationships = action.payload.data.relationships;
      if (!relationships || !relationships.equipmentAttributes) {
        return [];
      }
      return relationships.equipmentAttributes.data
        .filter(
          attr =>
            action.payload.data.attributes.brandAttributes.indexOf(attr.id) > -1
        )
        .map(brand => ({
          ...state,
          ...brand,
          attributes: {
            ...brand.attributes,
            values: relationships.equipmentAttributeValues
              ? relationships.equipmentAttributeValues.data.filter(
                  val => brand.attributes.values.indexOf(val.id) >= 0
                )
              : []
          }
        }));
    }
    case SAVE_EQUIPMENT_ATTRIBUTE_SUCCESS:
      return state.map((item, index) => {
        if (index === 0) {
          return {
            ...state[0],
            attributes: {
              ...action.payload.data.attributes,
              values: action.payload.data.relationships.values
                ? action.payload.data.relationships.values.data.filter(
                    val =>
                      action.payload.data.attributes.values.indexOf(val.id) >= 0
                  )
                : []
            }
          };
        }
        return item;
      });
    default:
      return state;
  }
};

const equipmentAttributes = (state = [], action) => {
  switch (action.type) {
    case FETCH_CATEGORIES_SUCCESS:
    case FETCH_CATEGORY_SUCCESS:
    case SAVE_CATEGORY_SUCCESS:
      if (!action.payload.data.relationships.equipmentAttributes) {
        return [];
      }
      return action.payload.data.relationships.equipmentAttributes.data
        .filter(attr => action.payload.data.attributes.brandAttributes.indexOf(attr.id) < 0)
        .map(attr => ({
          ...attr,
          attributes: {
            ...attr.attributes,
            values: action.payload.data.relationships.equipmentAttributeValues
              ? action.payload.data.relationships.equipmentAttributeValues.data.filter(
                  val => attr.attributes.values.indexOf(val.id) >= 0
                )
              : []
          }
        }));
    case REMOVE_EQUIPMENT_ATTRIBUTE_SUCCESS: {
      return state.filter(attr => String(attr.id) !== String(action.id));
    }
    case SAVE_EQUIPMENT_ATTRIBUTE_SUCCESS: {
      const newAttr = {
        ...action.payload.data,
        attributes: {
          ...action.payload.data.attributes,
          values: action.payload.data.relationships.values
            ? action.payload.data.relationships.values.data.filter(
                val => action.payload.data.attributes.values.indexOf(val.id) >= 0
              )
            : []
        }
      };
      let exists = false;
      let equipmentAttributeArr = state.map(attr => {
        if (String(attr.id) === String(newAttr.id)) {
          exists = true;

          return newAttr;
        }

        return attr;
      });

      if (!exists) {
        equipmentAttributeArr.push(newAttr);
      }

      return equipmentAttributeArr;
    }
    case SAVE_EQUIPMENT_ATTRIBUTES_SUCCESS: {
      return state.concat(
        action.payload.data.map(attr => ({
          id: attr.id,
          type: attr.type,
          attributes: {
            ...attr.attributes,
            values: [...attr.relationships.values.data]
          }
        }))
      );
    }
    default:
      return state;
  }
};

const variantAttributes = (state = [], action) => {
  switch (action.type) {
    case FETCH_CATEGORIES_SUCCESS:
    case FETCH_CATEGORY_SUCCESS:
    case SAVE_CATEGORY_SUCCESS:
      if (!action.payload.data.relationships.variantAttributes) {
        return [];
      }
      return action.payload.data.relationships.variantAttributes.data.map(
        attr => ({
          ...attr,
          attributes: {
            ...attr.attributes,
            values: action.payload.data.relationships.variantAttributeValues
              ? action.payload.data.relationships.variantAttributeValues.data.filter(
                  val => attr.attributes.values.indexOf(val.id) >= 0
                )
              : []
          }
        })
      );
    case REMOVE_VARIANT_ATTRIBUTE_SUCCESS: {
      return state.filter(attr => String(attr.id) !== String(action.id));
    }
    case SAVE_VARIANT_ATTRIBUTE_SUCCESS: {
      const newAttr = {
        ...action.payload.data,
        attributes: {
          ...action.payload.data.attributes,
          values: action.payload.data.relationships.values
            ? action.payload.data.relationships.values.data.filter(
                val =>
                  action.payload.data.attributes.values.indexOf(val.id) >= 0
              )
            : []
        }
      };
      let exists = false;
      let variantAttributeArr = state.map(attr => {
        if (String(attr.id) === String(newAttr.id)) {
          exists = true;

          return newAttr;
        }

        return attr;
      });

      if (!exists) {
        variantAttributeArr.push(newAttr);
      }

      return variantAttributeArr;
    }
    case SAVE_VARIANT_ATTRIBUTES_SUCCESS: {
      return state.concat(
        action.payload.data.map(attr => ({
          id: attr.id,
          type: attr.type,
          attributes: {
            ...attr.attributes,
            values: [...attr.relationships.values.data]
          }
        }))
      );
    }
    default:
      return state;
  }
};

const relatedCategories = (state = [], action) => {
  switch (action.type) {
    case SAVE_CATEGORY_SUCCESS:
    case FETCH_CATEGORIES_SUCCESS:
    case FETCH_CATEGORY_SUCCESS: {
      let relationships = action.payload.data.relationships;
      let relatedCategoriesIds =
        action.payload.data.attributes.relatedCategories;

      const categories =
        (relationships.categories && relationships.categories.data) ||
        action.categories ||
        [];

      if (!relatedCategoriesIds.length || !categories || !categories.length) {
        return [];
      }

      let related = categories.filter(
        c => relatedCategoriesIds.indexOf(c.id) > -1
      );

      return related.map(c => ({
        id: c.id,
        value: c.id,
        type: c.type,
        label: c.attributes.name,
        ...c.attributes
      }));
    }
    default:
      return state;
  }
};

// MAIN

const reducer = combineReducers({
  items,
  selected,
  meta
});

reducer.reducer = 'categories';

export default reducer;
