import styled from 'styled-components';

const CloseButton = styled.a`
  position: absolute;
  top: 8px;
  right: 8px;
  display: inline-block;
  width: 18px;
  height: 18px;
  overflow: hidden;

  &::before,
  &::after {
    content: '';
    position: absolute;
    height: 1px;
    width: 50%;
    top: 50%;
    left: 25%;
    margin-top: -1px;
    background-color: ${props => props.$notificationType ? props.theme.colorGrayDarker : props.theme.colorRed};
  }
  
  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`;

export default CloseButton;
