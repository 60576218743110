export const filterStates = [
  'published,temporary',
  'temporary',
  'published',
];

export const defaultFilters = {
  state: 'published,temporary',
};

export const reservationStatuses = [
  'reserved',
  'converted',
  'cancelled',
];

export const loanStatuses = [
  'packed,picked_up,returned,overdue,extended,stolen,damaged,closed',
  'packed',
  'picked_up',
  'returned',
  'overdue',
  'extended',
  'stolen',
  'damaged',
  'closed',
];

export const resolvedStatuses = [
  'returned',
  'stolen',
  'damaged',
  'closed'
];

export const resettableStatuses = [
  'packed',
  'picked_up',
  'overdue',
  'extended',
];

export const extendableStatuses = [
  'picked_up',
  'overdue',
  'extended',
];

export const equipmentInstanceStatus = [
  {
    value: 'available',
    color: 'green',
    transitions: [
      {
        value: 'unavailable',
        color: 'grey'
      },
      {
        value: 'maintenance',
        color: 'blue'
      },
      {
        value: 'damaged',
        color: 'orange'
      },
      {
        value: 'stolen',
        color: 'red'
      },
      {
        value: 'discarded',
        color: 'red'
      }
    ]
  },
  {
    value: 'unavailable',
    color: 'grey',
    transitions: [
      {
        value: 'available',
        color: 'green'
      },
      {
        value: 'maintenance',
        color: 'blue'
      },
      {
        value: 'damaged',
        color: 'orange'
      },
      {
        value: 'stolen',
        color: 'red'
      },
      {
        value: 'discarded',
        color: 'red'
      }
    ]
  },
  {
    value: 'stolen',
    color: 'red',
    transitions: [
      {
        value: 'available',
        color: 'green'
      },
      {
        value: 'unavailable',
        color: 'grey'
      },
      {
        value: 'maintenance',
        color: 'blur'
      },
      {
        value: 'damaged',
        color: 'orange'
      },
      {
        value: 'discarded',
        color: 'red'
      }
    ]
  },
  {
    value: 'damaged',
    color: 'orange',
    transitions: [
      {
        value: 'available',
        color: 'green'
      },
      {
        value: 'unavailable',
        color: 'grey'
      },
      {
        value: 'maintenance',
        color: 'blue'
      },
      {
        value: 'stolen',
        color: 'red'
      },
      {
        value: 'discarded',
        color: 'red'
      }
    ]
  },
  {
    value: 'maintenance',
    color: 'blue',
    transitions: [
      {
        value: 'available',
        color: 'green'
      },
      {
        value: 'unavailable',
        color: 'grey'
      },
      {
        value: 'damaged',
        color: 'orange'
      },
      {
        value: 'stolen',
        color: 'red'
      },
      {
        value: 'discarded',
        color: 'red'
      }
    ]
  },
  {
    value: 'borrowed',
    color: 'lightgreen',
    transitions: []
  },
  {
    value: 'discarded',
    color: 'red',
    disableFilter: true,
    transitions: []
  },
];

export const loanItemsStatuses = [
  {
    value: 'picked_up',
    color: 'green',
    transitions: [
      {
        value: 'packed',
        color: 'green',
      },
      {
        value: 'returned',
        color: 'grey',
      },
      {
        value: 'extended',
        color: 'blue'
      },
      {
        value: 'stolen',
        color: 'red'
      },
      {
        value: 'damaged',
        color: 'orange'
      }
    ]
  },
  {
    value: 'packed',
    color: 'green',
    transitions: [
      {
        value: 'picked_up',
        color: 'green',
      },
      {
        value: 'returned',
        color: 'grey',
      },
      {
        value: 'extended',
        color: 'blue'
      },
      {
        value: 'stolen',
        color: 'red'
      },
      {
        value: 'damaged',
        color: 'orange'
      }
    ]
  },
  {
    value: 'returned',
    color: 'grey',
    transitions: [
      {
        value: 'packed',
        color: 'green',
      },
      {
        value: 'picked_up',
        color: 'green'
      },
      {
        value: 'extended',
        color: 'blue',
      },
      {
        value: 'damaged',
        color: 'orange',
      },
      {
        value: 'stolen',
        color: 'red'
      }
    ]
  },
  {
    value: 'overdue',
    color: 'purple',
    transitions: [
      {
        value: 'returned',
        color: 'grey',
      },
      {
        value: 'extended',
        color: 'blue',
      },
      {
        value: 'stolen',
        color: 'red'
      },
      {
        value: 'damaged',
        color: 'orange',
      }

    ]
  },
  {
    value: 'extended',
    color: 'blue',
    transitions: [
      {
        value: 'returned',
        color: 'grey',
      },
      {
        value: 'stolen',
        color: 'red'
      },
      {
        value: 'damaged',
        color: 'orange',
      }
    ]
  },
  {
    value: 'stolen',
    color: 'red',
    transitions: [
      {
        value: 'packed',
        color: 'green',
      },
      {
        value: 'picked_up',
        color: 'green',
      },
      {
        value: 'returned',
        color: 'grey'
      },
      {
        value: 'damaged',
        color: 'orange',
      }
    ]
  },
  {
    value: 'damaged',
    color: 'orange',
    transitions: [
      {
        value: 'packed',
        color: 'green',
      },
      {
        value: 'picked_up',
        color: 'green'
      },
      {
        value: 'returned',
        color: 'grey'
      },
      {
        value: 'stolen',
        color: 'red',
      }
    ]
  }
];

export default filterStates;
