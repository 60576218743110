import React from 'react';
import PropTypes from 'prop-types';

import Select from '../../../../../../shared/libs/react-select';
import DropDown from '../../../../../assets/icons/drop-down';

import StyledTableFilterSelect from './TableFilterSelect.styled';

const TableFilterSelect = ({ standalone, numbers, dark, alignLeft, className, ...props}) => (
  <StyledTableFilterSelect 
    className={className}
    $standalone={standalone} 
    $alignLeft={alignLeft}
    $numbers={numbers} 
    $dark={dark}
    { ...props }
  >
    <Select
      arrowRenderer={DropDown}
      searchable={false}
      clearable={false}
      { ...props }
    />
  </StyledTableFilterSelect>
);

TableFilterSelect.defaultValues = {
  placeholder: 'Velg BUAOrdning'
};

TableFilterSelect.propTypes = {
  placeholder: PropTypes.string,
  standalone: PropTypes.bool,
  numbers: PropTypes.bool,
  dark: PropTypes.bool,
  alignLeft: PropTypes.bool,
  className: PropTypes.string,
};

export default TableFilterSelect;