import { combineReducers } from 'redux';
import omit from 'lodash/omit';

import { defaultFilters } from '../../../config/status';

import {
  CHANGE_SORT,
  CHANGE_FILTERS,
  CHANGE_SEARCH,
  CHANGE_LOCATION,
  INITIALIZE,
  DESTROY,
  CHANGE_PAGINATION,
  RESET
} from './Table.actions';

const items = (state = {}, action) => {
  switch(action.type) {
    case INITIALIZE:
      return {
        ...state,
        [action.name]: {
          sort: sort(state.sort, action),
          desc: desc(state.desc, action)
        }
      };
    case DESTROY:
      return omit(state, [action.key]);
    case CHANGE_SORT:
      return !state[action.name] ? state : {
        ...state,
        [action.name]: {
          ...state[action.name],
          sort: sort(state[action.name].sort, action),
          desc: desc(state[action.name].desc, action)
        }
      };
    case CHANGE_PAGINATION:
      return !state[action.name] ? state : {
        ...state,
        [action.name]: {
          ...state[action.name],
          limit: limit(state[action.name].limit, action),
          offset: offset(state[action.name].offset, action)
        }
      };
    case RESET:
      return {};
    default:
      return state;
  }
};

export const sort = (state = 'id', action) => {
  switch (action.type) {
    case INITIALIZE:
      return typeof action.sort !== 'undefined' ? action.sort : state;
    case CHANGE_SORT:
      return typeof action.sort !== 'undefined' ? action.sort : state;
    default:
      return state;
  }
}

export const desc = (state = false, action) => {
  switch (action.type) {
    case INITIALIZE:
      return typeof action.desc !== 'undefined' ? action.desc : state;
    case CHANGE_SORT:
      return typeof action.desc !== 'undefined' ? action.desc : state;
    default:
      return state;
  }
}

export const filters = (state = defaultFilters, action) => {
  switch (action.type) {
    case CHANGE_FILTERS:
      return typeof action.filters !== 'undefined' && action.filters !== null ? action.filters : defaultFilters;
    case RESET:
      return defaultFilters;
    default:
      return state;
  }
}

export const currentLocation = (state = null, action ) => {
  switch (action.type) {
    case CHANGE_LOCATION:
      return typeof action.value !== 'undefined' ? +action.value : state;
    case RESET:
      return null;
    default:
      return state;
  }
}

export const search = (state = '', action) => {
  switch (action.type) {
    case CHANGE_SEARCH:
      return typeof action.search !== 'undefined' ? action.search : state;
    case RESET:
      return '';
    default:
      return state;
  }
}

const limit = (state, action) => {
  switch(action.type) {
    case CHANGE_PAGINATION:
      return typeof action.limit !== 'undefined' ? action.limit : null;
    default:
      return state;
  }
}

const offset = (state, action) => {
  switch(action.type) {
    case CHANGE_PAGINATION:
      return typeof action.offset !== 'undefined' ? action.offset : null;
    default:
      return state;
  }
}

export default combineReducers({
  currentLocation,
  search,
  filters,
  items
});
