import styled, { css } from 'styled-components';

import reactSelectCSS from './Select.scss';

const invalid = css`
  .Select-control {
    border: ${props => props.theme.colorRed} solid 1px !important;
  }
`;

export const selectBase = css`
  ${reactSelectCSS.toString()};
  position: relative;

  .Select.is-focused .Select-control,
  .Select.is-open .Select-control {
    border-color: ${props => props.theme.colorGrayLight};
    background-color: ${props => props.theme.colorWhite};
  }

  .Select-arrow-zone {
    width: 40px;
    border-radius: 1px;
    border-left: 1px solid ${props => props.theme.colorGrayLight};
    pointer-events: none;
    padding-left: 0;
    padding-right: 0;
  }

  .Select-menu-outer {
    text-align: left;
  }

  .Select-input > input {
    line-height: 40px;
    height: 40px;
    padding: 0;
  }

  .Select--multi .Select-value {
    margin-top: 8px;
    margin-left: 8px;
  }

  .Select--multi .Select-value-icon {
    padding-left: 8px;
    padding-right: 8px;
    border-right: none;
  }

  .Select--multi .Select-value-label {
    padding-left: 0;
  }

  .discrete {
    color: ${props => props.theme.colorGray};
  }

  .Select-loading {
    margin-right: 8px;
  }

  ${props => props.$invalid ? invalid : null};
`;

const StyledSelect = styled.div`
  ${selectBase};
`;

export default StyledSelect;
