import createCreateLogic from './createCreateLogic'

const reservedPathNames = ['EDIT', 'CREATE'];

const createLogicWithRouteChecks = options => {
  const process = (props, dispatch, done) => {
    const { getState } = props;
    const state = getState();

    const getUrl = selector => {
      return state.locale[selector].route.path;
    };

    const checkLogin = () => {
      return state.login?.loggedIn;
    };

    const isRelativeLocation = (routes) => {
      return routes.find(route => {
        return state.router.location.pathname.split('/').indexOf(route.key) > -1;
      });
    };

    const checkInitSelectWithLocation = selector => {
      let selectId = null;

      if (state[selector]) {
        const { items, selected } = state[selector];
        const pathname = state.router.location.pathname;
        const route = state.locale[selector].route.path;

        if (
          pathname
            .split('/')
            .slice(0, -1)
            .join('/') === route
        ) {
          if (typeof selected.id === 'undefined' && items.length) {
            selectId = items[0].id;

          } else {
            selectId = selected && selected.id ? selected.id : null;
          }
        }

        return selectId;
      }
    };

    const checkInitSelect = selector => {
      let selectId = null;

      if (state[selector]) {
        const { selected, items } = state[selector];
        if (
          state.router.location.pathname === state.locale[selector].route.path
        ) {
          if (typeof selected.id === 'undefined' && items.length) {
            selectId = items[0].id;

          } else {
            selectId = selected && selected.id ? selected.id : null;
          }
        }
      }
      return selectId;
    };

    const checkInitLocation = () => {
      let location = null;

      if (typeof state.router.location.pathname.split('/')[2] !== 'undefined') {
        location = state.router.location.pathname.split('/')[2];

      } else if (state.table?.currentLocation) {
        location = state.table.currentLocation;
        
      } else if (
        window.DEFAULT_LOCATION &&
        state.login?.data?.represents?.find(l => String(l.id) === String(window.DEFAULT_LOCATION))
      ) {
        location = window.DEFAULT_LOCATION;

      } else if (state.login?.data?.represents?.[0].id) {
        location = state.login.data.represents[0].id;
      }

      return location;
    };

    const checkIdParam = (selector, location) => {
      if (
        checkLogin() 
        && state.router.location.pathname.indexOf(state.locale[selector].route.path) === 0
      ) {
        const params = state.router.location.pathname.split('/');

        params.shift();

        if (location) {
          params.shift();
        }
        
        if (params[1] && !isNaN(params[1])) {
          return params[1];
        }
      }

      return false;
    };

    const checkUnselectedIdParam = (selector, location) => {
      if (state.router.location.pathname.indexOf(state.locale[selector].route.path) === 0) {
        const id = checkIdParam(selector, location);

        if (
          id 
          && (
            !state[selector] 
            || typeof state[selector].selected.id === 'undefined' 
            || String(id) !== String(state[selector].selected.id)
          )
        ) {
          return id;
        }
      }

      return false;
    };

    const checkUnselectedVariantParam = (selector, location) => {
      if (
        checkLogin() 
        && state.router.location.pathname.indexOf(state.locale[selector].route.path) === 0
      ) {
        const params = state.router.location.pathname.split('/');
        let id = null;

        params.shift();

        if (location) {
          params.shift();
        }

        // TODO: if url has one of reservedPathNames before third param then order is wrong
        if (
          !params
            .slice(0, 3)
            .find(p => !!~reservedPathNames.indexOf(p.toUpperCase()))
        ) {
          id = params[2] && !isNaN(params[2]) ? params[2] : false;
        }

        if (
          id 
          &&(
            !state[selector] 
            || typeof state[selector].variants.selected.id === 'undefined' 
            || String(id) !== String(state[selector].variants.selected.id)
          )
        ) {
          return id;
        }
      }

      return false;
    };

    const checkUnselectedInstanceParam = (selector, location) => {
      if (
        checkLogin() 
        && state.router.location.pathname.indexOf(state.locale[selector].route.path) === 0
      ) {
        const params = state.router.location.pathname.split('/');
        let id = null;

        params.shift();

        if (location) {
          params.shift();
        }

        // TODO: if url has one of reservedPathNames before fourth param then order is wrong
        if (
          !params
            .slice(0, 4)
            .find(p => !!~reservedPathNames.indexOf(p.toUpperCase()))
        ) {
          id = params[3] && !isNaN(params[3]) ? params[3] : false;
        }

        if (
          id 
          && (
            !state[selector] 
            || typeof state[selector].instances.selected.id === 'undefined' 
            || String(id) !== String(state[selector].instances.selected.id)
          )
        ) {
          return id;
        }
      }

      return false;
    };

    const params = Object.assign({}, props, {
      getUrl,
      checkInitSelect,
      checkIdParam,
      checkUnselectedIdParam,
      checkInitLocation,
      checkInitSelectWithLocation,
      checkUnselectedVariantParam,
      checkUnselectedInstanceParam,
      isRelativeLocation,
      state
    });

    return options.process(params, dispatch, done);
  };

  return createCreateLogic(options, process);
};

export default createLogicWithRouteChecks;
