export * from './LoanItems/LoanItems.actions';

export const FETCH_LOANS = '@@loans/FETCH_LOANS';
export const FETCH_LOANS_SUCCESS = '@@loans/FETCH_LOANS_SUCCESS';
export const FETCH_LOANS_ERROR = '@@loans/FETCH_LOANS_ERROR';
export const CANCEL_FETCH_LOANS = '@@loans/CANCEL_FETCH_LOANS';
export const RESET_LOAN = '@@loans/RESET_LOAN';
export const RESET_LOANS = '@@loans/RESET_LOANS';

export const FETCH_LOAN = '@@loans/FETCH_LOAN';
export const FETCH_LOAN_SUCCESS = '@@loans/FETCH_LOAN_SUCCESS';
export const FETCH_LOAN_ERROR = '@@loans/FETCH_LOAN_ERROR';
export const CANCEL_FETCH_LOAN = '@@loans/CANCEL_FETCH_LOAN';

export const SAVE_LOAN = '@@loans/SAVE_LOAN';
export const SAVE_LOAN_SUCCESS = '@@loans/SAVE_LOAN_SUCCESS';
export const SAVE_LOAN_ERROR = '@@loans/SAVE_LOAN_ERROR';
export const CANCEL_SAVE_LOAN = '@@loans/CANCEL_SAVE_LOAN';
export const SAVE_LOAN_PROGRESS = '@@loans/SAVE_LOAN_PROGRESS';

export const SAVE_LOAN_INTERNAL_NOTES = '@@loans/SAVE_LOAN_INTERNAL_NOTES';

export const REMOVE_LOAN = '@@loans/REMOVE_LOAN';
export const REMOVE_LOAN_SUCCESS = '@@loans/REMOVE_LOAN_SUCCESS';
export const REMOVE_LOAN_ERROR = '@@loans/REMOVE_LOAN_ERROR';
export const CANCEL_REMOVE_LOAN = '@@loans/CANCEL_LOAN_RESERVATION';

export const CLOSE_LOAN = '@@loans/CLOSE_LOAN';
export const CLOSE_LOAN_SUCCESS = '@@loans/CLOSE_LOAN_SUCCESS';
export const CLOSE_LOAN_ERROR = '@@loans/CLOSE_LOAN_ERROR';
export const CANCEL_CLOSE_LOAN = '@@loans/CANCEL_CLOSE_LOAN';

export const TABLE_SELECT_LOAN = '@@loans/TABLE_SELECT_LOAN';

export const PICK_UP_LOAN = '@@loans/PICK_UP_LOAN';
export const PICK_UP_LOAN_ERROR = '@@loans/PICK_UP_LOAN_ERROR';
export const CANCEL_PICK_UP_LOAN = '@@loans/CANCEL_PICK_UP_LOAN';

export const RETURN_LOAN = '@@loans/RETURN_LOAN';
export const RETURN_LOAN_ERROR = '@@loans/RETURN_LOAN_ERROR';
export const CANCEL_RETURN_LOAN = '@@loans/CANCEL_RETURN_LOAN';

export const selectLoanTable = id => ({
  type: TABLE_SELECT_LOAN,
  id
});

export const fetchLoans = config => ({
  type: FETCH_LOANS,
  ...config
});

export const fetchLoansSuccess = resp => ({
  type: FETCH_LOANS_SUCCESS,
  payload: resp
});

export const fetchLoansError = err => ({
  type: FETCH_LOANS_ERROR,
  payload: err
});

export const fetchLoan = config => ({
  type: FETCH_LOAN,
  ...config
});

export const fetchLoanSuccess = resp => ({
  type: FETCH_LOAN_SUCCESS,
  payload: resp
});

export const fetchLoanError = err => ({
  type: FETCH_LOAN_ERROR,
  payload: err
});

export const saveLoan = (data, nextStep) => ({
  type: SAVE_LOAN,
  payload: data,
  nextStep
});

export const saveLoanSuccess = (resp, nextStep, id) => ({
  type: SAVE_LOAN_SUCCESS,
  payload: resp,
  nextStep,
  id
});

export const saveLoanError = (id, err) => ({
  type: SAVE_LOAN_ERROR,
  id,
  payload: err
});

export const saveLoanProgress = (status, data) => ({
  type: SAVE_LOAN_PROGRESS,
  status,
  data
});

export const saveLoanInternalNotes = (data, nextStep) => ({
  type: SAVE_LOAN_INTERNAL_NOTES,
  payload: data,
  nextStep
});

export const removeLoan = payload => ({
  type: REMOVE_LOAN,
  ...payload
});

export const removeLoanSuccess = payload => ({
  type: REMOVE_LOAN_SUCCESS,
  ...payload
});

export const removeLoanError = err => ({
  type: REMOVE_LOAN_ERROR,
  payload: err
});

export const closeLoan = payload => ({
  type: CLOSE_LOAN,
  ...payload
});

export const closeLoanSuccess = (id, payload) => ({
  type: CLOSE_LOAN_SUCCESS,
  id,
  payload
});

export const closeLoanError = err => ({
  type: CLOSE_LOAN_ERROR,
  payload: err
});

export const resetLoan = () => ({
  type: RESET_LOAN
});

export const resetLoans = () => ({
  type: RESET_LOANS
});

export const pickupLoan = config => ({
  type: PICK_UP_LOAN,
  ...config
});

export const pickupLoanError = err => ({
  type: PICK_UP_LOAN_ERROR,
  payload: err
});

export const returnLoan = config => ({
  type: RETURN_LOAN,
  ...config
});

export const returnLoanError = err => ({
  type: RETURN_LOAN_ERROR,
  payload: err
});
