import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { AuthContext } from '.';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import { withRouter } from '../hoc';
import { useIntl } from 'react-intl';
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';

const locationHelper = locationHelperBuilder({});

export const AuthProvider = ({ children }) => {
    const intl = useIntl();

    const withLoginCheck = useMemo(() => connectedRouterRedirect({
        wrapperDisplayName: 'LoggedIn',
        redirectPath: intl.formatMessage({ id: 'login.route.path' }),
        authenticatedSelector: state => !!state.login?.loggedIn,
        allowRedirectBack: true,
    }), [ intl ]);

    const withLogoutCheck = useMemo(() => connectedRouterRedirect({
        wrapperDisplayName: 'LoggedOut',
        redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/',
        authenticatedSelector: state => !state.login?.loggedIn,
        allowRedirectBack: false,
    }), []);

    const checkAuth = useCallback((component, loginRequired, logoutRequired) => {
        if (loginRequired) {
            return withRouter(withLoginCheck(component));
        } else if (logoutRequired) {
            return withRouter(withLogoutCheck(component));
        }

        return component;
    }, [ withLoginCheck, withLogoutCheck ]);

    return (
        <AuthContext.Provider value={{checkAuth}}>
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.node,
};
