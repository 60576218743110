export const timeout = 30000;

// Tail 'R' means that URL have variable to replace by data before request
export const vipps = {
  // To initiate redirect to vipps or send notification to guardian
  // To verify guardian, use url + ?verification_code=some_code_that_user_or_guardian_got_by_email
  verify: '/verification/vipps/verify',
  verifyAnon: '/verification/anon/vipps/verify',

  // To get info that is required for further registration or other actions after verification
  callback: '/verification/vipps/callback',
  callbackAnon: '/verification/anon/vipps/callback',

  // Get details of verification by verificationCode
  getDataR: '/verification/vipps/data/{verificationCode}',

  // To generate verification code and notification for email(for current user)
  generateEmailVerification: '/users/verify-email/generate',

  // To generate verification code and notification for phone(for current user)
  generatePhoneVerification: '/users/verify-phone/generate',

  // To validate verification code for mail
  // { data: { verificationCode: some_code_that_user_get_from_email }}
  verifyByEmail: '/users/verify-email',

  // To validate verification code for phone
  // { data: { verificationCode: some_code_that_user_get_from_email }}
  verifyByPhone: '/users/verify-phone',

  // To send notification to the user that he/she needs to verify account
  sendVerificationMessageR: '/verification/vipps/send/{userId}'
};

export const PHONE_TYPE = 'mobilePhone';
export const EMAIL_TYPE = 'email';

export const VERIFY_FORM_NAME = 'verifyForm';
export const VERIFY_FORM_PHONE_CODE = 'phoneCode';
export const VERIFY_FORM_EMAIL_CODE = 'emailCode';
