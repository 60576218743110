export const cookies = {
  token: {
    name: 'BUA_ADMIN_TOKEN',
    expires: 1
  },
  refresh_token: {
    name: 'BUA_ADMIN_REFRESH_TOKEN',
    expires: 1
  },
  csrf_token: {
    name: 'crsf_token',
    expires: 1
  }
};

export const heartbeat = {
  intervalTime: 60000,
  refetchTime: 1800000
};
