import { CHANGE_LOCATION_ACTION } from '../../../../shared/config/locations';

export const INITIALIZE = '@@Table/INITIALIZE';
export const DESTROY    = '@@Table/DESTROY';
export const CHANGE_SORT = '@@Table/CHANGE_SORT';
export const CHANGE_FILTERS = '@@Table/CHANGE_FILTERS';
export const CHANGE_SEARCH = '@@Table/CHANGE_SEARCH';
export const CHANGE_LOCATION = CHANGE_LOCATION_ACTION;
export const CHANGE_PAGINATION = '@@Table/CHANGE_PAGINATION';
export const RESET = '@@Table/RESET';

export function initialize(config) {
  return {
    type: INITIALIZE,
    ...config
  }
}

export function destroy(key) {
  return {
    type: DESTROY,
    key
  }
}

export function changeSort(name, sort, desc) {
  return {
    type: CHANGE_SORT,
    name,
    sort,
    desc
  };
}

export function changeFilters(filters) {
  return {
    type: CHANGE_FILTERS,
    filters
  };
}

export function changeSearch(search) {
  return {
    type: CHANGE_SEARCH,
    search
  };
}

export function changeLocation(value) {
  return {
    type: CHANGE_LOCATION,
    value
  };
}

export function changePagination(name, {offset, limit}) {
  return {
    type: CHANGE_PAGINATION,
    name,
    offset,
    limit
  };
}

export function resetTable() {
  return {
    type: RESET
  }
}
