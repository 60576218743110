import moment from 'moment';

import { FETCH_LOCATION_SUCCESS, SAVE_LOCATION_SUCCESS } from '../../Locations/Locations.actions';
import { FETCH_LOCATIONS_OPENING_HOURS_SUCCESS } from '../../Locations/LocationsOpeningHours/LocationsOpeningHours.actions';
import {
  FETCH_SETTINGS_SUCCESS,
  FETCH_SETTINGS_OPENING_HOURS_SUCCESS
} from '../../../config/locations';

const sortByCompareDate = (a, b) =>
  a.attributes.compareDate - b.attributes.compareDate;

export const openingHours = (state = [], action) => {
  switch (action.type) {
    case FETCH_LOCATION_SUCCESS:
    case SAVE_LOCATION_SUCCESS:
    case FETCH_SETTINGS_SUCCESS: {
      const {
        data: { relationships }
      } = action.payload;

      if (relationships && relationships.openingHours) {
        const {
          openingHours: { data }
        } = relationships;

        return data.filter(({ attributes: { date } }) => !date);
      }
      return [];
    }
    case FETCH_LOCATIONS_OPENING_HOURS_SUCCESS:
    case FETCH_SETTINGS_OPENING_HOURS_SUCCESS:
      return action.payload.data.filter(({ attributes: { date } }) => !date);
    default:
      return state;
  }
};

export const closedDays = (state = [], action) => {
  switch (action.type) {
    case FETCH_LOCATION_SUCCESS:
    case SAVE_LOCATION_SUCCESS:
    case FETCH_SETTINGS_SUCCESS: {
      const {
        data: { relationships }
      } = action.payload;

      if (relationships && relationships.openingHours) {
        let {
          openingHours: { data }
        } = relationships;

        return data
          .filter(
            ({ attributes: { date, openingTime, closingTime } }) =>
              date && openingTime === '00:00' && closingTime === '00:00'
          )
          .map(item => {
            const dateObj = moment(item.attributes.date);
            return {
              ...item,
              attributes: {
                ...item.attributes,
                compareDate: dateObj.format('YYYYMMDD'),
                date: dateObj.format('YYYY-MM-DD')
              }
            };
          })
          .sort(sortByCompareDate);
      }
      return [];
    }
    case FETCH_SETTINGS_OPENING_HOURS_SUCCESS:
    case FETCH_LOCATIONS_OPENING_HOURS_SUCCESS:
      return action.payload.data
        .filter(
          ({ attributes: { date, openingTime, closingTime } }) =>
            date && openingTime === '00:00' && closingTime === '00:00'
        )
        .map(item => {
          const dateObj = moment(item.attributes.date);
          return {
            ...item,
            attributes: {
              ...item.attributes,
              compareDate: dateObj.format('YYYYMMDD'),
              date: dateObj.format('YYYY-MM-DD')
            }
          };
        })
        .sort(sortByCompareDate);
    default:
      return state;
  }
};

export const specialOpeningHours = (state = [], action) => {
  switch (action.type) {
    case FETCH_LOCATION_SUCCESS:
    case SAVE_LOCATION_SUCCESS:
    case FETCH_SETTINGS_SUCCESS: {
      const {
        data: { relationships }
      } = action.payload;

      if (relationships && relationships.openingHours) {
        let {
          openingHours: { data }
        } = relationships;

        return data
          .filter(
            ({ attributes: { date, openingTime, closingTime } }) =>
              date && !(openingTime === '00:00' && closingTime === '00:00')
          )
          .map(item => {
            const dateObj = moment(item.attributes.date);
            return {
              ...item,
              attributes: {
                ...item.attributes,
                compareDate: dateObj.format('YYYYMMDD'),
                date: dateObj.format('YYYY-MM-DD')
              }
            };
          })
          .sort(sortByCompareDate);
      }
      return state;
    }
    case FETCH_SETTINGS_OPENING_HOURS_SUCCESS:
    case FETCH_LOCATIONS_OPENING_HOURS_SUCCESS:
      return action.payload.data
        .filter(
          ({ attributes: { date, openingTime, closingTime } }) =>
            date && !(openingTime === '00:00' && closingTime === '00:00')
        )
        .map(item => {
          const dateObj = moment(item.attributes.date);
          return {
            ...item,
            attributes: {
              ...item.attributes,
              compareDate: dateObj.format('YYYYMMDD'),
              date: dateObj.format('YYYY-MM-DD')
            }
          };
        })
        .sort(sortByCompareDate);
    default:
      return state;
  }
};
