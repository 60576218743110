import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import LoadingGfx from '../Loading/Loading.component';

import StyledLoadingAnimation from './LoadingAnimation.styled';

const LoadingAnimation = ({ margin, ...props }) => {
  return (
    <StyledLoadingAnimation {...props} $margin={margin} tabIndex={-1}>
      <LoadingGfx {...props} />
      {props.info && <FormattedMessage tagName="small" id={props.info} />}
    </StyledLoadingAnimation>
  );
};

LoadingAnimation.defaultProps = {
  margin: 'auto',
  fill: '#d9dee3',
};

LoadingAnimation.propTypes = {
  margin: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
  info: PropTypes.string
};

export default LoadingAnimation;
