import { combineReducers } from 'redux';

import {
  SHOW_CONFIRM,
  CANCEL_CONFIRM,
  CLOSE_CONFIRM,
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  CLEAR_NOTIFICATIONS
} from './Dialogs.actions';

const confirm = (state = false, action) => {
  switch (action.type) {
    case SHOW_CONFIRM:
      return action.payload;
    case CLOSE_CONFIRM:
    case CANCEL_CONFIRM:
      return false;
    default:
      return state;
  }
};

const notifications = (state = [], action) => {
  switch (action.type) {
    case SHOW_NOTIFICATION:
      return [...state, action.payload];
    case HIDE_NOTIFICATION:
      return state.slice(0, -1);
    case CLEAR_NOTIFICATIONS:
      return [];
    default:
      return state;
  }
};

// MAIN
const reducer = combineReducers({
  confirm,
  notifications
});

reducer.reducer = 'dialogs';

export default reducer;
