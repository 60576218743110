export const FETCH_EQUIPMENT_INSTANCES = '@@equipment/FETCH_EQUIPMENT_INSTANCES';
export const FETCH_EQUIPMENT_INSTANCES_SUCCESS = '@@equipment/FETCH_EQUIPMENT_INSTANCES_SUCCESS';
export const FETCH_EQUIPMENT_INSTANCES_ERROR = '@@equipment/FETCH_EQUIPMENT_INSTANCES_ERROR';
export const CANCEL_FETCH_EQUIPMENT_INSTANCES = '@@equipment/CANCEL_FETCH_EQUIPMENT_INSTANCES';

export const FETCH_EQUIPMENT_INSTANCE = '@@equipment/FETCH_EQUIPMENT_INSTANCE';
export const FETCH_EQUIPMENT_INSTANCE_SUCCESS = '@@equipment/FETCH_EQUIPMENT_INSTANCE_SUCCESS';
export const FETCH_EQUIPMENT_INSTANCE_ERROR = '@@equipment/FETCH_EQUIPMENT_INSTANCE_ERROR';
export const CANCEL_FETCH_EQUIPMENT_INSTANCE = '@@equipment/CANCEL_FETCH_EQUIPMENT_INSTANCE';

export const SAVE_EQUIPMENT_INSTANCES = '@@equipment/SAVE_EQUIPMENT_INSTANCES';
export const SAVE_EQUIPMENT_INSTANCES_SUCCESS = '@@equipment/SAVE_EQUIPMENT_INSTANCES_SUCCESS';
export const SAVE_EQUIPMENT_INSTANCES_ERROR = '@@equipment/SAVE_EQUIPMENT_INSTANCES_ERROR';
export const CANCEL_SAVE_EQUIPMENT_INSTANCES = '@@equipment/CANCEL_SAVE_EQUIPMENT_INSTANCES';

export const REMOVE_EQUIPMENT_INSTANCE = '@@equipment/REMOVE_EQUIPMENT_INSTANCE';
export const REMOVE_EQUIPMENT_INSTANCE_SUCCESS = '@@equipment/REMOVE_EQUIPMENT_INSTANCE_SUCCESS';
export const REMOVE_EQUIPMENT_INSTANCE_ERROR = '@@equipment/REMOVE_EQUIPMENT_INSTANCE_ERROR';
export const CANCEL_REMOVE_EQUIPMENT_INSTANCE = '@@equipment/CANCEL_REMOVE_EQUIPMENT_INSTANCE';

export const SAVE_EQUIPMENT_INSTANCE = '@@equipment/SAVE_EQUIPMENT_INSTANCE';
export const SAVE_EQUIPMENT_INSTANCE_SUCCESS = '@@equipment/SAVE_EQUIPMENT_INSTANCE_SUCCESS';
export const SAVE_EQUIPMENT_INSTANCE_ERROR = '@@equipment/SAVE_EQUIPMENT_INSTANCE_ERROR';
export const CANCEL_SAVE_EQUIPMENT_INSTANCE = '@@equipment/CANCEL_SAVE_EQUIPMENT_INSTANCE';

export const RESET_EQUIPMENT_INSTANCE = '@@equipment/RESET_EQUIPMENT_INSTANCE';

export const saveEquipmentInstances = (payload, nextStep) => ({
  type: SAVE_EQUIPMENT_INSTANCES,
  payload,
  nextStep
});

export const saveEquipmentInstancesSuccess = (payload, nextStep) => ({
  type: SAVE_EQUIPMENT_INSTANCES_SUCCESS,
  payload,
  nextStep
});

export const saveEquipmentInstancesError = err => ({
  type: SAVE_EQUIPMENT_INSTANCES_ERROR,
  payload: err
});

export const fetchEquipmentInstance = config => ({
  type: FETCH_EQUIPMENT_INSTANCE,
  ...config
});

export const fetchEquipmentInstanceSuccess = resp => ({
  type: FETCH_EQUIPMENT_INSTANCE_SUCCESS,
  payload: resp
});

export const fetchEquipmentInstanceError = err => ({
  type: FETCH_EQUIPMENT_INSTANCE_ERROR,
  payload: err
});

export const saveEquipmentInstance = (payload, nextStep) => ({
  type: SAVE_EQUIPMENT_INSTANCE,
  payload,
  nextStep
});

export const saveEquipmentInstanceSuccess = (payload, nextStep) => ({
  type: SAVE_EQUIPMENT_INSTANCE_SUCCESS,
  payload,
  nextStep
});

export const saveEquipmentInstanceError = err => ({
  type: SAVE_EQUIPMENT_INSTANCE_ERROR,
  payload: err
});

export const removeEquipmentInstance = id => ({
  type: REMOVE_EQUIPMENT_INSTANCE,
  id
});

export const removeEquipmentInstanceSuccess = (equipmentId, variantId, id) => ({
  type: REMOVE_EQUIPMENT_INSTANCE_SUCCESS,
  id,
  variantId,
  equipmentId
});

export const removeEquipmentInstanceError = err => ({
  type: REMOVE_EQUIPMENT_INSTANCE_ERROR,
  payload: err
});

export const resetEquipmentInstance = () => ({
  type: RESET_EQUIPMENT_INSTANCE,
});
