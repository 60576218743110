import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';

export const getLocations = (state, onlyVisibleMap=false) => state.locations && state.locations.items 
  ? onlyVisibleMap
    ? state.locations.items.filter(i => i.visibleMap)
    : state.locations.items 
  : undefined;

export const getLoginRepresent = state => state.login && state.login.data 
  ? state.login.data.represents 
  : undefined;

export const getGlobalLocations = state => state.globalLocations && state.globalLocations.items
  ? state.globalLocations.items
  : undefined;

export const getSelected = state => state.locations && state.locations.selected
  ? state.locations.selected
  : undefined;

export const getMarkerLocation = state => state.locationSelector && state.locationSelector.markerLocation
  ? state.locationSelector.markerLocation
  : undefined;

export const getListTotal = state => state.locations && state.locations.meta && state.locations.meta.list
  ? state.locations.meta.list.total
  : 0;

export const getSelectedWithEntrances = createSelector(
  [getSelected],
  location =>
    location
      ? {
          ...location,
          entrances:
            Array.isArray(location.entrances) &&
            location.entrances.map(entrance => ({
              ...entrance,
              attributes: {
                ...entrance.attributes,
                ...(typeof entrance.attributes.config === 'string' &&
                entrance.attributes.config !== '' &&
                entrance.attributes.config !== 'Array'
                  ? { config: JSON.parse(entrance.attributes.config) }
                  : {})
              }
            }))
        }
      : undefined
);

export const getGlobalLocationsWithoutMapEntries = createSelector(
  [getGlobalLocations],
  locations => locations.filter(l => l.type !== 'mapEntry')
);

export const getLocationsWithoutMapEntries = createSelector(
  [getLocations],
  locations => locations.filter(l => l.type !== 'mapEntry')
);

export const getRepresentedLocations = createSelector(
  [getGlobalLocations, getLoginRepresent],
  (locations, represents) =>
    locations.filter(
      location => represents && represents.find(rep => String(location.id) === String(rep.id))
    )
);

export const getMarkerLocationWithSortedImages = createSelector(
  [getMarkerLocation],
  location => ({
    ...location,
    images:
      Array.isArray(location.images) &&
      location.images.sort(
        (a, b) => a.attributes.sortOrder - b.attributes.sortOrder
      )
  })
);

export const getGlobalLocationFromId = createCachedSelector(
  [getGlobalLocations, (state, id) => id],
  (globalLocations, id) => globalLocations.find(location => String(location.id) === String(id))
)((state, id) => `globalLocation-${id}`);

export const checkClosedDate = createCachedSelector(
  [location => location, (location, date) => date],
  (location, date) => {
    const dow = date.weekday(),
      dateStr = date.format('YYYY-MM-DD');
    return (
      !(
        isDeviantlyOpened(location, dateStr) ||
        (location.openingHours[dow] &&
          location.openingHours[dow].attributes.isActive)
      ) || !!isDayBlocked(location, dateStr)
    );
  }
)(
  (location, date) =>
    `openingHoursCheck-${location.id}-${date.format('YYYY-MM-DD')}`
);

const isDeviantlyOpened = ({ specialOpeningHours }, currentDate) =>
  specialOpeningHours &&
  specialOpeningHours.find(
    ({ attributes: { date, isActive } }) => isActive && currentDate === date
  );

const isDayBlocked = ({ closedDays }, currentDate) =>
  closedDays &&
  closedDays.find(({ attributes: { date } }) => currentDate === date);
