import Api from '../../../../../../shared/api';
import { locations as apiConfig } from '../../../../../config/api';
import {
  saveLocationsEntranceSuccess,
} from '../../../../../../shared/components/Locations/LocationsEntrance/LocationsEntrance.actions';

export const saveEntrance = (dispatch, action, entrance) => {
  const { entranceInfo } = action.payload,
        exitingEntrance = !!entrance && entrance.attributes.type === 'info' && entrance;

  return Api({
    method: exitingEntrance ? 'PATCH' : 'POST',
    path: `${apiConfig.path}/${action.id}${apiConfig.entrancePath}${exitingEntrance ? `/${exitingEntrance.id}` : ''}`,
    handle: r => r.send({
      attributes: {
        state: 'published',
        type: 'info',
        content: entranceInfo,
      }
    })
  })
  .then(resp => dispatch(saveLocationsEntranceSuccess(resp, action.id)))
};
