import { createLogicWithApi } from '../../../../shared/logicCreators';
import { vipps } from '../../../../shared/config/api';
import {
  EMAIL_TYPE,
  PHONE_TYPE,
  VERIFY_FORM_EMAIL_CODE,
  VERIFY_FORM_PHONE_CODE,
} from '../../../../shared/config/api';

import {
  CANCEL_SEND_VERIFY_CODE,
  CANCEL_SEND_VERIFY_MESSAGE,
  CANCEL_VALIDATE_VERIFY_CODE,
  SEND_VERIFY_CODE,
  SEND_VERIFY_MESSAGE,
  VALIDATE_VERIFY_CODE,
  sendVerificationMessageError,
  sendVerificationMessageSuccess,
  sendVerifyCodeError,
  sendVerifyCodeSuccess,
  validateVerifyCodeError,
  validateVerifyCodeSuccess
} from './Verification.actions';

const sendVerifyMessageLogic = createLogicWithApi({
  type: SEND_VERIFY_MESSAGE,
  cancelType: CANCEL_SEND_VERIFY_MESSAGE,
  process: ({ action, Api }, dispatch, done) => {
    return Api({
      method: 'POST',
      path: vipps.sendVerificationMessageR.replace('{userId}', action.payload),
    })
      .then(resp => {
        dispatch(sendVerificationMessageSuccess({ resp, id: action.payload }));
        done();
      })
      .catch(err => {
        dispatch(sendVerificationMessageError({ err, id: action.payload }));
        done();
      });
  },
});

const sendVerifyCodeLogic = createLogicWithApi({
  type: SEND_VERIFY_CODE,
  cancelType: CANCEL_SEND_VERIFY_CODE,
  process: ({ action: { payload }, Api }, dispatch, done) => {
    const url = payload.type === PHONE_TYPE 
      ? vipps.generatePhoneVerification 
      : vipps.generateEmailVerification;

    return Api({
      method: 'POST',
      path: `${url}/${payload.id}`,
      requireLogin: true
    })
      .then(resp => {
        dispatch(sendVerifyCodeSuccess({ type: payload.type, resp }));
        done();
      })
      .catch(err => {
        dispatch(sendVerifyCodeError({ type: payload.type, err }));
        done();
      });
  },
});

const validateVerifyCodeLogic = createLogicWithApi({
  type: VALIDATE_VERIFY_CODE,
  cancelType: CANCEL_VALIDATE_VERIFY_CODE,
  process: ({ action: { payload: { type, formName, id } }, getState, Api }, dispatch, done) => {
    const url = type === PHONE_TYPE ? vipps.verifyByPhone : vipps.verifyByEmail;
    const matching = {
      [PHONE_TYPE]: VERIFY_FORM_PHONE_CODE,
      [EMAIL_TYPE]: VERIFY_FORM_EMAIL_CODE
    };

    const { form } = getState();
    let code = '';

    if (form[formName] && form[formName].values) {
      code = form[formName].values[matching[type]];
    }

    return Api({
      method: 'POST',
      path: `${url}/${id}`,
      requireLogin: true,
      handle: request => {
        return request.send({
          verificationCode: code
        });
      }
    })
      .then(resp => {
        dispatch(validateVerifyCodeSuccess({ type, resp }));
        done();
      })
      .catch(err => {
        dispatch(validateVerifyCodeError({ type, err }));
        done();
      });
  }
});


const logics = [
  sendVerifyMessageLogic,
  sendVerifyCodeLogic,
  validateVerifyCodeLogic
];

export default logics;