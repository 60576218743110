export const URL_CHANGE = '@@app/URL_CHANGE';
export const PATH_CHANGE = '@@app/PATH_CHANGE';

export const urlChange = location => ({
  type: URL_CHANGE,
  location
});

export const pathChange = location => ({
  type: PATH_CHANGE,
  location
});
