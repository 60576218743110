export const FETCH_GLOBAL_LOCATIONS                      = '@@globalLocations/FETCH_GLOBAL_LOCATIONS';
export const FETCH_GLOBAL_LOCATIONS_SUCCESS              = '@@globalLocations/FETCH_GLOBAL_LOCATIONS_SUCCESS';
export const FETCH_GLOBAL_LOCATIONS_ERROR                = '@@globalLocations/FETCH_GLOBAL_LOCATIONS_ERROR';
export const CANCEL_FETCH_GLOBAL_LOCATIONS               = '@@globalLocations/CANCEL_FETCH_GLOBAL_LOCATIONS';

export const fetchGlobalLocations = () => ({
  type: FETCH_GLOBAL_LOCATIONS
});

export const fetchGlobalLocationsSuccess = (resp) => ({
  type: FETCH_GLOBAL_LOCATIONS_SUCCESS,
  payload: resp
});

export const fetchGlobalLocationsError = (err) => ({
  type: FETCH_GLOBAL_LOCATIONS_ERROR,
  payload: err
});
