import { createGlobalStyle } from 'styled-components';
import { normalize } from 'polished';

const NormalizeGlobalStyles = createGlobalStyle`
  ${normalize()};

  input[type="text"]::-ms-clear,
  input[type="password"]::-ms-clear,
  input[type="search"]::-ms-clear,
  input[type="text"]::-webkit-search-cancel-button,
  input[type="password"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-cancel-button {
    display: none;
  }
`;

export default NormalizeGlobalStyles;
