import Promise from 'pinkie-promise';

import { saveEntrance as saveUnlocEntrance } from '../../pages/Locations/EntranceTypes/EntranceUnloc/EntranceUnloc.logic';
import { saveEntrance as saveInfoEntrance } from '../../pages/Locations/EntranceTypes/EntranceInfo/EntranceInfo.logic';
import { getPrioritizedEntrance, getEntranceType } from '../../pages/Locations/EntranceTypes/EntranceTypes.selectors';
import { settings as settingsApiConfig, locations as locationsApiConfig } from '../../../config/api';
import { createLogicWithApi } from '../../../../shared/logicCreators';
import {
  selectDeletedLocationsOpeningHours,
  selectUpdatedLocationsOpeningHours,
  selectCreatedLocationsOpeningHours
} from '../../../../shared/components/Locations/LocationsOpeningHours/LocationsOpeningHours.selectors';
import {
  getLocationsOpeningHoursRequests
} from '../../pages/Locations/Locations.logic.js';

import {
  FETCH_SETTINGS,
  CANCEL_FETCH_SETTINGS,
  fetchSettingsSuccess,
  fetchSettingsError,
  SAVE_SETTINGS,
  CANCEL_SAVE_SETTINGS,
  saveSettingsSuccess,
  saveSettingsError,
  SAVE_SETTINGS_OPENING_HOURS,
  CANCEL_SAVE_SETTINGS_OPENING_HOURS,
  saveSettingsOpeningHoursError,
  FETCH_SETTINGS_OPENING_HOURS,
  CANCEL_FETCH_SETTINGS_OPENING_HOURS,
  fetchSettingsOpeningHours,
  fetchSettingsOpeningHoursSuccess,
  SAVE_SETTINGS_ENTRANCE,
  CANCEL_SAVE_SETTINGS_ENTRANCE,
  saveSettingsEntranceSuccess,
  saveSettingsEntranceError,
  fetchSettingsEntrances,
  FETCH_SETTINGS_ENTRANCES,
  CANCEL_FETCH_SETTINGS_ENTRANCES,
  fetchSettingsEntrancesSuccess,
  fetchSettingsEntrancesError,
} from './Settings.actions';


const fetchSettingsLogic = createLogicWithApi({
  type: FETCH_SETTINGS,
  cancelType: CANCEL_FETCH_SETTINGS,
  latest: true,
  process: ({ action, Api }, dispatch, done) => {
    return Api({
      method: 'get',
      path: `${locationsApiConfig.path}/${action.id}${settingsApiConfig.path}`,
    })
    .then(resp => {
      dispatch(fetchSettingsSuccess(resp));
      dispatch(fetchSettingsEntrances(action.id));
      done();
    })
    .catch(err => {
      dispatch(fetchSettingsError(err));
      done();
    });
  }
});

const saveSettingsLogic = createLogicWithApi({
  type: SAVE_SETTINGS,
  cancelType: CANCEL_SAVE_SETTINGS,
  process: ({ action, Api }, dispatch, done) => {
    return Api({
      method: 'patch',
      path: `${locationsApiConfig.path}/${action.payload.id}${settingsApiConfig.path}`,
      handle: r => {
        return r.send({
          attributes: action.payload,
          type: 'location',
          id: action.payload.id ? action.payload.id : null
        });
      }
    })
    .then(resp => {
      dispatch(saveSettingsSuccess(resp, action.nextStep));
      done();
    })
    .catch(err => {
      dispatch(saveSettingsError(err));
      done();
    });
  }
});

const saveSettingsOpeningHoursLogic = createLogicWithApi({
  type: SAVE_SETTINGS_OPENING_HOURS,
  cancelType: CANCEL_SAVE_SETTINGS_OPENING_HOURS,
  process: ({ action }, dispatch, done) => {
    const { openingHours, specialOpeningHours, closedDays } = action.payload;
    const deletedItems = selectDeletedLocationsOpeningHours(specialOpeningHours.concat(closedDays));
    const updatedItems = openingHours.concat(selectUpdatedLocationsOpeningHours(specialOpeningHours.concat(closedDays)));
    const createdItems = selectCreatedLocationsOpeningHours(specialOpeningHours, closedDays);
    const sendRequests = getLocationsOpeningHoursRequests(deletedItems, updatedItems, createdItems, action.id);

    return Promise.all(sendRequests)
      .then(() => {
        dispatch(fetchSettingsOpeningHours(action.id));
        done();
      })
      .catch(err => {
        dispatch(saveSettingsOpeningHoursError(action.id, err));
        done();
      });
  }
});

const fetchSettingsOpeningHoursLogic = createLogicWithApi({
  type: FETCH_SETTINGS_OPENING_HOURS,
  cancelType: CANCEL_FETCH_SETTINGS_OPENING_HOURS,
  latest: true,
  process: ({ Api, action }, dispatch, done) => {
    return Api({
      method: 'GET',
      path: `${locationsApiConfig.path}/${action.id}${locationsApiConfig.openinghoursPath}`,
      handle: r => {
        r
          .query({'offset': 0})
          .query({'limit': 0})
        return r;
      }
    })
    .then(resp => {
      dispatch(fetchSettingsOpeningHoursSuccess(action.id, resp));
      done();
    })
    .catch(err => {
      dispatch(fetchSettingsError(err));
      done();
    });
  }
});

const saveSettingsEntranceLogic = createLogicWithApi({
  type: SAVE_SETTINGS_ENTRANCE,
  cancelType: CANCEL_SAVE_SETTINGS_ENTRANCE,
  process: async ({ action, getState }, dispatch, done) => {
    const { settings: { selected:currentLocation }} = getState();
    const entrance = getPrioritizedEntrance(currentLocation);
    const entranceType = getEntranceType(entrance);

    return new Promise(() => {
      switch (entranceType) {
        case 'unloc':
          return saveUnlocEntrance(dispatch, action, entrance);

        default: 
          return saveInfoEntrance(dispatch, action, entrance);
      }
    })
    .then(resp => {
      dispatch(saveSettingsEntranceSuccess(resp, action.id));
    })
    .catch(err => {
      dispatch(saveSettingsEntranceError(action.id, err));
      done();
    });
  }
});

const fetchSettingsEntrancesLogic = createLogicWithApi({
  type: FETCH_SETTINGS_ENTRANCES,
  cancelType: CANCEL_FETCH_SETTINGS_ENTRANCES,
  latest: true,
  process: ({ Api, action }, dispatch, done) => {
    return Api({
      method: 'GET',
      path: `${locationsApiConfig.path}/${action.id}${locationsApiConfig.entrancePath}`,
      handle: r => {
        r
          .query({'offset': 0})
          .query({'limit': 0})
        return r;
      }
    })
    .then(resp => {
      dispatch(fetchSettingsEntrancesSuccess(action.id, resp));
      done();
    })
    .catch(err => {
      dispatch(fetchSettingsEntrancesError(action.id, err));
      done();
    });
  }
});

const logics = [
  fetchSettingsLogic,
  saveSettingsLogic,
  saveSettingsOpeningHoursLogic,
  fetchSettingsOpeningHoursLogic,
  saveSettingsEntranceLogic,
  fetchSettingsEntrancesLogic,
];

export default logics;