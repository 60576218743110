import { createStore as _createStore, applyMiddleware } from 'redux';

import registryMiddleware from './registry/middleware';
import Registry from './registry';

export default function createStore(
  initialState = {},
  middleware = [],
  reducers = []
) {
  const registry = new Registry(reducers);

  let finalCreateStore = applyMiddleware(
    registryMiddleware(registry),
    ...middleware
  );

  // if we have redux devtools installed hook into it.
  if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    finalCreateStore = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(
      finalCreateStore
    );
  }

  const store = finalCreateStore(_createStore)(
    registry.initialReducers,
    initialState
  );

  if (middleware && middleware.length) {
    middleware.map(mw =>
      typeof mw.addDeps === 'function'
        ? mw.addDeps({ storeDispatch: store.dispatch })
        : null
    );
  }

  registry.store = store;

  return store;
}
