import React from 'react';
import { injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { saveEquipmentInstance } from '../../../../../../shared/components/Equipment/EquipmentInstance/EquipmentInstance.actions';
import { getSelectedInstance } from '../../../../../../shared/components/Equipment/Equipment.selectors';
import { 
  SCANNER_ACTION_EQUIPMENT_OVERVIEW, 
  SCANNER_ACTION_EQUIPMENT_LABEL, 
  setScannerAction 
} from '../../../../common/PubSub/PubSub.actions';

import EquipmentInstanceLabelForm from './EquipmentInstanceLabelForm.component';

export const formName = 'EquipmentInstanceLabelForm';

class EquipmentInstanceLabelFormContainer extends React.Component {
  componentDidMount() {
    const { changeMountedStatus } = this.props;

    changeMountedStatus(true);
  }

  componentWillUnmount() {
    const { changeMountedStatus } = this.props;

    changeMountedStatus(false);
  }

  render() {
    return (
      <EquipmentInstanceLabelForm {...this.props} />
    )
  }
}

EquipmentInstanceLabelFormContainer.propTypes = {
  changeMountedStatus: PropTypes.func,
};

const mapStateToProps = (state) => {
  const selected = getSelectedInstance(state);
  return {
    selected,
    loading: state.equipment.instances.meta.loading,
    initialValues: selected && selected.labelIdentifier ? {
      labelIdentifier: selected.labelIdentifier
    } : null
  }
};

const mapDispatchToProps = (dispatch, { toggleLabelModal }) => ({
  onSubmit(data, selected) {
    dispatch(
      saveEquipmentInstance({
        id: selected.id,
        variantId: selected.variant,
        ...data
      }, false)
    );
    toggleLabelModal();
  },
  changeMountedStatus(mounted=false) {
    dispatch(setScannerAction(
      mounted ? SCANNER_ACTION_EQUIPMENT_LABEL : SCANNER_ACTION_EQUIPMENT_OVERVIEW
    ));
  }
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onSubmit: data => {
    dispatchProps.onSubmit(data, stateProps.selected)
  }
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(
    reduxForm({ form: formName })(
      EquipmentInstanceLabelFormContainer
    )
  )
);
