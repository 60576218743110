import { combineReducers } from 'redux';

import { MAP_LOADED } from './Map.actions';

// LOADED

const loaded = (state = false, action) => {
  switch(action.type) {
    case MAP_LOADED:
      return true;
    default:
      return state;
  }
};

// MAIN

const reducer = combineReducers({
  loaded
});

export default reducer;
