import { showNotification } from '../components/common/Dialogs/Dialogs.actions';
import { logoutSuccess } from '../components/Login/Login.actions';
import Api from '../api';

import createCreateLogic from './createCreateLogic';

const createLogicWithApi = ({ ignoreUnauthorized, ...options }) => {
  const process = (props, dispatch, done) => {
    const { getState } = props;
    const state = getState();
    if (state.login) {
      Api.setCatchCallback(err => {
        if (err && err.status === 401 && !ignoreUnauthorized) {
          dispatch(logoutSuccess(err));
        }
        dispatch(showNotification(err));
      });
    }
    return options.process({ ...props, Api, state }, dispatch, done);
  };
  return createCreateLogic(options, process);
};

export default createLogicWithApi;
