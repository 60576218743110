export const FETCH_USERS                = '@@users/FETCH_USERS';
export const FETCH_USERS_SUCCESS        = '@@users/FETCH_USERS_SUCCESS';
export const FETCH_USERS_ERROR          = '@@users/FETCH_USERS_ERROR';
export const CANCEL_FETCH_USERS         = '@@users/CANCEL_FETCH_USERS';

export const FETCH_USER                 = '@@users/FETCH_USER';
export const FETCH_USER_SUCCESS         = '@@users/FETCH_USER_SUCCESS';
export const FETCH_USER_ERROR           = '@@users/FETCH_USER_ERROR';
export const CANCEL_FETCH_USER          = '@@users/CANCEL_FETCH_USER';
export const RESET_USER                 = '@@users/RESET_USER';

export const TABLE_SELECT_USER          = '@@users/TABLE_SELECT_USER';

export const SAVE_USER                  = '@@users/SAVE_USER';
export const SAVE_USERS                 = '@@users/SAVE_USERS';
export const SAVE_USERS_SUCCESS         = '@@users/SAVE_USERS_SUCCESS';
export const SAVE_USERS_ERROR           = '@@users/SAVE_USERS_ERROR';
export const CANCEL_SAVE_USERS          = '@@users/CANCEL_SAVE_USERS';
export const SAVE_USER_SUCCESS          = '@@users/SAVE_USER_SUCCESS';
export const SAVE_USER_ERROR            = '@@users/SAVE_USER_ERROR';
export const CANCEL_SAVE_USER           = '@@users/CANCEL_SAVE_USER';

export const REMOVE_USER                = '@@users/REMOVE_USER';
export const REMOVE_USER_SUCCESS        = '@@users/REMOVE_USER_SUCCESS';
export const REMOVE_USER_ERROR          = '@@users/REMOVE_USER_ERROR';
export const CANCEL_REMOVE_USER         = '@@users/CANCEL_REMOVE_USER';

export const REMOVE_USERS                = '@@users/REMOVE_USERS';
export const REMOVE_USERS_SUCCESS        = '@@users/REMOVE_USERS_SUCCESS';
export const REMOVE_USERS_ERROR          = '@@users/REMOVE_USERS_ERROR';
export const CANCEL_REMOVE_USERS         = '@@users/CANCEL_REMOVE_USERS';

export const RESET_USERS                = '@@users/RESET_USERS';

export const PATCH_USER                 = '@@users/PATCH_USER';
export const PATCH_USER_ERROR           = '@@users/PATCH_USER_ERROR';
export const PATCH_USER_SUCCESS         = '@@users/PATCH_USER_SUCCESS';
export const CANCEL_PATCH_USER          = '@@users/CANCEL_PATCH_USER';

export const selectUserTable = (id) => ({
  type: TABLE_SELECT_USER,
  id
});

export const fetchUsers = (config) => ({
  type: FETCH_USERS,
  ...config
});

export const fetchUsersSuccess = (resp) => ({
  type: FETCH_USERS_SUCCESS,
  payload: resp
});

export const fetchUsersError = (err) => ({
  type: FETCH_USERS_ERROR,
  payload: err
});

export const fetchUser = (id) => ({
  type: FETCH_USER,
  id
});

export const fetchUserSuccess = (resp) => ({
  type: FETCH_USER_SUCCESS,
  payload: resp
});

export const fetchUserError = (err) => ({
  type: FETCH_USER_ERROR,
  payload: err
});

export const saveUser = (payload, nextStep) => ({
  type: SAVE_USER,
  payload,
  nextStep
});

export const saveUsers = (payload, nextStep) => ({
  type: SAVE_USERS,
  payload,
  nextStep
});

export const saveUsersSuccess = (payload, nextStep) => ({
  type: SAVE_USERS_SUCCESS,
  payload,
  nextStep
});

export const saveUserSuccess = (payload, nextStep, id) => ({
  type: SAVE_USER_SUCCESS,
  payload,
  nextStep,
  id
});

export const saveUserError = (err) => ({
  type: SAVE_USER_ERROR,
  payload: err
});

// REMOVE USER

export const removeUser = (payload) => ({
  type: REMOVE_USER,
  payload
});

export const removeUserSuccess = (payload) => ({
  type: REMOVE_USER_SUCCESS,
  payload
});

export const removeUserError = (err) => ({
  type: REMOVE_USER_ERROR,
  payload: err
});

export const resetUser = () => ({
  type: RESET_USER
});

export const resetUsers = () => ({
  type: RESET_USERS
});

export const patchUser = (payload) => ({
  type: PATCH_USER,
  payload
});

export const patchUserSuccess = (payload) => ({
  type: PATCH_USER_SUCCESS,
  payload
});

export const patchUserError = (err) => ({
  type: PATCH_USER_ERROR,
  payload: err
});

// REMOVE USERS

export const removeUsers = (payload) => ({
  type: REMOVE_USERS,
  payload
});

export const removeUsersSuccess = (payload) => ({
  type: REMOVE_USERS_SUCCESS,
  payload
});

export const removeUsersError = (err) => ({
  type: REMOVE_USERS_ERROR,
  payload: err
});
