import { createGlobalStyle } from 'styled-components';

import vars from '../../variables';

const FormHelperGlobalStyles = createGlobalStyle`
  .not-verified {
    margin-right: 5px;
    color: ${vars.colorRed};
  }
`;

export default FormHelperGlobalStyles;
