import React from 'react';
import PropTypes from 'prop-types';
import LogoWhite from './bua_10_white.svg';
import LogoBlack from './bua_10_black.svg';

import withSvg from '../hoc/withSvg';

const Logo = ({ fill, ...props }) => {  
  const logo = fill  ? LogoBlack : LogoWhite  ;
  return (
    <img src={logo} alt="Logo" {...props} />
  )
};

Logo.propTypes = {
  theme: PropTypes.object,
  fill: PropTypes.string
};

export default withSvg(Logo);
