import {
  PATCH_IMAGES_SUCCESS,
  SAVE_IMAGE_SUCCESS,
  DELETE_IMAGE_SUCCESS
} from './Images.actions';

export const images = (state = [], action) => {
  switch(action.type) {
    case PATCH_IMAGES_SUCCESS:
      var actionObj = action.images.reduce((o, image) => ({ ...o, [image.id]: image}), {});
      return state.map(image => !actionObj[image.id] ? image : Object.assign({},
        image,
        actionObj[image.id],
        { attributes: Object.assign({},
          image.attributes,
          actionObj[image.id].attributes
        )}
      ));
    case SAVE_IMAGE_SUCCESS: {
      const current = state.find(item => String(item.id) === String(action.payload.data.id));
      if (current) {
        return state.map(
          i => String(current.id) === String(i.id) ? action.payload.data : i
        );
      } else {
        return [
          ...state,
          {
            ...action.payload.data,
            attributes: {
              ...action.payload.data.attributes,
              sortOrder: state.length
            }
          }
        ];
      }
    }
    case DELETE_IMAGE_SUCCESS:
      return state.filter(img => String(img.id) !== String(action.id));
    default:
      return state;
  }
}
