import { createGlobalStyle } from 'styled-components';

import vars from '../variables';

const HtmlGlobalStyles = createGlobalStyle`
  html {
    background-color: ${vars.colorGrayLighter};
  }
`;

export default HtmlGlobalStyles;