import { connect } from 'react-redux';

import ScannerSpinner from './ScannerSpinner.component';

const mapStateToProps = ({pubsub}) => ({
  scannerSpinnerVisible: pubsub.scannerSpinnerVisible
});

export default connect(
  mapStateToProps
)(ScannerSpinner);
