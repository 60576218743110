import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import { getGlobalLocations } from '../../../shared/components/Locations/Locations.selectors';
import { fetchGlobalLocations } from '../common/GlobalLocations/GlobalLocations.actions';
import ScannerSpinner from '../common/Dialogs/ScannerSpinner/ScannerSpinner.container';
import ConfirmDialog from '../common/Dialogs/ConfirmDialog/ConfirmDialog.component';
import Dialogs from '../../../shared/components/common/Dialogs/Dialogs.container';
import Notification from '../common/Dialogs/Notification/Notification.container';
import Header from '../common/Header/Header.container';
import { locale } from '../../config/locale';
import QR from '../common/QR/QR.container';

class App extends React.Component {
  componentDidMount() {
    this.checkGlobalLocations(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.checkGlobalLocations(nextProps);
  }

  checkGlobalLocations(props) {
    const {
      globalLocations,
      getGlobalLocations,
      loadingGlobalLocations,
      loggedIn
    } = props;

    if((!globalLocations || !globalLocations.length) && !loadingGlobalLocations && loggedIn) {
      getGlobalLocations();
    }
  }

  render() {
    const { routes, intl } = this.props;

    return (
      <div>
        <Helmet
          titleTemplate={intl.formatMessage({ id: `general.title_template` })}
          titleAttributes={{itemprop: 'name', lang: locale.langCode}}
        />

        <Routes>
          <Route path={`${intl.formatMessage({id: 'qr.route.path'})}/*`} Component={QR} />
          
          <Route index path="*" element={(
            <>
              <Header />

              <Routes>
                {routes.map(({ key, path, ...route }) => (
                  <Route key={key} path={`${path}/*`} {...route} />
                ))}
              </Routes>
            </>
          )} />
        </Routes>

        <Dialogs>
          <ScannerSpinner />
          <ConfirmDialog />
          <Notification />
        </Dialogs>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  globalLocations: getGlobalLocations(state),
  loggedIn: state.login.loggedIn,
  loadingGlobalLocations: state.globalLocations.meta.loading
});

const mapDispatchesToProps = (dispatch) => ({
  getGlobalLocations() {
    dispatch(
      fetchGlobalLocations()
    );
  }
});

App.propTypes = {
  globalLocations: PropTypes.array,
  getGlobalLocations: PropTypes.func,
  loadingGlobalLocations: PropTypes.bool,
  loggedIn: PropTypes.bool,
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired
    })
  ),
  intl: PropTypes.object.isRequired
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchesToProps,
    null,
  )(App)
);
