export const table = {
  name: 'organizations',
  columns: [
    'name',
    'organizationNumber',
    'contactPerson',
    'usersCount',
    'icon',
  ],
};
