export const EQUIPMENT_SCANNED                  = '@@pubsub/EQUIPMENT_SCANNED';
export const EQUIPMENT_SCAN_STARTED             = '@@pubsub/EQUIPMENT_SCAN_STARTED';
export const EQUIPMENT_SCAN_COMPLETED           = '@@pubsub/EQUIPMENT_SCAN_COMPLETED';

export const LOAN_STATUS                        = '@@pubsub/LOAN_STATUS';
export const SET_SCANNER_ACTION                 = '@@pubsub/SET_SCANNER_ACTION';
export const UNSET_SCANNER_ACTION               = '@@pubsub/UNSET_SCANNER_ACTION';

export const SCANNER_ACTION_EQUIPMENT_OVERVIEW  = '@@pubsub/SCANNER_ACTION_EQUIPMENT_OVERVIEW';
export const SCANNER_ACTION_EQUIPMENT_LABEL     = '@@pubsub/SCANNER_ACTION_EQUIPMENT_LABEL';
export const SCANNER_ACTION_LOAN_OVERVIEW       = '@@pubsub/SCANNER_ACTION_LOAN_OVERVIEW';
export const SCANNER_ACTION_LOAN_CREATION       = '@@pubsub/SCANNER_ACTION_LOAN_CREATION';
export const SCANNER_ACTION_LOAN_DELIVERY       = '@@pubsub/SCANNER_ACTION_LOAN_DELIVERY';

export const SCANNER_SPINNER_VISIBLE            = '@@pubsub/SCANNER_SPINNER_VISIBLE';

export const CATEGORY_DELETION_STATUS           = '@@pubsub/CATEGORY_DELETION_STATUS';

export const VERIFICATION_PUBSUB                = '@@pubsub/VERIFICATION_PUBSUB';

export const equipmentScanned = message => ({
  type: EQUIPMENT_SCANNED,
  message
});

export const equipmentScanStarted = () => ({
  type: EQUIPMENT_SCAN_STARTED
});

export const equipmentScanCompleted = () => ({
  type: EQUIPMENT_SCAN_COMPLETED
});

export const loanStatus = message => ({
  type: LOAN_STATUS,
  message
});

export const setScannerAction = label => ({
  type: SET_SCANNER_ACTION,
  label
});

export const unsetScannerAction = label => ({
  type: UNSET_SCANNER_ACTION,
  label
});

export const scannerActionCreator = (type, payload) => ({
  type,
  payload
});

export const scannerSpinnerVisible = visible => ({
  type: SCANNER_SPINNER_VISIBLE,
  visible
});

export const categoryDeletionStatus = message => ({
  type: CATEGORY_DELETION_STATUS,
  message
});

export const verificationPubsubAction = id => ({
  type: VERIFICATION_PUBSUB,
  id
});
