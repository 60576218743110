import { push } from '@lagunovsky/redux-react-router';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';

const useModalClose = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const clickClose = useCallback((levels = null) => {
    let locationArr = location.pathname.split("/");

    if (typeof levels === "number") {
      locationArr.splice(-levels);

    } else {
      locationArr.splice(-2);
    }

    dispatch(push(`${locationArr.join("/")}${window.location.search}`));
  }, [ location, dispatch ]);

  return { clickClose };
};

export default useModalClose;