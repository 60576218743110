import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';

import configureUrlQuery from '../configureUrlQuery';

/**
 * This class exists to read in the router from context (useful in react-router v4)
 * to get an equivalent history object so we can push and replace the URL.
 */
class RouterToUrlQuery extends Component {
  static propTypes = {
    routerContext: PropTypes.object,
    children: PropTypes.node
  };
  static contextTypes = {
    router: PropTypes.object
  };

  render() {
    const { router: routerOldContext } = this.context;
    const { routerContext: RouterContext } = this.props;

    if (typeof RouterContext === "undefined") {
      return (
        <RouterToUrlQueryLogic
          router={routerOldContext}
        >
          {React.Children.only(this.props.children)}
        </RouterToUrlQueryLogic>
      )
    }

    return (
      <RouterContext.Consumer>
        {routerNewContext => (
          <RouterToUrlQueryLogic
            router={routerNewContext}
          >
            {React.Children.only(this.props.children)}
          </RouterToUrlQueryLogic>
        )}
      </RouterContext.Consumer>
    );
  }
}

const RouterToUrlQueryLogic = ({ children, router }) => {
  useEffect(() => {
    if (!router) {
      return;
    }

    if (process.env.NODE_ENV === 'development' && !router) {
      // eslint-disable-next-line
      console.warn(
        'RouterToUrlQuery: `router` object not found in context. ' +
        'Not configuring history for react-url-query.'
      );

      return;
    }

    let history;

    if (router.history && router.history.push && router.history.replace) {
      history = router.history;

    } else if (router.push && router.replace) {
      history = router;

    } else if (router.transitionTo && router.replaceWith) {
      history = {
        push: router.transitionTo,
        replace: router.replaceWith,
      };
    }

    configureUrlQuery({ history });
  }, [ router ]);

  return React.Children.only(children);
}

RouterToUrlQueryLogic.propTypes = {
  children: PropTypes.node,
  router: PropTypes.object,
};

export default RouterToUrlQuery;