import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

class Dialogs extends React.Component {
  render() {
    return createPortal(this.props.children, document.body);
  }
}

Dialogs.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

export default Dialogs;
