import { reducer as formReducer } from 'redux-form';

import EquipmentManagerReducer from '../components/common/EquipmentManager/EquipmentManager.reducers';
import GlobalLocationsReducer from '../components/common/GlobalLocations/GlobalLocations.reducers';
import verificationReducer from '../components/common/Verification/Verification.reducers';
import CategoriesReducer from '../../shared/components/Categories/Categories.reducers';
import dialogsReducer from '../../shared/components/common/Dialogs/Dialogs.reducers';
import locationsReducer from '../../shared/components/Locations/Locations.reducers';
import pubsubReducer from '../components/common/PubSub/PubSub.reducers';
import loginReducer from '../../shared/components/Login/Login.reducers';
import tempFormReducer from '../components/common/Form/Form.reducers';
import tableReducer from '../components/common/Table/Table.reducers';
import mapReducer from '../components/common/Map/Map.reducers';

const reducers = {
  form: formReducer,
  tempForm: tempFormReducer,
  login: loginReducer,
  table: tableReducer,
  dialogs: dialogsReducer,
  equipmentManager: EquipmentManagerReducer,
  map: mapReducer,
  locations: locationsReducer,
  categories: CategoriesReducer,
  globalLocations: GlobalLocationsReducer,
  pubsub: pubsubReducer,
  verification: verificationReducer,
};

export default reducers;