
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { isArray } from 'lodash';

import { updateRouteConfig } from '../components/common/Route/Route.actions';
import { injectReducers } from '../actions/registry';

const withLazyLoad = (Component) => {
  let isReducersLoadedBackup = false;

  const LazyLoadComponent = ({ routeConfig, loadReducers, ...props }) => {
    const dispatch = useDispatch();

    const [ isReducersLoaded, setIsReducersLoaded ] = useState(isReducersLoadedBackup);

    const setReducersLoaded = useCallback(() => {
      setIsReducersLoaded(true);
      isReducersLoadedBackup = true;
    }, []);

    useEffect(() => {
      if (routeConfig) {
        dispatch(updateRouteConfig(routeConfig));
      }
    }, [ routeConfig, dispatch ]);
  
    useEffect(() => {
      if (!loadReducers || isReducersLoaded) {
        setReducersLoaded();
        
        return;
      }
  
      loadReducers()
        .then(module => esModule(module, true))
        .then(reducers => {
          dispatch(injectReducers(reducers));
          setReducersLoaded();
        });
    }, [ loadReducers, isReducersLoaded, dispatch, setReducersLoaded ]);

    if (!isReducersLoaded) {
      return null;
    }
  
    return <Component {...props} />;
  };

  LazyLoadComponent.propTypes = {
    loadReducers: PropTypes.func,
    routeConfig: PropTypes.object,
  };

  return LazyLoadComponent;
}

function moduleDefaultExport(module) {
  return module.default || module;
}

function esModule(module, forceArray) {
  if (isArray(module)) {
    return module.map(moduleDefaultExport);
  }

  const defualted = moduleDefaultExport(module);

  return forceArray 
    ? [ defualted ] 
    : defualted;
}

export default withLazyLoad;