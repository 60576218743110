import { combineReducers } from 'redux';

import {
  SET_TEMP_STATE,
} from './Form.actions';


const tempState = (state = {}, action) => {
  switch(action.type) {
    case SET_TEMP_STATE:
      return {
        ...state,
        [action.form]: action.state
      };
    default:
      return state;
  }
}

// MAIN
const reducer = combineReducers({
  tempState
});

reducer.reducer = 'tempForm';

export default reducer;
