import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import history from '../store/history';

const withRouter = (Component) => {
    const WithRouterWrapper = (props) => {
      const location = useLocation();
      const navigate = useNavigate();
      const params = useParams();
  
      return (
        <Component 
          navigate={navigate} 
          history={history}
          location={location} 
          params={params}
          {...props} 
        />
      );
    };
  
    return WithRouterWrapper;
  };

export default withRouter;