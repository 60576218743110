import { EMAIL_TYPE, PHONE_TYPE } from '../../../../shared/config/api';
import { 
  FETCH_USER, 
  SAVE_USER, 
  FETCH_USER_SUCCESS, 
  FETCH_USERS_SUCCESS, 
  SAVE_USER_SUCCESS
} from '../../pages/Users/Users.actions';

import {
  SEND_VERIFY_MESSAGE_SUCCESS,
  SEND_VERIFY_MESSAGE_ERROR,
  SEND_VERIFY_CODE,
  SEND_VERIFY_CODE_SUCCESS,
  SEND_VERIFY_CODE_ERROR,
  VALIDATE_VERIFY_CODE,
  VALIDATE_VERIFY_CODE_SUCCESS,
  VALIDATE_VERIFY_CODE_ERROR,
  RESET_VERIFICATION_USER,
} from './Verification.actions';

const verificationInitialState = {
  [EMAIL_TYPE]: {},
  [PHONE_TYPE]: {},
  verifyMessageSend: false,
  loading: false,
  user: null
};

const extractErrorMessage = (payload) => {
  let sendCodeErrorMessage = '';
  if (payload && payload.err && payload.err.response) {
    sendCodeErrorMessage = JSON.parse(payload.err.response.text).message;
  }

  return sendCodeErrorMessage;
}

const reducer = (state = verificationInitialState, action) => {
  switch (action.type) {
    case SAVE_USER_SUCCESS:
    case FETCH_USER_SUCCESS:
    case FETCH_USERS_SUCCESS: {
      if (action.payload && action.payload.data) {
        return {
          ...verificationInitialState,
          user: {
            id: action.payload.data.id,
            ...action.payload.data.attributes
          }
        }
      }

      return verificationInitialState;
    }

    case RESET_VERIFICATION_USER:
      return { ...verificationInitialState };

    case FETCH_USER:
    case SAVE_USER:
      return {
        ...state,
        loading: true
      }

    case SEND_VERIFY_CODE:
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          loading: true,
          codeSend: false,
          verified: null,
          errorMessage: ''
        }
      }

    case SEND_VERIFY_CODE_SUCCESS:
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          loading: false,
          codeSend: true
        }
      }

    case SEND_VERIFY_CODE_ERROR: {
      const sendCodeErrorMessage = extractErrorMessage(action.payload);

      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          loading: false,
          codeSend: false,
          verified: false,
          errorMessage: sendCodeErrorMessage
        }
      };
    }

    case VALIDATE_VERIFY_CODE:
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          loading: true,
          errorMessage: ''
        }
      }

    case VALIDATE_VERIFY_CODE_SUCCESS:
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          loading: false,
          verified: true
        }
      }

    case VALIDATE_VERIFY_CODE_ERROR: {
      const sendCodeErrorMessage = extractErrorMessage(action.payload);

      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          loading: false,
          verified: null,
          errorMessage: sendCodeErrorMessage
        }
      }
    }

    case SEND_VERIFY_MESSAGE_SUCCESS:
      return {
        ...state,
        verifyMessageSend: true,
      }
    case SEND_VERIFY_MESSAGE_ERROR:
      return {
        ...state,
        verifyMessageSend: false,
      }

    default:
      return state;
  }
};

reducer.reducer = 'verification';

export default reducer;

