import { createLogic } from 'redux-logic';

const createCreateLogic = (options, process) => {
  if (options.process.length < 3) {
    console.error(
      'Custom createLogic methods only support dispatchMultiple. "process(deps, dispatch, done)" structure required.'
    );
  }
  return createLogic(
    Object.assign({}, options, {
      process
    })
  );
};

export default createCreateLogic;
