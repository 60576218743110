import styled, { css } from 'styled-components';

export const StyledNotifications = styled.div`
  position: fixed;
  right: 8px;
  bottom: 8px;
  width: 450px;
  z-index: 101;

  > .tools {
    display: flex;
    justify-content: flex-end;
    padding: 0 8px;
  }

  > div {
    &:first-of-type {
      margin-top: 0;
    }
  }
`;

const notification = css`
  color: ${props => props.theme.colorGrayDarker};
  background-color: ${props => props.theme.colorGrayLighter};
`;

export const StyledNotification = styled.div`
  padding: 0 32px 0 16px;
  overflow-y: auto;
  border-radius: 2px;
  background-color: #fff4f7;
  box-shadow: 0 2px 8px 0 rgba(54, 60, 76, 0.08),
              0 3px 4px 0 rgba(0, 0, 0, 0.04);
  border: solid 1px ${props => props.theme.colorGrayLight};
  color:${props => props.theme.colorRed};
  position:relative;
  margin-top: 8px;

  > div {
    margin: 8px 0;
  }

  ${props => props.$notificationType && notification}
`;

export default StyledNotifications;
