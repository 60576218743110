import Select from './Select';
import Async from './Async';
import AsyncCreatable from './AsyncCreatable';
import Creatable from './Creatable';
import Value from './Value';
import Option from './Option';

import defaultArrowRenderer from './utils/defaultArrowRenderer';
import defaultClearRenderer from './utils/defaultClearRenderer';
import defaultFilterOptions from './utils/defaultFilterOptions';
import defaultMenuRenderer from './utils/defaultMenuRenderer';

Select.Async = Async;
Select.AsyncCreatable = AsyncCreatable;
Select.Creatable = Creatable;
Select.Value = Value;
Select.Option = Option;

export default Select;
export {
	Async,
	AsyncCreatable,
	Creatable,
	Value,
	Option,
	defaultMenuRenderer,
	defaultArrowRenderer,
	defaultClearRenderer,
	defaultFilterOptions
};
