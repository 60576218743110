import styled from 'styled-components';

const StyledMain = styled.a`
  position: fixed;
  top: 24px;
  right: 24px;
  display: inline-block;
  width: 32px;
  height: 32px;
  overflow: hidden;

  &::before,
  &::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 80%;
    top: 50%;
    left: 10%;
    margin-top: -1px;
    background-color: ${props => props.$dark ? props.theme.colorGrayDarker : props.theme.colorWhite};
  }
  
  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`;

export default StyledMain;
