import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const HeaderLink = styled(NavLink)`
  display: block;
  color: ${props => props.theme.colorGray};
  transition: all 0.1s linear;
  padding: 10px 16px;
  text-decoration: none;

  &.active,
  &:hover {
    color: ${props => props.theme.colorWhite};
    background-color: ${props => props.theme.colorGrayDarker};
  }
`;

export default HeaderLink;