import Faye from 'faye';

import { createLogic, createLogicWithApi } from '../../../../shared/logicCreators';
import { showNotification } from '../../../../shared/components/common/Dialogs/Dialogs.actions';
import { saveLoanProgress } from '../../../../shared/components/Loans/Loans.actions';
import { getGlobalLocationFromId } from '../../../../shared/components/Locations/Locations.selectors';
import { removeCategorySuccess } from '../../../../shared/components/Categories/Categories.actions';
import { url as pubsubPath, delay as pubsubScannerDelay } from '../../../config/pubsub';
import { timeout } from '../../../../shared/config/api';
import {
  SET_LOGIN_INFO,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS
} from '../../../../shared/components/Login/Login.actions';
import {
  locations as locationsApiConfig,
  instances as instancesApiConfig,
} from '../../../config/api';

import {
  EQUIPMENT_SCANNED,
  equipmentScanned,
  LOAN_STATUS,
  loanStatus,
  SCANNER_ACTION_EQUIPMENT_OVERVIEW,
  SCANNER_ACTION_EQUIPMENT_LABEL,
  SCANNER_ACTION_LOAN_OVERVIEW,
  SCANNER_ACTION_LOAN_CREATION,
  SCANNER_ACTION_LOAN_DELIVERY,
  scannerActionCreator,
  equipmentScanStarted,
  equipmentScanCompleted,
  EQUIPMENT_SCAN_STARTED,
  EQUIPMENT_SCAN_COMPLETED,
  scannerSpinnerVisible,
  categoryDeletionStatus,
  CATEGORY_DELETION_STATUS,
  verificationPubsubAction
} from './PubSub.actions';

const pubsubClient = new Faye.Client(pubsubPath);
let pubsubSubscription, pubsubScannerLocked = false;
let verificationPubsub;

const startPubsubLogic = createLogic({
  type: [SET_LOGIN_INFO, LOGIN_SUCCESS],
  cancelType: LOGOUT_SUCCESS,
  warnTimeout: 0,
  processOptions: {
    dispatchMultiple: true
  },
  process: ({ action, getState }, dispatch) => {
    pubsubSubscription = pubsubClient.subscribe(action.payload.data.pubsub_channel, message => {
      switch (message.type) {
        case 'loan_status':
          dispatch(loanStatus(message));
          break;

        case 'equipment_scan':
          if (!pubsubScannerLocked) {
            pubsubScannerLocked = true;
            dispatch(equipmentScanned(message));
            dispatch(scannerSpinnerVisible(true));
            setTimeout(() => {
              pubsubScannerLocked = false;
              dispatch(scannerSpinnerVisible(false));
            }, pubsubScannerDelay);
          }
          break;

        case 'category_deletion_status':
          dispatch(categoryDeletionStatus(message));
          break;

        default:
          break;
      }
    });

    if (action.payload.data.verification_channel) {
      verificationPubsub = pubsubClient.subscribe(action.payload.data.verification_channel, message => {
        switch (message.type) {
          case 'verification_update':
            if (message.status === 'finished_success') {
              const { verification, users } = getState();
              const verificationUser = verification && verification.user && verification.id;
              const usersSelected = users && users.selected && users.selected.id;
              const messageUserId = message.data.id;

              if ([verificationUser, usersSelected].includes(messageUserId)) {
                dispatch(verificationPubsubAction(messageUserId))
              }
            }
            break;

          default:
            break;
        }
      });
    }
  }
});

const stopPubsubLogic = createLogic({
  type: LOGOUT_SUCCESS,
  process: (deps, dispatch, done) => {
    if (pubsubSubscription) {
      pubsubSubscription.cancel();
    }
    if (verificationPubsub) {
      verificationPubsub.cancel();
    }
    done();
  }
});

const loanStatusLogic = createLogic({
  type: LOAN_STATUS,
  process: ({action:{ message }, getState}, dispatch, done) => {
    const state = getState();
    dispatch(saveLoanProgress(message.status, message.data));
    if (message.status === 'finished_errors') {
      dispatch(showNotification(`${state.locale.loans.details.error_title_id}${message.data.id}: ${message.error}`));
    }
    done();
  }
});

const equipmentScannedLogic = createLogicWithApi({
  type: EQUIPMENT_SCANNED,
  process: ({ Api, getState}, dispatch, done) => {
    const state = getState();
    const {pubsub:{ scannerAction, scannedMessage, /* scannerLoading */ }, table: { currentLocation }} = state;
    /* if (!scannerLoading) { */ // TODO: scannerLoading is not correctly being reset. Opening this for now.
      dispatch(equipmentScanStarted());
      
      switch (scannerAction) {
        case SCANNER_ACTION_EQUIPMENT_OVERVIEW:
        case SCANNER_ACTION_LOAN_OVERVIEW:
        case SCANNER_ACTION_LOAN_CREATION:
        case SCANNER_ACTION_LOAN_DELIVERY: {
          return Api({
            method: 'GET',
            path: `${locationsApiConfig.path}/${currentLocation}${instancesApiConfig.searchPath}`,
            handle: r => r.query({'query': scannedMessage.data})
          })
          .then(resp => {
            if (currentLocation && String(resp.data.attributes.location) === String(currentLocation)) {
              dispatch(scannerActionCreator(scannerAction, resp));
            } else {
              const equipmentLocation = getGlobalLocationFromId(state, resp.data.attributes.location);
              dispatch(showNotification(state.locale.general.wrongLocation.replace('{location}', equipmentLocation.name)));
              dispatch(equipmentScanCompleted());
            }
            done();
          })
          .catch(err => {
            console.error('Scanner error', err);
            dispatch(equipmentScanCompleted());
            done();
          });
        }
        case SCANNER_ACTION_EQUIPMENT_LABEL:
          dispatch(scannerActionCreator(scannerAction, scannedMessage.data));
          done();
          break;
        default:
          dispatch(equipmentScanCompleted());
          done();
      }
    // } else {
    //   dispatch(showNotification(state.locale.general.scannerLoading));
    //   done();
    // }
  }
});

const scannerTimeoutLogic = createLogic({
  type: EQUIPMENT_SCAN_STARTED,
  cancelType: EQUIPMENT_SCAN_COMPLETED,
  processOptions: {
    successType: EQUIPMENT_SCAN_COMPLETED
  },
  process: ({ getState }) => new Promise((resolve, reject) => {
    setTimeout(() => {
      const {pubsub:{ scannerLoading }} = getState();
      if (scannerLoading) {
        resolve();
      } else {
        reject();
      }
    }, timeout);
  })
});

const STATUS_STARTED = 'started';
// const STATUS_IN_PROGRESS = 'in_progress';
const STATUS_FINISHED_ERRORS = 'finished_errors';
const STATUS_FINISHED_SUCCESS = 'finished_success';

const matchTranslation = {
  'category_deletion_status': 'Category deletion',
  [STATUS_FINISHED_SUCCESS]: 'finished success',
  [STATUS_STARTED]: 'started',
};

const categoryDeletionStatusLogic = createLogic({
  type: CATEGORY_DELETION_STATUS,
  process: ({ action: { message }}, dispatch, done) => {
    switch (message.status) {
      case STATUS_FINISHED_ERRORS:
        dispatch(showNotification({ notificationText: `${message.data.id}: ${message.error}` }));
        break;

      case STATUS_STARTED:
      case STATUS_FINISHED_SUCCESS:
        dispatch(showNotification({ notificationText: `${matchTranslation[message.type]} ${matchTranslation[message.status]}` }));
        break;

      default:
        break;
    }

    if (message.status === 'finished_success') {
      dispatch(removeCategorySuccess({
        id: message.data.id,
        targetCategory: message.data.params && message.data.params.targetId ? message.data.params.targetId : 1,
      }));
    }

    done();
  },
});

const logics = [
  startPubsubLogic,
  stopPubsubLogic,
  loanStatusLogic,
  equipmentScannedLogic,
  scannerTimeoutLogic,
  categoryDeletionStatusLogic,
];

export default logics;