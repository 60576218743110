import PropTypes from 'prop-types';
import withSizes from './withSizes'

const Conductor = ({ children, render, ...props }) => {
  const fn = children || render
  return fn ? fn(props) : null
}

Conductor.propTypes = {
  children: PropTypes.arrayOf(PropTypes.object),
  render: PropTypes.func,
};

const createSizedComponent = (...mapSizesToProps) => {
  return withSizes(mapSizesToProps)(Conductor)
}

export default createSizedComponent
