import React from 'react';
import PropTypes from 'prop-types';

import StyledQR from './QR.styled';

const QR = ({ src, onLoad, identifier }) => (
  <StyledQR>
    {!!src && src !== '' && (
      <img onLoad={onLoad} src={src} alt="" />
    )}
    
    {!!identifier && (
      <h2>{identifier}</h2>
    )}
  </StyledQR>
);

QR.propTypes = {
  identifier: PropTypes.string,
  src: PropTypes.string,
  onLoad: PropTypes.func,
}

export default QR;
