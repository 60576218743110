import styled from 'styled-components';

const HeaderTop = styled.div`
  display: block;
  padding: 16px;

  &,
  a {
    color: ${props => props.theme.colorWhite};
  }

  h4 {
    margin: 0;
  }

  .logo {
    height: 24px;
    max-width: 60px;
    margin-bottom: 4px;
  }
`;

export default HeaderTop;
