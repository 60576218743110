const UrlUpdateTypes = {
  replace: 'replace',
  replaceIn: 'replaceIn',
  multiReplaceIn: 'multiReplaceIn',
  push: 'push',
  pushIn: 'pushIn',
  multiPushIn: 'multiPushIn',
};

export default UrlUpdateTypes;
