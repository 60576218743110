const LOCATION_TYPE_FRANCHISE = 'franchise';
const LOCATION_TYPE_NETWORK = 'network';
const LOCATION_TYPE_MAP_ENTRY = 'mapEntry';
const LOCATION_TYPE_SELF_SERVICE = 'selfService';

export const LOCATIONS_TYPES = [
  LOCATION_TYPE_FRANCHISE,
  LOCATION_TYPE_NETWORK,
  LOCATION_TYPE_MAP_ENTRY,
  LOCATION_TYPE_SELF_SERVICE
];

export const CHANGE_LOCATION_ACTION = '@@Table/CHANGE_LOCATION';
export const SAVE_SETTINGS_SUCCESS   = '@@settings/SAVE_SETTINGS_SUCCESS';
export const FETCH_SETTINGS_SUCCESS = '@@settings/FETCH_SETTINGS_SUCCESS';
export const FETCH_SETTINGS_OPENING_HOURS_SUCCESS   = '@@settings/FETCH_SETTINGS_OPENING_HOURS_SUCCESS';
