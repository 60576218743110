import React from 'react';
import { createPortal } from 'react-dom';

import withModalClose from '../../../../shared/hoc/withModalClose';

import Modal from './Modal.component';

class ModalContainer extends React.Component {
  componentDidMount() {
    document.body.classList.add('no-scrolling');
  }
  componentWillUnmount() {
    document.body.classList.remove('no-scrolling');
  }
  render() {
    return createPortal(
      <Modal { ...this.props } />,
      document.body
    )
  }
}

export default withModalClose(
  ModalContainer
);
