export const FETCH_EQUIPMENT               = '@@equipmentManager/FETCH_EQUIPMENT';
export const FETCH_EQUIPMENT_SUCCESS       = '@@equipmentManager/FETCH_EQUIPMENT_SUCCESS';
export const FETCH_EQUIPMENT_ERROR         = '@@equipmentManager/FETCH_EQUIPMENT_ERROR';
export const CANCEL_FETCH_EQUIPMENT        = '@@equipmentManager/CANCEL_FETCH_EQUIPMENT';
export const RESET_EQUIPMENT               = '@@equipmentManager/RESET_EQUIPMENT';

export const fetchEquipment = (config) => ({
  type: FETCH_EQUIPMENT,
  ...config
});

export const fetchEquipmentSuccess = (resp) => ({
  type: FETCH_EQUIPMENT_SUCCESS,
  payload: resp
});

export const fetchEquipmentError = (err) => ({
  type: FETCH_EQUIPMENT_ERROR,
  payload: err
});

export const resetEquipment = () => ({
  type: RESET_EQUIPMENT
});
