export const SELECT_CATEGORY = '@@categories/SELECT_CATEGORY';

export const CATEGORIES_FORM_STEP = '@@categories/CATEGORIES_FORM_STEP';

export const FETCH_CATEGORIES = '@@categories/FETCH_CATEGORIES';
export const FETCH_CATEGORIES_SUCCESS = '@@categories/FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_ERROR = '@@categories/FETCH_CATEGORIES_ERROR';
export const CANCEL_FETCH_CATEGORIES = '@@categories/CANCEL_FETCH_CATEGORIES';

export const FETCH_CATEGORY = '@@categories/FETCH_CATEGORY';
export const FETCH_CATEGORY_SUCCESS = '@@categories/FETCH_CATEGORY_SUCCESS';
export const FETCH_CATEGORY_ERROR = '@@categories/FETCH_CATEGORY_ERROR';
export const CANCEL_FETCH_CATEGORY = '@@categories/CANCEL_FETCH_CATEGORY';

export const SAVE_CATEGORY = '@@categories/SAVE_CATEGORY';
export const SAVE_CATEGORY_SUCCESS = '@@categories/SAVE_CATEGORY_SUCCESS';
export const SAVE_CATEGORY_ERROR = '@@categories/SAVE_CATEGORY_ERROR';
export const CANCEL_SAVE_CATEGORY = '@@categories/CANCEL_SAVE_CATEGORY';

export const SAVE_CATEGORIES = '@@categories/SAVE_CATEGORIES';
export const SAVE_CATEGORIES_SUCCESS = '@@categories/SAVE_CATEGORIES_SUCCESS';
export const SAVE_CATEGORIES_ERROR = '@@categories/SAVE_CATEGORIES_ERROR';
export const CANCEL_SAVE_CATEGORIES = '@@categories/CANCEL_SAVE_CATEGORIES';

export const SAVE_EQUIPMENT_ATTRIBUTES = '@@categories/SAVE_EQUIPMENT_ATTRIBUTES';
export const SAVE_EQUIPMENT_ATTRIBUTES_SUCCESS = '@@categories/SAVE_EQUIPMENT_ATTRIBUTES_SUCCESS';
export const SAVE_EQUIPMENT_ATTRIBUTES_ERROR = '@@categories/SAVE_EQUIPMENT_ATTRIBUTES_ERROR';
export const CANCEL_SAVE_EQUIPMENT_ATTRIBUTES = '@@categories/CANCEL_SAVE_EQUIPMENT_ATTRIBUTES';

export const SAVE_EQUIPMENT_ATTRIBUTE = '@@categories/SAVE_EQUIPMENT_ATTRIBUTE';
export const SAVE_EQUIPMENT_ATTRIBUTE_SUCCESS = '@@categories/SAVE_EQUIPMENT_ATTRIBUTE_SUCCESS';
export const SAVE_EQUIPMENT_ATTRIBUTE_ERROR = '@@categories/SAVE_EQUIPMENT_ATTRIBUTE_ERROR';
export const CANCEL_SAVE_EQUIPMENT_ATTRIBUTE = '@@categories/CANCEL_SAVE_EQUIPMENT_ATTRIBUTE';

export const REMOVE_EQUIPMENT_ATTRIBUTE = '@@categories/REMOVE_EQUIPMENT_ATTRIBUTE';
export const REMOVE_EQUIPMENT_ATTRIBUTE_SUCCESS = '@@categories/REMOVE_EQUIPMENT_ATTRIBUTE_SUCCESS';
export const REMOVE_EQUIPMENT_ATTRIBUTE_ERROR = '@@categories/REMOVE_EQUIPMENT_ATTRIBUTE_ERROR';
export const CANCEL_REMOVE_EQUIPMENT_ATTRIBUTE = '@@categories/CANCEL_REMOVE_EQUIPMENT_ATTRIBUTE';

export const REMOVE_VARIANT_ATTRIBUTE = '@@categories/REMOVE_VARIANT_ATTRIBUTE';
export const REMOVE_VARIANT_ATTRIBUTE_SUCCESS = '@@categories/REMOVE_VARIANT_ATTRIBUTE_SUCCESS';
export const REMOVE_VARIANT_ATTRIBUTE_ERROR = '@@categories/REMOVE_VARIANT_ATTRIBUTE_ERROR';
export const CANCEL_REMOVE_VARIANT_ATTRIBUTE = '@@categories/CANCEL_REMOVE_VARIANT_ATTRIBUTE';

export const SAVE_VARIANT_ATTRIBUTES = '@@categories/SAVE_VARIANT_ATTRIBUTES';
export const SAVE_VARIANT_ATTRIBUTES_SUCCESS = '@@categories/SAVE_VARIANT_ATTRIBUTES_SUCCESS';
export const SAVE_VARIANT_ATTRIBUTES_ERROR = '@@categories/SAVE_VARIANT_ATTRIBUTES_ERROR';
export const CANCEL_SAVE_VARIANT_ATTRIBUTES = '@@categories/CANCEL_SAVE_VARIANT_ATTRIBUTES';

export const SAVE_VARIANT_ATTRIBUTE = '@@categories/SAVE_VARIANT_ATTRIBUTE';
export const SAVE_VARIANT_ATTRIBUTE_SUCCESS = '@@categories/SAVE_VARIANT_ATTRIBUTE_SUCCESS';
export const SAVE_VARIANT_ATTRIBUTE_ERROR = '@@categories/SAVE_VARIANT_ATTRIBUTE_ERROR';
export const CANCEL_SAVE_VARIANT_ATTRIBUTE = '@@categories/CANCEL_SAVE_VARIANT_ATTRIBUTE';

export const SAVE_CATEGORY_IMAGE = '@@locations/SAVE_CATEGORY_IMAGE';
export const SAVE_CATEGORY_IMAGE_SUCCESS = '@@locations/SAVE_CATEGORY_IMAGE_SUCCESS';
export const SAVE_CATEGORY_IMAGE_ERROR = '@@locations/SAVE_CATEGORY_IMAGE_ERROR';
export const CANCEL_SAVE_CATEGORY_IMAGE = '@@locations/CANCEL_SAVE_CATEGORY_IMAGE';

export const DELETE_CATEGORY_IMAGE = '@@locations/DELETE_CATEGORY_IMAGE';
export const DELETE_CATEGORY_IMAGE_SUCCESS = '@@locations/DELETE_CATEGORY_IMAGE_SUCCESS';
export const DELETE_CATEGORY_IMAGE_ERROR = '@@locations/DELETE_CATEGORY_IMAGE_ERROR';
export const CANCEL_DELETE_CATEGORY_IMAGE = '@@locations/CANCEL_DELETE_CATEGORY_IMAGE';

export const PATCH_CATEGORY_IMAGES = '@@locations/PATCH_CATEGORY_IMAGES';
export const PATCH_CATEGORY_IMAGES_SUCCESS = '@@locations/PATCH_CATEGORY_IMAGES_SUCCESS';
export const PATCH_CATEGORY_IMAGES_ERROR = '@@locations/PATCH_CATEGORY_IMAGES_ERROR';
export const CANCEL_PATCH_CATEGORY_IMAGES = '@@locations/CANCEL_PATCH_CATEGORY_IMAGES';

export const REMOVE_CATEGORY = '@@categories/REMOVE_CATEGORY';
export const REMOVE_CATEGORY_SUCCESS = '@@categories/REMOVE_CATEGORY_SUCCESS';
export const REMOVE_CATEGORY_ERROR = '@@categories/REMOVE_CATEGORY_ERROR';
export const CANCEL_REMOVE_CATEGORY = '@@categories/CANCEL_REMOVE_CATEGORY';

export const SET_SELECTED_CATEGORY = '@@categories/SET_SELECTED_CATEGORY';

export function selectCategory(id) {
  return {
    type: SELECT_CATEGORY,
    id
  };
}

export function categoryFormStep(id, nextStep) {
  return {
    type: CATEGORIES_FORM_STEP,
    id,
    nextStep
  };
}

export function fetchCategories(config) {
  return {
    type: FETCH_CATEGORIES,
    ...config
  };
}

export function fetchCategoriesSuccess(resp) {
  return {
    type: FETCH_CATEGORIES_SUCCESS,
    payload: resp
  };
}

export function fetchCategoriesError(err) {
  return {
    type: FETCH_CATEGORIES_ERROR,
    payload: err
  };
}

export function fetchCategory(id) {
  return {
    type: FETCH_CATEGORY,
    id
  };
}

export function fetchCategorySuccess(resp) {
  return {
    type: FETCH_CATEGORY_SUCCESS,
    payload: resp
  };
}

export function fetchCategoryError(err) {
  return {
    type: FETCH_CATEGORY_ERROR,
    payload: err
  };
}

export function saveCategory(data, nextStep) {
  return {
    type: SAVE_CATEGORY,
    payload: data,
    nextStep
  };
}

export function saveCategorySuccess(resp, nextStep) {
  return {
    type: SAVE_CATEGORY_SUCCESS,
    payload: resp,
    nextStep
  };
}

export function saveCategoryError(err) {
  return {
    type: SAVE_CATEGORY_ERROR,
    payload: err
  };
}

export function saveCategories(newOrder) {
  return {
    type: SAVE_CATEGORIES,
    payload: newOrder
  };
}

export function saveCategoriesSuccess(resp) {
  return {
    type: SAVE_CATEGORIES_SUCCESS,
    payload: resp
  };
}

export function saveCategoriesError(err) {
  return {
    type: SAVE_CATEGORIES_ERROR,
    payload: err
  };
}

export function saveEquipmentAttributes(category, attributes) {
  return {
    type: SAVE_EQUIPMENT_ATTRIBUTES,
    payload: {
      category,
      attributes
    }
  };
}

export function saveEquipmentAttributesSuccess(resp) {
  return {
    type: SAVE_EQUIPMENT_ATTRIBUTES_SUCCESS,
    payload: resp
  };
}

export function saveEquipmentAttributesError(err) {
  return {
    type: SAVE_EQUIPMENT_ATTRIBUTES_ERROR,
    payload: err
  };
}

export function saveEquipmentAttribute(category, payload) {
  return {
    type: SAVE_EQUIPMENT_ATTRIBUTE,
    category,
    payload
  };
}

export function saveEquipmentAttributeSuccess(resp) {
  return {
    type: SAVE_EQUIPMENT_ATTRIBUTE_SUCCESS,
    payload: resp
  };
}

export const removeEquipmentAttribute = payload => ({
  type: REMOVE_EQUIPMENT_ATTRIBUTE,
  ...payload
});

export const removeEquipmentAttributeSuccess = payload => ({
  type: REMOVE_EQUIPMENT_ATTRIBUTE_SUCCESS,
  ...payload
});

export const removeEquipmentAttributeError = err => ({
  type: REMOVE_EQUIPMENT_ATTRIBUTE_ERROR,
  payload: err
});

export const removeVariantAttribute = payload => ({
  type: REMOVE_VARIANT_ATTRIBUTE,
  ...payload
});

export const removeVariantAttributeSuccess = payload => ({
  type: REMOVE_VARIANT_ATTRIBUTE_SUCCESS,
  ...payload
});

export const removeVariantAttributeError = err => ({
  type: REMOVE_VARIANT_ATTRIBUTE_ERROR,
  payload: err
});

export function saveEquipmentAttributeError(err) {
  return {
    type: SAVE_EQUIPMENT_ATTRIBUTE_ERROR,
    payload: err
  };
}

export function saveVariantAttributes(category, attributes) {
  return {
    type: SAVE_VARIANT_ATTRIBUTES,
    payload: {
      category,
      attributes
    }
  };
}

export function saveVariantAttributesSuccess(resp) {
  return {
    type: SAVE_VARIANT_ATTRIBUTES_SUCCESS,
    payload: resp
  };
}

export function saveVariantAttributesError(err) {
  return {
    type: SAVE_VARIANT_ATTRIBUTES_ERROR,
    payload: err
  };
}

export function saveVariantAttribute(category, payload) {
  return {
    type: SAVE_VARIANT_ATTRIBUTE,
    category,
    payload
  };
}

export function saveVariantAttributeSuccess(resp) {
  return {
    type: SAVE_VARIANT_ATTRIBUTE_SUCCESS,
    payload: resp
  };
}

export function saveVariantAttributeError(err) {
  return {
    type: SAVE_VARIANT_ATTRIBUTE_ERROR,
    payload: err
  };
}

export const removeCategory = payload => ({
  type: REMOVE_CATEGORY,
  ...payload
});

export const removeCategorySuccess = payload => ({
  type: REMOVE_CATEGORY_SUCCESS,
  ...payload
});

export const removeCategoryError = err => ({
  type: REMOVE_CATEGORY_ERROR,
  payload: err
});

export function saveCategoryImage(category, image) {
  return {
    type: SAVE_CATEGORY_IMAGE,
    category,
    payload: image
  };
}

export function saveCategoryImageSuccess(category, resp) {
  return {
    type: SAVE_CATEGORY_IMAGE_SUCCESS,
    category,
    payload: resp
  };
}

export function saveCategoryImageError(category, err) {
  return {
    type: SAVE_CATEGORY_IMAGE_ERROR,
    category,
    payload: err
  };
}

export function deleteCategoryImage(category, image) {
  return {
    type: DELETE_CATEGORY_IMAGE,
    category,
    image
  };
}

export function deleteCategoryImageSuccess(category, id) {
  return {
    type: DELETE_CATEGORY_IMAGE_SUCCESS,
    category,
    id
  };
}

export function deleteCategoryImageError(category, err) {
  return {
    type: DELETE_CATEGORY_IMAGE_ERROR,
    category,
    payload: err
  };
}

export function patchCategoryImages(category, images) {
  return {
    type: PATCH_CATEGORY_IMAGES,
    category,
    images
  };
}

export function patchCategoryImagesSuccess(category, images) {
  return {
    type: PATCH_CATEGORY_IMAGES_SUCCESS,
    category,
    images
  };
}

export function patchCategoryImagesError(category, err) {
  return {
    type: PATCH_CATEGORY_IMAGES_ERROR,
    category,
    payload: err
  };
}

export function setSelectedCategory(payload) {
  return {
    type: SET_SELECTED_CATEGORY,
    payload
  };
}
