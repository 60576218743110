import { createSelector } from 'reselect';
import { isObject } from 'lodash';
import moment from 'moment';

import { selectSort } from '../common/Selectors/Select.selectors';
import { LOAN_DELIVER_FORM } from '../../config/loans';

export const getLoans = state => state.loans?.items || null;

export const getSelectedLoan = state => state.loans?.selected || null;

export const getProcessingLoans = state => state.loans?.meta?.processing || [];

export const getLoanItems = state => state.loans?.loanItems?.items || null;

export const getListTotal = state => state.loans?.meta?.list?.total || 0;

export const getLoanItemsListTotal = state => state.loans?.loanItems?.meta?.list?.total || 0;

export const getLoanTempFormStateDeliver = state => state.tempForm.tempState[LOAN_DELIVER_FORM];

export const getSelected = createSelector(
  [getSelectedLoan, getProcessingLoans],
  (item, processing) =>
    !item
      ? item
      : {
          ...item,
          processing: processing.find(
            p => String(item.id) === String(p.id) && p.status.indexOf('finished_') !== 0
          )
        }
);

export const getExtendedLoans = createSelector(
  [getLoans, getProcessingLoans],
  (items = [], processing) => {
    if (items && items.length) {
      items = items.map(item => {
        const tempItem = {
          ...item,
          pickupAt: item.pickupAt
            ? moment(item.pickupAt).format('DD. MMMM, YYYY')
            : item.pickupAt,
          returnAt: item.returnAt
            ? moment(item.returnAt).format('DD. MMMM, YYYY')
            : item.returnAt,
          processing: processing.find(p => String(item.id) === String(p.id))
        };

        if (isObject(item.user)) {
          return {
            ...tempItem,
            firstName: item.user.firstName,
            lastName: item.user.lastName,
            name: `${item.user.firstName} ${item.user.lastName}`
          };
        }

        return tempItem;
      });
    }

    return items;
  }
);

export const getSelectedEquipment = createSelector(
  [getLoanItems],
  (items = []) => {
    var variants = [];

    return items
      .filter(i => {
        if (variants.length && variants.find(v => String(v.id) === String(i.variant))) {
          variants = variants.map(v => {
            if (String(v.id) === String(i.variant)) {
              v.quantity += 1;
            }

            return v;
          });
          return false;
        } else {
          variants.push({
            id: i.variant,
            quantity: 1
          });
          return i;
        }
      })
      .map(i => {
        let variant = variants.find(v => String(v.id) === String(i.variant));

        return {
          ...i,
          quantity: variant.quantity,
          attributeValues: [...i.variantAttributeValues]
        };
      });
  }
);

export const getTemporaryPublishedLoanItems = createSelector(
  [getLoanItems],
  (items = []) => {
    return items
      .filter(i => i.state === 'published' || i.state === 'temporary')
      .map(i => ({
        ...i,
        identifier: i.instance && i.instance.identifier,
        name: `${i.brand ? `${i.brand.value} ` : ''}${
          i.equipment ? i.equipment.name : ''
        }`.trim(),
        variantValues: `${i.variantAttributeValues
          .map(v => v.value)
          .join(', ')}`.trim()
      }))
      .sort((a, b) => {
        if (a.name !== b.name) {
          return a.name > b.name ? 1 : -1;
        } else if (a.variantValues !== b.variantValues) {
          return a.variantValues > b.variantValues ? 1 : -1;
        } else {
          return a.id > b.id ? 1 : -1;
        }
      });
  }
);

export const getLoanInstanceIdentifiers = createSelector(
  [getLoanItems],
  (items = []) => {
    var identifiers = {};

    if (items && items.length) {
      items
        .filter(i => i.instance && i.instance.identifier)
        .forEach(i => {
          identifiers[`loanItem-${i.id}`] = i.instance.id;
        });
    }

    return identifiers;
  }
);

export const getPreparedLoanItems = state => {
  let items = getLoanItems(state);

  if (!Array.isArray(items)) return [];

  return items
    .filter(i => i.state === 'published' || i.state === 'temporary')
    .filter(i => i.instance && i.instance.id)
    .map(i => ({
      ...i,
      identifier: i.instance && i.instance.identifier,
      name: `${i.brand ? `${i.brand.value} ` : ''}${
        i.equipment ? i.equipment.name : ''
      }`,
      variantValues: `${i.variantAttributeValues.map(v => v.value).join(', ')}`
    }));
};

export const getLoanItemsSorted = createSelector(
  [getLoanItems],
  (items = []) => {
    return items
      .map(item => ({
        ...item,
        name: `${item.brand && item.brand.value ? item.brand.value : ''} ${
          item.equipment.name
        }`.trim()
      }))
      .sort((a, b) => {
        if (a.name !== b.name) {
          return a.name > b.name ? 1 : -1;
        } else {
          return a.instance.identifier > b.instance.identifier ? 1 : -1;
        }
      });
  }
);

export const getLoanTempFormStateDeliverSorted = createSelector(
  [getLoanTempFormStateDeliver],
  (items = []) => {
    let sorted = [];
    if (items.length) sorted = items.slice().sort(selectSort('name'));
    return sorted;
  }
);
