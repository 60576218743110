export const selectSort = label => (a, b) => {
  const aFloat = parseFloat(a[label].replace(',', '.')),
    bFloat = parseFloat(b[label].replace(',', '.')),
    aIsNaN = isNaN(aFloat),
    bIsNaN = isNaN(bFloat),
    al = aIsNaN ? a[label] : aFloat,
    bl = bIsNaN ? b[label] : bFloat;
  return al > bl || (aIsNaN && !bIsNaN)
    ? 1
    : al < bl || (!aIsNaN && bIsNaN)
    ? -1
    : 0;
};
