import { omit } from 'lodash';
import Promise from 'pinkie-promise';
import { replace, push } from '@lagunovsky/redux-react-router';

import { createLogicWithApi, createLogicWithRouteChecks } from '../../../../shared/logicCreators';
import { locations as apiConfig, images as imagesApiConfig } from '../../../config/api';
import { URL_CHANGE } from '../../../../shared/components/App/App.actions';
import { table as tableConfig } from '../../../config/locations';
import Api from '../../../../shared/api';
import {
  selectDeletedLocationsOpeningHours,
  selectUpdatedLocationsOpeningHours,
  selectCreatedLocationsOpeningHours
} from '../../../../shared/components/Locations/LocationsOpeningHours/LocationsOpeningHours.selectors';
import {
  FETCH_LOCATIONS,
  CANCEL_FETCH_LOCATIONS,
  FETCH_LOCATIONS_SUCCESS,
  fetchLocationsSuccess,
  fetchLocationsError,
  fetchLocation,
  selectLocationTable,
  FETCH_LOCATION,
  CANCEL_FETCH_LOCATION,
  fetchLocationSuccess,
  fetchLocationError,
  SAVE_LOCATION,
  CANCEL_SAVE_LOCATION,
  SAVE_LOCATION_SUCCESS,
  saveLocationSuccess,
  saveLocationError,
  SAVE_LOCATION_IMAGE,
  CANCEL_SAVE_LOCATION_IMAGE,
  saveLocationImageSuccess,
  saveLocationImageError,
  DELETE_LOCATION_IMAGE,
  CANCEL_DELETE_LOCATION_IMAGE,
  deleteLocationImageSuccess,
  deleteLocationImageError,
  PATCH_LOCATION_IMAGES,
  CANCEL_PATCH_LOCATION_IMAGES,
  patchLocationImagesSuccess,
  patchLocationImagesError,
  removeLocationSuccess,
  removeLocationError,
  REMOVE_LOCATION,
  CANCEL_REMOVE_LOCATION,
  REMOVE_LOCATION_SUCCESS,
  REMOVE_GEOPOINT,
  CANCEL_REMOVE_GEOPOINT,
  removeGeopointSuccess,
  removeGeopointError,
} from '../../../../shared/components/Locations/Locations.actions';
import {
  SAVE_LOCATIONS_OPENING_HOURS,
  CANCEL_SAVE_LOCATIONS_OPENING_HOURS,
  fetchLocationsOpeningHoursSuccess,
  saveLocationsOpeningHoursError,
  FETCH_LOCATIONS_OPENING_HOURS,
  CANCEL_FETCH_LOCATIONS_OPENING_HOURS
} from '../../../../shared/components/Locations/LocationsOpeningHours/LocationsOpeningHours.actions';
import {
  SAVE_LOCATIONS_ENTRANCE,
  CANCEL_SAVE_LOCATIONS_ENTRANCE,
  saveLocationsEntranceError,
  fetchLocationsEntrances,
  FETCH_LOCATIONS_ENTRANCES,
  CANCEL_FETCH_LOCATIONS_ENTRANCES,
  fetchLocationsEntrancesSuccess,
  fetchLocationsEntrancesError,
  deleteLocationsEntranceSuccess,
} from '../../../../shared/components/Locations/LocationsEntrance/LocationsEntrance.actions';

import { getCurrentEntrance } from './EntranceTypes/EntranceTypes.selectors';
import { saveEntrance as saveInfoEntrance } from './EntranceTypes/EntranceInfo/EntranceInfo.logic';
import { saveEntrance as saveUnlocEntrance } from './EntranceTypes/EntranceUnloc/EntranceUnloc.logic';

export const getLocationsOpeningHoursRequests = (deletedItems, updatedItems, createdItems, locationId) => {
  let patchRequests = [];
  let deleteRequests = [];
  let createRequests = [];

  if (updatedItems.length) {
    patchRequests = [
      Api({
        method: 'PUT',
        path: `${apiConfig.path}/${locationId}${apiConfig.openinghoursPath}`,
        handle: r => {
          return r.send(updatedItems);
        }
      })
    ];
  }

  if (deletedItems.length) {
    deleteRequests = deletedItems.map(id => {
      return Api({
        method: 'DELETE',
        path: `${apiConfig.path}/${locationId}${apiConfig.openinghoursPath}/${id}`,
      });
    });
  }

  if (createdItems.length) {
    createRequests = createdItems.map(item => {
      return Api({
        method: 'POST',
        path: `${apiConfig.path}/${locationId}${apiConfig.openinghoursPath}`,
        handle: r => {
          return r.send(item);
        }
      });
    });
  }

  return [
    ...patchRequests,
    ...deleteRequests,
    ...createRequests
  ];
}

const fetchLocationsLogic = createLogicWithApi({
  type: FETCH_LOCATIONS,
  cancelType: CANCEL_FETCH_LOCATIONS,
  latest: true,
  process: ({ Api, getState, action }, dispatch, done) => {
    return Api({
      method: 'get',
      path: apiConfig.path,
      handle: r => {
        const state = getState();
        const sort = action.sort ? action.sort : state.table.items[tableConfig.name].sort;
        const desc = typeof action.desc !== 'undefined' ? action.desc : state.table.items[tableConfig.name].desc;
        const filters = typeof action.filters !== 'undefined' ? action.filters : state.table.filters;
        const search = typeof action.search !== 'undefined' ? action.search : state.table.search;
        const limit =  typeof action.limit !== 'undefined' ? action.limit : state.table.items[tableConfig.name].limit;
        const offset =  typeof action.offset !== 'undefined' ? action.offset : state.table.items[tableConfig.name].offset;

        if (offset) {
          r.query({'offset': offset});
        }

        r
          .query({'limit': limit})
          .query({'filters': filters})
          .query({'query': search})
          .query({'order[field]': sort})
          .query({'order[dir]': desc ? 'desc' : 'asc' });

        return r;
      }
    })
    .then(resp => {
      dispatch(fetchLocationsSuccess(resp));
      done();
    })
    .catch(err => {
      dispatch(fetchLocationsError(err));
      done();
    });
  }
});

const checkFetchedLocationSelectionLogic = createLogicWithRouteChecks({
  type: FETCH_LOCATIONS_SUCCESS,
  process: ({ checkInitSelect, getUrl }, dispatch, done) => {
    const selectId = checkInitSelect('locations');
    if (selectId) {
      dispatch(replace(`${getUrl('locations')}/${selectId}${window.location.search}`));
    }
    done();
  }
});

const checkLocationRouteLogic = createLogicWithRouteChecks({
  type: URL_CHANGE,
  process: ({ checkUnselectedIdParam, checkInitSelect, getUrl }, dispatch, done) => {
    const selectId = checkInitSelect('locations');
    if (selectId) {
      dispatch(replace(`${getUrl('locations')}/${selectId}${window.location.search}`));
    } else {
      const newIdParam = checkUnselectedIdParam('locations');
      if (newIdParam !== false) {
        dispatch(selectLocationTable(newIdParam));
        dispatch(fetchLocation({id:newIdParam}));
      }
    }
    done();
  }
});

const fetchLocationLogic = createLogicWithApi({
  type: FETCH_LOCATION,
  cancelType: CANCEL_FETCH_LOCATION,
  latest: true,
  process: ({ action, Api }, dispatch, done) => {
    return Api({
      method: 'get',
      path: `${apiConfig.path}/${action.payload.id}`
    })
    .then(resp => {
      dispatch(fetchLocationSuccess(resp));
      dispatch(fetchLocationsEntrances(action.payload.id));
      done();
    })
    .catch(err => {
      dispatch(fetchLocationError(err));
      done();
    });
  }
});

const saveLocationLogic = createLogicWithApi({
  type: SAVE_LOCATION,
  cancelType: CANCEL_SAVE_LOCATION,
  process: ({ action, Api }, dispatch, done) => {
    const method = action.payload.id ? 'patch' : 'post',
          path = `${apiConfig.path}${action.payload.id ? `/${action.payload.id}` : ''}`;
    return Api({
      method: method,
      path: path,
      handle: r => {
        return r.send({
          attributes: action.payload,
          type: 'location',
          id: action.payload.id ? action.payload.id : null
        });
      }
    })
    .then(resp => {
      dispatch(saveLocationSuccess(resp, action.nextStep, action.payload.id));
      done();
    })
    .catch(err => {
      dispatch(saveLocationError(action.payload.id, err));
      done();
    });
  }
});

const saveLocationSuccessLogic = createLogicWithRouteChecks({
  type: SAVE_LOCATION_SUCCESS,
  process: ({ getUrl, state, action }, dispatch, done) => {
    const id = action.payload.data.id || action.id;
    if (action.nextStep) {
      if (action.nextStep === 2) {
        dispatch(replace(`${getUrl('locations')}/${id}${state.locale.actions.create.path}/1${window.location.search}`));
      }
      dispatch(push(`${getUrl('locations')}/${id}${state.locale.actions.create.path}/${action.nextStep}${window.location.search}`));
    } else {
      dispatch(push(`${getUrl('locations')}/${id}${window.location.search}`));
    }
    done();
  }
});

const saveLocationImageLogic = createLogicWithApi({
  type: SAVE_LOCATION_IMAGE,
  cancelType: CANCEL_SAVE_LOCATION_IMAGE,
  process: ({ action, Api }, dispatch, done) => {
    const method = action.payload.id ? 'patch' : 'post',
          path = `${apiConfig.path}/${action.location}${imagesApiConfig.path}${action.payload.id ? `/${action.payload.id}` : ''}`;
    return Api({
      method: method,
      path: path,
      handle: r => {
        if (action.payload.imageFile) {
          r
            .field('data[attributes][image][state]', 'published')
            .attach('data[attributes][image][imageFile]',action.payload.imageFile);
        } else {
          r.send({
            attributes: { image: omit(action.payload, ['id']) },
            type: 'location_image',
            id: action.payload.id ? action.payload.id : null
          });
        }
        return r;
      }
    })
    .then(resp => {
      dispatch(saveLocationImageSuccess(action.location, resp));
      done();
    })
    .catch(err => {
      dispatch(saveLocationImageError(action.location, err));
      done();
    });
  }
});

const deleteLocationImageLogic = createLogicWithApi({
  type: DELETE_LOCATION_IMAGE,
  cancelType: CANCEL_DELETE_LOCATION_IMAGE,
  process: ({ action, Api }, dispatch, done) => {
    return Api({
      method: 'delete',
      path: `${apiConfig.path}/${action.location}${imagesApiConfig.path}/${action.image}`
    })
    .then(() => {
      dispatch(deleteLocationImageSuccess(action.location, action.image));
      done();
    })
    .catch(err => {
      dispatch(deleteLocationImageError(action.location, err));
      done();
    });
  }
});

const patchLocationImagesLogic = createLogicWithApi({
  type: PATCH_LOCATION_IMAGES,
  cancelType: CANCEL_PATCH_LOCATION_IMAGES,
  process: ({ action, Api }, dispatch, done) => {
    return Api({
      method: 'patch',
      path: `${apiConfig.path}/${action.location}${imagesApiConfig.path}`,
      handle: r => {
        return r.send(action.images);
      }
    })
    .then(() => {
      dispatch(patchLocationImagesSuccess(action.location, action.images));
      done();
    })
    .catch(err => {
      dispatch(patchLocationImagesError(action.location, err));
      done();
    });
  }
});

const saveLocationsOpeningHoursLogic = createLogicWithApi({
  type: SAVE_LOCATIONS_OPENING_HOURS,
  cancelType: CANCEL_SAVE_LOCATIONS_OPENING_HOURS,
  process: async ({ action, Api }, dispatch, done) => {
    const { openingHours, specialOpeningHours, closedDays, openingHoursInfo } = action.payload;
    const deletedItems = selectDeletedLocationsOpeningHours(specialOpeningHours.concat(closedDays));
    const updatedItems = openingHours.concat(selectUpdatedLocationsOpeningHours(specialOpeningHours.concat(closedDays)));
    const createdItems = selectCreatedLocationsOpeningHours(specialOpeningHours, closedDays);
    const sendRequests = getLocationsOpeningHoursRequests(deletedItems, updatedItems, createdItems, action.id);
    return Promise.all(sendRequests)
      .then(() => Api({
        method: 'PATCH',
        path: `${apiConfig.path}/${action.id}`,
        handle: r => {
          return r.send({
            data:{
              attributes: {
                openingHoursInfo: openingHoursInfo
              }
            }
          });
        }
      }))
      .then(resp => {
        dispatch(saveLocationSuccess(resp, action.nextStep, action.id));
        done();
      })
      .catch(err => {
        dispatch(saveLocationsOpeningHoursError(action.id, err));
        done();
      });
  }
});

const fetchLocationsOpeningHoursLogic = createLogicWithApi({
  type: FETCH_LOCATIONS_OPENING_HOURS,
  cancelType: CANCEL_FETCH_LOCATIONS_OPENING_HOURS,
  latest: true,
  process: ({ Api, action }, dispatch, done) => {
    return Api({
      method: 'GET',
      path: `${apiConfig.path}/${action.id}${apiConfig.openinghoursPath}`,
      handle: r => {
        r
          .query({'offset': 0})
          .query({'limit': 0})
        return r;
      }
    })
    .then(resp => {
      dispatch(fetchLocationsOpeningHoursSuccess(action.id, resp, action.nextStep));
      done();
    })
    .catch(err => {
      dispatch(fetchLocationsError(err));
      done();
    });
  }
});

const removeLocationLogic = createLogicWithApi({
  type: REMOVE_LOCATION,
  cancelType: CANCEL_REMOVE_LOCATION,
  latest: true,
  process: ({ action, Api }, dispatch, done) => {
    return Api({
      method: 'DELETE',
      path: `${apiConfig.path}/${action.id}`,
    })
      .then(() => {
        dispatch(removeLocationSuccess({id: action.id}));
        done();
      })
      .catch(err => {
        dispatch(removeLocationError(err));
        done();
      })
  }
});

const checkRemoveLocationLogic = createLogicWithRouteChecks({
  type: REMOVE_LOCATION_SUCCESS,
  process: (_, dispatch, done) => {
    dispatch(
      push(`${apiConfig.path}${window.location.search}`)
    );

    done();
  }
});

const removeGeopointLogic = createLogicWithApi({
  type: REMOVE_GEOPOINT,
  cancelType: CANCEL_REMOVE_GEOPOINT,
  latest: true,
  process: ({ action, Api }, dispatch, done) => {
    return Api({
      method: 'PATCH',
      path: `${apiConfig.path}/${action.id}${apiConfig.removeGeopointPath}`,
    })
      .then(() => {
        dispatch(removeGeopointSuccess(action.id));
        done();
      })
      .catch(err => {
        dispatch(removeGeopointError(err));
        done();
      })
  }
});

const saveLocationsEntranceLogic = createLogicWithApi({
  type: SAVE_LOCATIONS_ENTRANCE,
  cancelType: CANCEL_SAVE_LOCATIONS_ENTRANCE,
  process: async ({ action, Api, getState }, dispatch, done) => {
    const { locations: { selected:currentLocation }} = getState();
    const { 
      entranceType,
      ...location
    } = action.payload;

    const entrance = getCurrentEntrance(currentLocation, entranceType);

    let deleteEntrances = [];
    if (
      !entrance 
      && currentLocation.entrances 
      && (
        currentLocation.entrances.length 
        || currentLocation.entrances.length > 1
      )
    ) {
      deleteEntrances = currentLocation.entrances.filter(e => !entrance || String(e.id) !== String(entrance.id));
    }

    return Promise.all(deleteEntrances.map(e => Api({
        method: 'DELETE',
        path: `${apiConfig.path}/${action.id}${apiConfig.entrancePath}/${e.id}`,
      })))
      .then(() => deleteEntrances.map(e => dispatch(deleteLocationsEntranceSuccess(action.id, e.id))))
      .then(() => {
        switch (entranceType) {
          case 'unloc':
            return saveUnlocEntrance(dispatch, action, entrance);
          default: 
            return saveInfoEntrance(dispatch, action, entrance);
        }
      })
      .then(() => {
        if (action.saveLocation) {
          return Api({
            method: 'PATCH',
            path: `${apiConfig.path}/${action.id}`,
            handle: r => {
              return r.send({
                data:{
                  attributes: {
                    ...location
                  }
                }
              });
            }
          }).then(resp => {
            dispatch(fetchLocationsEntrances(resp.data.id));
            dispatch(saveLocationSuccess(resp, action.nextStep, action.id));
            done();
          })
        }
      })
      .catch(err => {
        dispatch(saveLocationsEntranceError(action.id, err));
        done();
      });
  }
});

const fetchLocationsEntrancesLogic = createLogicWithApi({
  type: FETCH_LOCATIONS_ENTRANCES,
  cancelType: CANCEL_FETCH_LOCATIONS_ENTRANCES,
  latest: true,
  process: ({ Api, action }, dispatch, done) => {
    return Api({
      method: 'GET',
      path: `${apiConfig.path}/${action.id}${apiConfig.entrancePath}`,
      handle: r => {
        r
          .query({'offset': 0})
          .query({'limit': 0})
        return r;
      }
    })
    .then(resp => {
      dispatch(fetchLocationsEntrancesSuccess(action.id, resp));
      done();
    })
    .catch(err => {
      dispatch(fetchLocationsEntrancesError(action.id, err));
      done();
    });
  }
});

const logics = [
  fetchLocationsLogic,
  checkFetchedLocationSelectionLogic,
  checkLocationRouteLogic,
  fetchLocationLogic,
  saveLocationLogic,
  saveLocationSuccessLogic,
  saveLocationImageLogic,
  deleteLocationImageLogic,
  patchLocationImagesLogic,
  removeLocationLogic,
  checkRemoveLocationLogic,
  saveLocationsOpeningHoursLogic,
  fetchLocationsOpeningHoursLogic,
  removeGeopointLogic,
  saveLocationsEntranceLogic,
  fetchLocationsEntrancesLogic,
];

export default logics;