export * from './EquipmentVariants/EquipmentVariants.actions';
export * from './EquipmentInstance/EquipmentInstance.actions';

export const FETCH_EQUIPMENT = '@@equipment/FETCH_EQUIPMENT';
export const FETCH_EQUIPMENT_SUCCESS = '@@equipment/FETCH_EQUIPMENT_SUCCESS';
export const FETCH_EQUIPMENT_ERROR = '@@equipment/FETCH_EQUIPMENT_ERROR';
export const CANCEL_FETCH_EQUIPMENT = '@@equipment/CANCEL_FETCH_EQUIPMENT';
export const RESET_EQUIPMENT = '@@equipment/RESET_EQUIPMENT';

export const FETCH_EQUIPMENT_BY_ID = '@@equipment/FETCH_EQUIPMENT_BY_ID';
export const FETCH_EQUIPMENT_BY_SLUG = '@@equipment/FETCH_EQUIPMENT_BY_SLUG';
export const FETCH_EQUIPMENT_BY_ID_SUCCESS = '@@equipment/FETCH_EQUIPMENT_BY_ID_SUCCESS';
export const FETCH_EQUIPMENT_BY_ID_ERROR = '@@equipment/FETCH_EQUIPMENT_BY_ID_ERROR';
export const CANCEL_FETCH_BY_ID_EQUIPMENT = '@@equipment/CANCEL_FETCH_BY_ID_EQUIPMENT';

export const SAVE_EQUIPMENT_BY_ID = '@@equipment/SAVE_EQUIPMENT_BY_ID';
export const SAVE_EQUIPMENT_BY_ID_SUCCESS = '@@equipment/SAVE_EQUIPMENT_BY_ID_SUCCESS';
export const SAVE_EQUIPMENT_BY_ID_ERROR = '@@equipment/SAVE_EQUIPMENT_BY_ID_ERROR';
export const CANCEL_SAVE_EQUIPMENT_BY_ID = '@@equipment/CANCEL_SAVE_EQUIPMENT_BY_ID';

export const REMOVE_EQIPMENT_BY_ID = '@@equipment/REMOVE_EQIPMENT_BY_ID';
export const REMOVE_EQIPMENT_BY_ID_SUCCESS = '@@equipment/REMOVE_EQIPMENT_BY_ID_SUCCESS';
export const REMOVE_EQIPMENT_BY_ID_ERROR = '@@equipment/REMOVE_EQIPMENT_BY_ID_ERROR';
export const CANCEL_REMOVE_EQIPMENT_BY_ID = '@@equipment/CANCEL_REMOVE_EQIPMENT_BY_ID';

export const SAVE_EQUIPMENT_IMAGE = '@@equipment/SAVE_EQUIPMENT_IMAGE';
export const SAVE_EQUIPMENT_IMAGE_SUCCESS = '@@equipment/SAVE_EQUIPMENT_IMAGE_SUCCESS';
export const SAVE_EQUIPMENT_IMAGE_ERROR = '@@equipment/SAVE_EQUIPMENT_IMAGE_ERROR';
export const CANCEL_SAVE_EQUIPMENT_IMAGE = '@@equipment/CANCEL_SAVE_EQUIPMENT_IMAGE';

export const DELETE_EQUIPMENT_IMAGE = '@@equipment/DELETE_EQUIPMENT_IMAGE';
export const DELETE_EQUIPMENT_IMAGE_SUCCESS = '@@equipment/DELETE_EQUIPMENT_IMAGE_SUCCESS';
export const DELETE_EQUIPMENT_IMAGE_ERROR = '@@equipment/DELETE_EQUIPMENT_IMAGE_ERROR';
export const CANCEL_DELETE_EQUIPMENT_IMAGE = '@@equipment/CANCEL_DELETE_EQUIPMENT_IMAGE';

export const PATCH_EQUIPMENT_IMAGES = '@@equipment/PATCH_EQUIPMENT_IMAGES';
export const PATCH_EQUIPMENT_IMAGES_SUCCESS = '@@equipment/PATCH_EQUIPMENT_IMAGES_SUCCESS';
export const PATCH_EQUIPMENT_IMAGES_ERROR = '@@equipment/PATCH_EQUIPMENT_IMAGES_ERROR';
export const CANCEL_PATCH_EQUIPMENT_IMAGES = '@@equipment/CANCEL_PATCH_EQUIPMENT_IMAGES';

export const MOVE_STEP_BACK = '@@equipment/MOVE_STEP_BACK';
export const MOVE_NEXT_STEP = '@@equipment/MOVE_NEXT_STEP';
export const RESET_STEPS = '@@equipment/RESET_STEPS';

export const TABLE_SELECT_EQUIPMENT = '@@equipment/TABLE_SELECT_EQUIPMENT';

export const selectEquipmentTable = id => ({
  type: TABLE_SELECT_EQUIPMENT,
  id
});

export const moveStepBack = () => ({
  type: MOVE_STEP_BACK
});

export const moveNextStep = () => ({
  type: MOVE_NEXT_STEP
});

export const resetSteps = () => ({
  type: RESET_STEPS
});

export const fetchEquipment = config => ({
  type: FETCH_EQUIPMENT,
  ...config
});

export const fetchEquipmentSuccess = resp => ({
  type: FETCH_EQUIPMENT_SUCCESS,
  payload: resp
});

export const fetchEquipmentError = err => ({
  type: FETCH_EQUIPMENT_ERROR,
  payload: err
});

export const fetchEquipmentById = config => ({
  type: FETCH_EQUIPMENT_BY_ID,
  ...config
});

export const fetchEquipmentBySlug = config => ({
  type: FETCH_EQUIPMENT_BY_SLUG,
  ...config
});

export const fetchEquipmentByIdSuccess = resp => ({
  type: FETCH_EQUIPMENT_BY_ID_SUCCESS,
  payload: resp
});

export const fetchEquipmentByIdError = err => ({
  type: FETCH_EQUIPMENT_BY_ID_ERROR,
  payload: err
});

export const saveEquipmentById = (payload, nextStep) => ({
  type: SAVE_EQUIPMENT_BY_ID,
  payload,
  nextStep
});

export const saveEquipmentByIdSuccess = (payload, nextStep, id) => ({
  type: SAVE_EQUIPMENT_BY_ID_SUCCESS,
  payload,
  nextStep,
  id
});

export const saveEquipmentByIdError = err => ({
  type: SAVE_EQUIPMENT_BY_ID_ERROR,
  payload: err
});

export const resetEquipment = () => ({
  type: RESET_EQUIPMENT
});

export const removeEquipmentById = payload => ({
  type: REMOVE_EQIPMENT_BY_ID,
  ...payload
});

export const removeEquipmentByIdSuccess = payload => ({
  type: REMOVE_EQIPMENT_BY_ID_SUCCESS,
  ...payload
});

export const removeEquipmentByIdError = err => ({
  type: REMOVE_EQIPMENT_BY_ID_ERROR,
  payload: err
});

export function saveEquipmentImage(equipment, image) {
  return {
    type: SAVE_EQUIPMENT_IMAGE,
    equipment,
    payload: image
  };
}

export function saveEquipmentImageSuccess(equipment, resp) {
  return {
    type: SAVE_EQUIPMENT_IMAGE_SUCCESS,
    equipment,
    payload: resp
  };
}

export function saveEquipmentImageError(err) {
  return {
    type: SAVE_EQUIPMENT_IMAGE_ERROR,
    payload: err
  };
}

export function deleteEquipmentImage(equipment, image) {
  return {
    type: DELETE_EQUIPMENT_IMAGE,
    equipment,
    image
  };
}

export function deleteEquipmentImageSuccess(equipment, id) {
  return {
    type: DELETE_EQUIPMENT_IMAGE_SUCCESS,
    equipment,
    id
  };
}

export function deleteEquipmentImageError(err) {
  return {
    type: DELETE_EQUIPMENT_IMAGE_ERROR,
    payload: err
  };
}

export function patchEquipmentImages(equipment, images) {
  return {
    type: PATCH_EQUIPMENT_IMAGES,
    equipment,
    images
  };
}

export function patchEquipmentImagesSuccess(equipment, images) {
  return {
    type: PATCH_EQUIPMENT_IMAGES_SUCCESS,
    equipment,
    images
  };
}

export function patchEquipmentImagesError(err) {
  return {
    type: PATCH_EQUIPMENT_IMAGES_ERROR,
    payload: err
  };
}
