import { createGlobalStyle } from 'styled-components';

import vars from '../../variables';

const FormPlaceholderGlobalStyles = createGlobalStyle`
  /* stylelint-disable-next-line */
  ::-webkit-input-placeholder {
    color: ${vars.colorGray};
  }

  /* stylelint-disable-next-line */
  ::-moz-placeholder {
    color: ${vars.colorGray};
  }

  /* stylelint-disable-next-line */
  :-ms-input-placeholder {
    color: ${vars.colorGray};
  }

  /* stylelint-disable-next-line */
  :-moz-placeholder {
    color: ${vars.colorGray};
    opacity: 1;
  }
`;

export default FormPlaceholderGlobalStyles;