export const PATCH_IMAGES_SUCCESS   = '@@images/PATCH_IMAGES_SUCCESS';
export const SAVE_IMAGE_SUCCESS     = '@@images/SAVE_IMAGE_SUCCESS';
export const DELETE_IMAGE_SUCCESS   = '@@images/DELETE_IMAGE_SUCCESS';

export const patchImagesSuccess = action => ({
  ...action,
  type: PATCH_IMAGES_SUCCESS
});

export const saveImageSuccess = action => ({
  ...action,
  type: SAVE_IMAGE_SUCCESS
});

export const deleteImageSuccess = action => ({
  ...action,
  type: DELETE_IMAGE_SUCCESS
});
