export const VIEW_LOCATION = 'location.view';
export const CREATE_LOCATION = 'location.create';
export const EDIT_LOCATION = 'location.edit';
export const DELETE_LOCATION = 'location.remove';

export const VIEW_USER = 'user.view';
export const CREATE_USER = 'user.create';
export const EDIT_USER = 'user.edit';
export const DELETE_USER = 'user.remove';

export const VIEW_LOAN = 'loan.view';
export const CREATE_LOAN = 'loan.create';
export const EDIT_LOAN = 'loan.edit';
export const DELETE_LOAN = 'loan.remove';

export const VIEW_RESERVATION = 'reservation.view';
export const CREATE_RESERVATION = 'reservation.create';
export const EDIT_RESERVATION = 'reservation.edit';
export const DELETE_RESERVATION = 'reservation.remove';

export const VIEW_EQUIPMENT = 'equipment.view';
export const CREATE_EQUIPMENT = 'equipment.create';
export const EDIT_EQUIPMENT = 'equipment.edit';
export const DELETE_EQUIPMENT = 'equipment.remove';

export const VIEW_CATEGORY = 'equipmentcategory.view';
export const CREATE_CATEGORY = 'equipmentcategory.create';
export const EDIT_CATEGORY = 'equipmentcategory.edit';
export const DELETE_CATEGORY = 'equipmentcategory.remove';

// Reusing USER permissions in organizations
export const VIEW_ORGANIZATION = 'user.view';
export const CREATE_ORGANIZATION = 'user.create';
export const EDIT_ORGANIZATION = 'user.edit';
export const DELETE_ORGANIZATION = 'user.remove';

export const VIEW_SETTINGS = 'settings.view';
export const CREATE_SETTINGS = 'settings.create';
export const EDIT_SETTINGS = 'settings.edit';
export const DELETE_SETTINGS = 'settings.remove';

export const VIEW_ADMIN_USER = 'admin_user.view';
export const CREATE_ADMIN_USER = 'admin_user.create';
export const EDIT_ADMIN_USER = 'admin_user.edit';
export const DELETE_ADMIN_USER = 'admin_user.remove';
