export const FETCH_LOCATIONS_ENTRANCES = '@@settings/FETCH_LOCATIONS_ENTRANCES';
export const FETCH_LOCATIONS_ENTRANCES_SUCCESS = '@@settings/FETCH_LOCATIONS_ENTRANCES_SUCCESS';
export const FETCH_LOCATIONS_ENTRANCES_ERROR = '@@settings/FETCH_LOCATIONS_ENTRANCES_ERROR';
export const CANCEL_FETCH_LOCATIONS_ENTRANCES = '@@settings/CANCEL_FETCH_LOCATIONS_ENTRANCES';

export const SAVE_LOCATIONS_ENTRANCE = '@@locations/SAVE_LOCATIONS_ENTRANCE';
export const SAVE_LOCATIONS_ENTRANCE_SUCCESS = '@@locations/SAVE_LOCATIONS_ENTRANCE_SUCCESS';
export const SAVE_LOCATIONS_ENTRANCE_ERROR = '@@locations/SAVE_LOCATIONS_ENTRANCE_ERROR';
export const CANCEL_SAVE_LOCATIONS_ENTRANCE = '@@locations/CANCEL_SAVE_LOCATIONS_ENTRANCE';
export const DELETE_LOCATIONS_ENTRANCE_SUCCESS = '@@locations/DELETE_LOCATIONS_ENTRANCE_SUCCESS';

export const SAVE_LOCATIONS_ENTRANCE_ADMIN = '@@locations/SAVE_LOCATIONS_ENTRANCE_ADMIN';
export const SAVE_LOCATIONS_ENTRANCE_ADMIN_SUCCESS = '@@locations/SAVE_LOCATIONS_ENTRANCE_ADMIN_SUCCESS';
export const SAVE_LOCATIONS_ENTRANCE_ADMIN_ERROR = '@@locations/SAVE_LOCATIONS_ENTRANCE_ADMIN_ERROR';
export const CANCEL_SAVE_LOCATIONS_ENTRANCE_ADMIN = '@@locations/CANCEL_SAVE_LOCATIONS_ENTRANCE_ADMIN';
export const DELETE_LOCATIONS_ENTRANCE_ADMIN_SUCCESS = '@@locations/DELETE_LOCATIONS_ENTRANCE_ADMIN_SUCCESS';

export const fetchLocationsEntrances = id => ({
  type: FETCH_LOCATIONS_ENTRANCES,
  id
});

export const fetchLocationsEntrancesSuccess = (id, data) => ({
  type: FETCH_LOCATIONS_ENTRANCES_SUCCESS,
  id,
  payload: data
});

export const fetchLocationsEntrancesError = (id, err) => ({
  type: FETCH_LOCATIONS_ENTRANCES_ERROR,
  id,
  payload: err
});

export const saveLocationsEntrance = (id, data, saveLocation, nextStep) => ({
  type: SAVE_LOCATIONS_ENTRANCE,
  id,
  payload: data,
  saveLocation,
  nextStep
});

export const saveLocationsEntranceSuccess = (resp, id) => ({
  type: SAVE_LOCATIONS_ENTRANCE_SUCCESS,
  id,
  payload: resp
});

export const saveLocationsEntranceError = (id, err) => ({
  type: SAVE_LOCATIONS_ENTRANCE_ERROR,
  id,
  payload: err
});

export const deleteLocationsEntranceSuccess = (locationId, entranceId) => ({
  type: DELETE_LOCATIONS_ENTRANCE_SUCCESS,
  locationId,
  entranceId
});

export const saveLocationsEntranceAdmin = (id, data) => ({
  type: SAVE_LOCATIONS_ENTRANCE_ADMIN,
  id,
  payload: data
});

export const saveLocationsEntranceAdminSuccess = (
  locationId,
  entranceId,
  resp
) => ({
  type: SAVE_LOCATIONS_ENTRANCE_ADMIN_SUCCESS,
  locationId,
  entranceId,
  payload: resp
});

export const saveLocationsEntranceAdminError = (id, err) => ({
  type: SAVE_LOCATIONS_ENTRANCE_ADMIN_ERROR,
  id,
  payload: err
});

export const deleteLocationsEntranceAdminSuccess = (
  locationId,
  entranceId
) => ({
  type: DELETE_LOCATIONS_ENTRANCE_ADMIN_SUCCESS,
  locationId,
  entranceId
});
