import React from 'react';
import PropTypes from 'prop-types';

import IconLoadingA from '../../../assets/icons/icon-loading-a';

import StyledLoading from './Loading.styled';

const Loading = ({ fadeStyle, ...props }) => {
  return (
    <StyledLoading {...props} $fadeStyle={ fadeStyle } tabIndex={-1}>
      <IconLoadingA {...props} />
    </StyledLoading>
  );
};

Loading.defaultProps = {
  width: 20,
  height: 20,
  fill: '#000'
};

Loading.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
  fadeStyle: PropTypes.bool
};

export default Loading;
