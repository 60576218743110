import Promise from 'pinkie-promise';
import { push } from '@lagunovsky/redux-react-router';
import { omit } from 'lodash';

import { loans as apiConfig, locations as locationsApiConfig, instances as instancesApiConfig } from '../../../../config/api';
import { getSelectedLoan, getTemporaryPublishedLoanItems } from '../../../../../shared/components/Loans/Loans.selectors';
import { createLogic, createLogicWithApi, createLogicWithRouteChecks } from '../../../../../shared/logicCreators';
import { showNotification } from '../../../../../shared/components/common/Dialogs/Dialogs.actions';
import { tableLoanItems as tableConfig } from '../../../../config/loans';
import { LOAN_DELIVER_FORM } from '../../../../../shared/config/loans';
import { setTempState } from '../../../common/Form/Form.actions';
import {
  UPDATE_LOAN_ITEMS,
  CANCEL_UPDATE_LOAN_ITEMS,
  UPDATE_LOAN_ITEMS_SUCCESS,
  PATCH_LOAN_ITEMS,
  CANCEL_PATCH_LOAN_ITEMS,
  PATCH_LOAN_ITEMS_SUCCESS,
  FETCH_LOAN_ITEMS,
  CANCEL_FETCH_LOAN_ITEMS,
  updateLoanItemsSuccess,
  updateLoanItemsError,
  patchLoanItemsSuccess,
  patchLoanItemsError,
  fetchLoanItems,
  fetchLoanItemsError,
  fetchLoanItemsSuccess,
  fetchLoan,
  saveLoan,
  updateLoanItems,
  ADD_LOAN_ITEM_BY_IDENTIFIER,
  CANCEL_ADD_LOAN_ITEM_BY_IDENTIFIER,
  addLoanItemByIdentifierError,
  saveLoanProgress,
} from '../../../../../shared/components/Loans/Loans.actions';
import { 
  SCANNER_ACTION_LOAN_DELIVERY, 
  SCANNER_ACTION_LOAN_CREATION,
  equipmentScanCompleted
} from '../../../common/PubSub/PubSub.actions';

const fetchLoanItemsLogic = createLogicWithApi({
  type: FETCH_LOAN_ITEMS,
  cancelType: CANCEL_FETCH_LOAN_ITEMS,
  latest: true,
  process: ({ Api, getState, action }, dispatch, done) => {
    const state = getState();
    const location = action.location ? action.location : state.table.currentLocation;

    return Api({
      method: 'GET',
      path: `${locationsApiConfig.path}/${location}${apiConfig.path}/${action.loan}${apiConfig.itemsPath}`,
      handle: r => {
        const state = getState();
        const sort = action.sort ? action.sort : state.table.items[tableConfig.name].sort;
        const desc = action.desc ? action.desc : state.table.items[tableConfig.name].desc;

        r
          .query({'limit': 0})
          .query({'filters': {state: 'temporary,published'}})
          .query({'order[field]': sort})
          .query({'order[dir]': desc ? 'desc' : 'asc'});
        return r;
      }
    })
      .then(resp => {
        dispatch(fetchLoanItemsSuccess(resp));
        done();
      })
      .then(err => {
        dispatch(fetchLoanItemsError(err));
        done();
      })
  }
});

const updateLoanItemsLogic = createLogicWithApi({
  type: UPDATE_LOAN_ITEMS,
  cancelType: CANCEL_UPDATE_LOAN_ITEMS,
  process: ({ action, Api, getState}, dispatch, done) => {
    const state = getState();
    const location = action.location 
      ? action.location 
      : state.table.currentLocation;
    const items = action.payload.items && action.payload.items.length 
      ? action.payload.items 
      : [];

    let creations = [];
    let promises = [];
    
    items.forEach(item => {
      if (item.action === 'remove') {
        promises.push(
          Api({
            method: 'DELETE',
            path: `${locationsApiConfig.path}/${location}${apiConfig.path}/${action.payload.id}${apiConfig.itemsPath}/${item.id}`,
          })
        );

      } else if (item.action === 'update') {
        promises.push(
          Api({
            method: 'PATCH',
            path: `${locationsApiConfig.path}/${location}${apiConfig.path}/${action.payload.id}${apiConfig.itemsPath}/${item.id}`,
            handle: r => r.send({attributes: omit(item, ['action'])})
          })
        );

      } else if (item.action === 'create') {
        creations.push(omit(item, ['action']))
      }
    });

    if (creations.length) {
      promises.push(
        Api({
          method: 'POST',
          path: `${locationsApiConfig.path}/${location}${apiConfig.path}/${action.payload.id}${apiConfig.itemsPath}${apiConfig.listPath}`,
          handle: (r) => {
            return r.send({
              attributes: {
                loanItems: creations
              }
            });
          }
        })
      )
    }

    return Promise.all(promises)
      .then(resp => {
        dispatch(updateLoanItemsSuccess(action.payload.id, resp, action.payload.nextStep));
        done();
      })
      .catch(err => {
        dispatch(updateLoanItemsError(err));
        dispatch(equipmentScanCompleted());
        done();
      })
  }
});

const updateLoanItemsSuccessLogic = createLogicWithRouteChecks({
  type: UPDATE_LOAN_ITEMS_SUCCESS,
  process: ({ getUrl, state, action }, dispatch, done) => {
    const location = action.location || state.table.currentLocation;
    const selected = action.id;

    dispatch(fetchLoanItems({ loan: selected }));

    if (action.nextStep) {
      dispatch(push(`${getUrl('loans')}/${location}/${action.id}${state.locale.actions.create.path}/${action.nextStep}${window.location.search}`));

    } else if (action.nextStep !== false) {
      dispatch(push(`${getUrl('loans')}/${location}/${selected}${window.location.search}`));
    }

    dispatch(equipmentScanCompleted());
    done();
  }
});

const patchLoanItemsLogic = createLogicWithApi({
  type: PATCH_LOAN_ITEMS,
  cancelType: CANCEL_PATCH_LOAN_ITEMS,
  process: ({ action, Api, getState }, dispatch, done) => {
    const state = getState();
    const location = action.location 
      ? action.location 
      : state.table.currentLocation;
    const items = action.payload.items && action.payload.items.length 
      ? action.payload.items 
      : [];
    const data = items.map(i => ({
      attributes: omit(i, ['id']),
      type: 'loan_item',
      id: i.id,
    }));

    dispatch(saveLoanProgress('started', {
      id: action.payload.id,
      progress: 0,
      loanShouldBeClosed: action.payload.loanShouldBeClosed
    }));
    
    return Api({
      method: 'PATCH',
      path: `${locationsApiConfig.path}/${location}${apiConfig.path}/${action.payload.id}${apiConfig.itemsPath}`,
      handle: r => {
        return r.send(data);
      }
    })
      .then(resp => {
        if (resp && resp.statusCode && resp.statusCode !== 202) {
          dispatch(saveLoanProgress('finished_success', {
            id: action.payload.id,
            progress: 100,
            loanShouldBeClosed: action.payload.loanShouldBeClosed
          }));
        }

        dispatch(patchLoanItemsSuccess(action.payload.id, resp, action.payload.loanShouldBeClosed));

        if (action.payload.publishLoan) {
          dispatch(saveLoan({
            id: action.payload.id,
            state: 'published'
          }));
        }

        done();
      })
      .catch(err => {
        dispatch(patchLoanItemsError(err));
        done();
      });
  }
});

const patchLoanItemsSuccessLogic = createLogicWithRouteChecks({
  type: PATCH_LOAN_ITEMS_SUCCESS,
  process: ({ getUrl, state, action }, dispatch, done) => {
    const location = action.location ? action.location : state.table.currentLocation;
    const selected = action.id;
    if (action.nextStep) {
      dispatch(push(`${getUrl('loans')}/${location}/${action.id}${state.locale.actions.create.path}/${action.nextStep}${window.location.search}`));
    } else {
      dispatch(push(`${getUrl('loans')}/${location}/${selected}${window.location.search}`));
      dispatch(
        fetchLoan({
          id: selected,
          location: location
        })
      );
    }
    done();
  }
});

const addLoanItemByIdentifierLogic = createLogicWithApi({
  type: ADD_LOAN_ITEM_BY_IDENTIFIER,
  cancelType: CANCEL_ADD_LOAN_ITEM_BY_IDENTIFIER,
  latest: true,
  process: ({ Api, getState, action }, dispatch, done) => {
    const state = getState();
    const loanItems = getTemporaryPublishedLoanItems(state);
    const selected = getSelectedLoan(state);
    const location = action.location 
      ? action.location 
      : state.table.currentLocation;

    Api({
      method: 'GET',
      path: `${locationsApiConfig.path}/${location}${instancesApiConfig.searchPath}`,
      handle: r => r.query({'query': action.identifier})
    })
      .catch(() => {
        dispatch(addLoanItemByIdentifierError());
        done();
      })
      .then(resp => {
        if (!resp) {
          return;
        }
        
        if (resp.data.attributes.status !== 'available') {
          dispatch(
            showNotification(
              state.locale.loans.messages.instance_unavailable
                .replace('{status}', state.locale.status[resp.data.attributes.status].toLowerCase())
            )
          );

        } else if (
          resp?.statusCode === 200 
          && (
            !loanItems 
            || !loanItems.some(item => String(item.instance.id) === String(resp.data.id))
          )
        ) {
          const existing = loanItems?.find(item => (
            String(item.variant) === String(resp.data.attributes.variant) 
            && !item.instance.id
          ));
          const loanItem = {
            action: existing ? 'update' : 'create',
            instance: resp.data.id,
            variant: resp.data.attributes.variant,
            state: 'temporary'
          };

          if (existing) {
            loanItem.id = existing.id
          }

          dispatch(
            updateLoanItems(
              selected.id,
              [loanItem],
              false
            )
          );

        } else {
          dispatch(showNotification(state.locale.loans.messages.instance_already_in_current_loan));
          dispatch(addLoanItemByIdentifierError());
        }
        done();
      });
  }
});

const scannerActionLoanOverviewLogic = createLogic({
  type: SCANNER_ACTION_LOAN_DELIVERY,
  process: ({ getState, action }, dispatch, done) => {
    const {locale, tempForm:{ tempState }} = getState();
    const items = tempState[LOAN_DELIVER_FORM];
    let itemExists = false;

    if (items) {
      dispatch(setTempState(LOAN_DELIVER_FORM, items.map(item => {
        if (String(item.instance.id) === String(action.payload.data.id)) {
          itemExists = true;
          return {
            ...item,
            status: 'returned'
          };
        }
        return item;
      })));
    }

    if (!itemExists) {
      dispatch(showNotification(locale.loans.messages.instance_not_in_current_loan));
    }

    dispatch(equipmentScanCompleted());
    done();
  }
});

const scannerActionLoanCreationLogic = createLogic({
  type: SCANNER_ACTION_LOAN_CREATION,
  process: ({ action, getState }, dispatch, done) => {
    const state = getState();
    const selected = getSelectedLoan(state);
    const loanItems = getTemporaryPublishedLoanItems(state);

    if (
      loanItems?.some(item => String(item.instance.id) === String(action.payload.data.id)) 
      || (action.payload.data.attributes.lastLoanId && String(action.payload.data.attributes.lastLoanId) === String(selected.id))
    ) {
      dispatch(showNotification(state.locale.loans.messages.instance_already_in_current_loan));
      dispatch(equipmentScanCompleted());

    } else if (action.payload.data.attributes.status !== 'available') {
      dispatch(showNotification(
        state.locale.loans.messages.instance_unavailable
          .replace('{status}', state.locale.status[action.payload.data.attributes.status].toLowerCase())
      ));
      dispatch(equipmentScanCompleted());

    } else {
      const existing = loanItems && loanItems.find(item => (
        String(item.variant) === String(action.payload.data.attributes.variant) 
        && !item.instance.id
      ));

      let loanItem = {
        action: existing ? 'update' : 'create',
        instance: action.payload.data.id,
        variant: action.payload.data.attributes.variant,
        status: 'temporary'
      };

      if (existing) {
        loanItem.id = existing.id
      }
      
      dispatch(
        updateLoanItems(
          selected.id,
          [loanItem],
          false
        )
      );
    }
    done();
  }
});

const logics = [
  fetchLoanItemsLogic,
  updateLoanItemsLogic,
  updateLoanItemsSuccessLogic,
  patchLoanItemsLogic,
  patchLoanItemsSuccessLogic,
  addLoanItemByIdentifierLogic,
  scannerActionLoanOverviewLogic,
  scannerActionLoanCreationLogic
];

export default logics;