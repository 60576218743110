import Promise from 'pinkie-promise';

import { locations as apiConfig } from '../../../../../config/api';
import Api from '../../../../../../shared/api';
import {
  saveLocationsEntranceSuccess,
  deleteLocationsEntranceAdminSuccess,
  saveLocationsEntranceAdminSuccess,
} from '../../../../../../shared/components/Locations/LocationsEntrance/LocationsEntrance.actions';

export const saveEntrance = (dispatch, action, entrance) => {
  const { 
    entranceUnlocInfo,
    entranceUnlocVendor,
    entranceUnlocUsername,
    entranceUnlocPassword,
    entranceAdminsExisting,
    entranceAdminsNew,
    entranceUnlocLockName,
    entranceUnlocDeviceIdentifier,
    entranceUnlocActivationCode,
  } = action.payload;
  const exitingEntrance = !!entrance && entrance.attributes.type === 'lock' && !!entrance.attributes.config && entrance.attributes.config.provider === 'unloc' && entrance;

  return Api({
    method: exitingEntrance ? 'PATCH' : 'POST',
    path: `${apiConfig.path}/${action.id}${apiConfig.entrancePath}${exitingEntrance ? `/${exitingEntrance.id}` : ''}`,
    handle: r => r.send({
      attributes: {
        state: 'published',
        type: 'lock',
        content: entranceUnlocInfo,
        ...(entranceUnlocVendor ? {
          config: {
            provider: 'unloc',
            vendor: entranceUnlocVendor,
            ...(entranceUnlocVendor === 'danalock' ? {
              username: entranceUnlocUsername,
              password: entranceUnlocPassword,
            } : entranceUnlocVendor === 'masterlock' ? {
              lockName: entranceUnlocLockName,
              deviceIdentifier: entranceUnlocDeviceIdentifier,
              activationCode: entranceUnlocActivationCode,
            } : {})
          }
        } : {})
      }
    })
  })
  .then(resp => {
    dispatch(saveLocationsEntranceSuccess(resp, action.id));
      let deleteAdmins = [],
          createAdmins = entranceAdminsNew || [];
      if (resp.data.relationships && resp.data.relationships.admins) {
        deleteAdmins = resp.data.relationships.admins.data.filter(a => !entranceAdminsExisting || !entranceAdminsExisting.find(ea => a.attributes.phoneNumber === ea.phoneNumber));
      }
      return Promise.all(deleteAdmins.map(a => Api({
        method: 'DELETE',
        path: `${apiConfig.path}/${action.id}${apiConfig.entrancePath}/${resp.data.id}/admin/${a.id}`,
      })))
      .then(() => deleteAdmins.map(a => dispatch(deleteLocationsEntranceAdminSuccess(action.id, a.id))))
      .then(() => Promise.all(
        createAdmins.map(a => Api({
          method: 'POST',
          path: `${apiConfig.path}/${action.id}${apiConfig.entrancePath}/${resp.data.id}/admin`,
          handle: r => r.send({
            type: 'entrance_lock_admin',
            attributes: {
              name: a.name,
              phoneNumber: parseInt(a.phoneNumber),
              state: 'published'
            },
          }),
        })
        .then(resp => dispatch(saveLocationsEntranceAdminSuccess(action.id, entrance.id, resp)))
      )));
  });
};
