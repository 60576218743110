export const FETCH_LOCATIONS_OPENING_HOURS = '@@settings/FETCH_LOCATIONS_OPENING_HOURS';
export const FETCH_LOCATIONS_OPENING_HOURS_SUCCESS = '@@settings/FETCH_LOCATIONS_OPENING_HOURS_SUCCESS';
export const FETCH_LOCATIONS_OPENING_HOURS_ERROR = '@@settings/FETCH_LOCATIONS_OPENING_HOURS_ERROR';
export const CANCEL_FETCH_LOCATIONS_OPENING_HOURS = '@@settings/CANCEL_FETCH_LOCATIONS_OPENING_HOURS';

export const SAVE_LOCATIONS_OPENING_HOURS = '@@locations/SAVE_LOCATIONS_OPENING_HOURS';
export const SAVE_LOCATIONS_OPENING_HOURS_SUCCESS = '@@locations/SAVE_LOCATIONS_OPENING_HOURS_SUCCESS';
export const SAVE_LOCATIONS_OPENING_HOURS_ERROR = '@@locations/SAVE_LOCATIONS_OPENING_HOURS_ERROR';
export const CANCEL_SAVE_LOCATIONS_OPENING_HOURS = '@@locations/CANCEL_SAVE_LOCATIONS_OPENING_HOURS';

export const fetchLocationsOpeningHours = id => ({
  type: FETCH_LOCATIONS_OPENING_HOURS,
  id
});

export const fetchLocationsOpeningHoursSuccess = (id, data) => ({
  type: FETCH_LOCATIONS_OPENING_HOURS_SUCCESS,
  id,
  payload: data
});

export const saveLocationsOpeningHours = (id, data, nextStep) => ({
  type: SAVE_LOCATIONS_OPENING_HOURS,
  id,
  payload: data,
  nextStep
});

export const saveLocationsOpeningHoursSuccess = (resp, nextStep, id) => ({
  type: SAVE_LOCATIONS_OPENING_HOURS_SUCCESS,
  payload: resp,
  nextStep,
  id
});

export const saveLocationsOpeningHoursError = (id, err) => ({
  type: SAVE_LOCATIONS_OPENING_HOURS_ERROR,
  id,
  payload: err
});
