import { createLogicWithApi } from '../../../../shared/logicCreators';
import { locations as locationsApiConfig } from '../../../config/api';
import {
  fetchEquipmentSuccess,
  fetchEquipmentError,
  FETCH_EQUIPMENT,
  CANCEL_FETCH_EQUIPMENT,
} from '../EquipmentManager/EquipmentManager.actions';

const fetchAvailableEquipmentLogic = createLogicWithApi({
  type: FETCH_EQUIPMENT,
  cancelType: CANCEL_FETCH_EQUIPMENT,
  latest: true,
  process: ({ action, Api, getState }, dispatch, done) => {
    const state = getState(),
          category = action.category,
          query = action.query,
          queryFields = action.queryFields,
          selectedId = action.id,
          location = state.table.currentLocation;

    return Api({
      method: 'GET',
      path: `${locationsApiConfig.path}/${location}/${action.entity}/${selectedId}/variant-search`,
      handle: r => {
        r
          .query({'limit': 0})
          .query({'filters[state]': 'published'});
        if (query) {
          r.query({'query': query});
        }
        if (queryFields) {
          r.query({'queryFields': queryFields});
        }
        if (category) {
          r.query({'filters[category]': category})
        }
        return r;
      }
    })
      .then(resp => {
        dispatch(fetchEquipmentSuccess(resp));
        done();
      })
      .catch(err => {
        dispatch(fetchEquipmentError(err));
        done();
      })
  }
});

const logics = [
  fetchAvailableEquipmentLogic
];

export default logics;