import { combineReducers } from 'redux';

import {
  FETCH_GLOBAL_LOCATIONS,
  FETCH_GLOBAL_LOCATIONS_SUCCESS,
  FETCH_GLOBAL_LOCATIONS_ERROR
} from '../GlobalLocations/GlobalLocations.actions';
import {
  FETCH_LOCATION_SUCCESS,
  SAVE_LOCATION_SUCCESS,
  REMOVE_LOCATION_SUCCESS
} from '../../../../shared/components/Locations/Locations.actions';
import {
  SAVE_SETTINGS_SUCCESS
} from '../../pages/Settings/Settings.actions';

// META

const loading = (state = false, action) => {
  switch(action.type) {
    case FETCH_GLOBAL_LOCATIONS:
      return true;
      
    case FETCH_GLOBAL_LOCATIONS_SUCCESS:
    case FETCH_GLOBAL_LOCATIONS_ERROR:
      return false;

    default:
      return state;
  }
};

const list = (state = {}, action) => {
  switch(action.type) {
    case FETCH_GLOBAL_LOCATIONS_SUCCESS:
      return action.payload.meta;

    case FETCH_GLOBAL_LOCATIONS_ERROR:
      return {};

    default:
      return state;
  }
};

const meta = combineReducers({
  loading,
  list
});

// ITEMS

const location = (state = {}, action) => {
  switch(action.type) {
    case SAVE_LOCATION_SUCCESS:
      return {
        id: action.payload.data.id,
        type: action.payload.data.type,
        loading: false,
        ...action.payload.data.attributes,
      };
      
    case SAVE_SETTINGS_SUCCESS:
      if (String(action.payload.data.id) === String(state.id)) {
        return Object.assign({},
          state,
          action.payload.data.attributes
        );
      }

      return state;

    case FETCH_GLOBAL_LOCATIONS_SUCCESS:
      // lat / lng is added so supercluster can generate clusters from the items array
      return Object.assign(
        {
          id: state.id,
          type: state.type,
          lat: state.attributes.geoPoint ? state.attributes.geoPoint[0] : null,
          lng: state.attributes.geoPoint ? state.attributes.geoPoint[1] : null,
        },
        state.attributes
      );

    default:
      return state;
  }
};

const items = (state = [], action) => {
  switch(action.type) {
    case FETCH_GLOBAL_LOCATIONS_SUCCESS:
      return action.payload.data.map(
        l => location(l, action)
      );

    case FETCH_LOCATION_SUCCESS:
    case SAVE_SETTINGS_SUCCESS:
      // TODO: Add created locations to array
      return state.map(
        l => location(l, action)
      );

    case SAVE_LOCATION_SUCCESS: {
      const {payload: {data: {id, attributes}}} = action;
      const current = state.find(l => String(l.id) === String(id));

      if ( current ) {
        return state.map(
          l => {
            if (String(id) === String(l.id)) {
              return location(l, action);
            }
            return l;
          }
        );

      } else if(attributes.state === 'published') {
        return [
          ...state,
          location({}, action)
        ];
      }

      return state;
    }
    case REMOVE_LOCATION_SUCCESS:
      return state.filter(item => item.id !== parseInt(action.id));

    default:
      return state;
  }
};

// MAIN

const reducer = combineReducers({
  items,
  meta
});

reducer.reducer = 'globalLocations';

export default reducer;
