export const defaultStatusFilter = 'reserved';

export const table = {
  name: 'reservations',
  columns: [
    'id',
    'userAndOrg',
    'pickupAt',
    'returnAt',
    'status',
    'icon'
  ],
  disabledColumns: [
    'userAndOrg',
    'icon'
  ]
};

export const pickupFilterOptions = [
  {
    label: 'true',
    pickup: '1'
  },
  {
    label: 'false',
    pickup: '0'
  }
];
