import React from 'react';
import moment from 'moment';
import { createSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';

import { getCurrentLocation } from '../../../../shared/components/common/AccessRights/AccessRights.selectors';
import { OPERATOR_ROLE, LOCATION_ADMIN_ROLE, adminRoles, operatorRoles, NON_ADMIN_ROLE } from '../../../config/staff';

export const getStaffTotal = state => 
  state.staff && state.staff.meta && state.staff.meta.list 
    ? state.staff.meta.list.total 
    : 0;
export const getStaff = state =>
  state.staff && state.staff.items
    ? state.staff.items
    : null;
export const getSelected = state =>
  state.staff && state.staff.selected
    ? state.staff.selected
    : null;

export const getType = (roles, currentLocation) => !roles 
? null 
: roles.includes( adminRoles[0].replace("{id}", currentLocation) ) 
? <FormattedMessage id={`staff.form.${LOCATION_ADMIN_ROLE}`} /> 
: roles.includes( operatorRoles[0].replace("{id}", currentLocation) ) 
? <FormattedMessage id={`staff.form.${OPERATOR_ROLE}`} />
: <FormattedMessage id={`staff.form.${NON_ADMIN_ROLE}`} /> 

export const getUpdatedStaff = createSelector(
  [getStaff, getCurrentLocation],
  (items = [], currentLocation) => {
    if (items && items.length) {
      items = items.map(item => {
        var tempItem = {
          ...item,
          id: item.id,
          email: item.attributes.email,
          firstName: item.attributes.firstName,
          lastName: item.attributes.lastName,
          type: getType(item.attributes.roles, currentLocation),
          lastActivity: moment(item.attributes.lastActivity).format('DD.MM.YYYY'),
        };
        return tempItem;
      });
    }
    return items;
  }
);