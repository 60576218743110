import pick from 'lodash/pick';
import { createSelector } from 'reselect';

export const getSelectedOpeningHours = selected =>
  selected ? selected.openingHours : undefined;

export const getSelectedOpeningHoursFormValues = createSelector(
  [getSelectedOpeningHours],
  hours => {
    var values = {};
    if (hours) {
      for (var h in hours) {
        values[`${hours[h].attributes.dayOfWeek}_id`] = hours[h].id;
        values[`${hours[h].attributes.dayOfWeek}_openingTime`] =
          hours[h].attributes.openingTime;
        values[`${hours[h].attributes.dayOfWeek}_closingTime`] =
          hours[h].attributes.closingTime;
        values[`${hours[h].attributes.dayOfWeek}_isActive`] =
          hours[h].attributes.isActive;
      }
    }
    return values;
  }
);

export const parseOpeningHoursCollection = (hours, values) =>
  hours.map(hour => {
    const isActive = values[`${hour.attributes.dayOfWeek}_isActive`],
      openingTime = values[`${hour.attributes.dayOfWeek}_openingTime`],
      closingTime = values[`${hour.attributes.dayOfWeek}_closingTime`];
    return {
      ...pick(hour, ['id', 'type']),
      attributes: {
        isActive: isActive,
        openingTime: openingTime && openingTime !== '' ? openingTime : null,
        closingTime: closingTime && closingTime !== '' ? closingTime : null
      }
    };
  });

export const checkSpecialOpeningHours = (prev, next) => {
  if (prev && prev.length) {
    prev.forEach(prevItem => {
      let shouldBeDeleted = true;

      next.forEach(nextItem => {
        if (String(nextItem.id) === String(prevItem.id)) {
          shouldBeDeleted = false;

          if (prevItem.attributes.date !== nextItem.attributes.date) {
            nextItem.shouldBeUpdated = true;

          } else if (
            (prevItem.attributes.closingTime !==
              prevItem.attributes.opeiningTime) !==
            '00-00'
          ) {
            if (
              prevItem.attributes.closingTime !==
                nextItem.attributes.closingTime ||
              prevItem.attributes.openingTime !==
                nextItem.attributes.openingTime
            ) {
              nextItem.shouldBeUpdated = true;
            }
          }
        }
      });

      if (shouldBeDeleted) {
        next.push({
          ...prevItem,
          shouldBeDeleted: true
        });
      }
    });
  }

  return next;
};

export const compareOpeningHoursBy = (prev, next) => {
  return (
    String(prev.id) === String(next.id) 
    && prev.attributes.isActive === next.attributes.isActive 
    && prev.attributes.openingTime === next.attributes.openingTime 
    && prev.attributes.closingTime === next.attributes.closingTime
  );
};

const createSpecialOpeningHours = item => ({
  attributes: {
    ...item.attributes,
    isActive: true,
    dayOfWeek: 0,
    state: 'published'
  }
});

const createClosedDays = item => ({
  attributes: {
    ...item.attributes,
    isActive: true,
    dayOfWeek: 0,
    state: 'published',
    openingTime: '00:00',
    closingTime: '00:00'
  }
});

export const selectDeletedLocationsOpeningHours = values =>
  values.filter(item => item.shouldBeDeleted).map(({ id }) => id);

export const selectUpdatedLocationsOpeningHours = values => values
  .filter(item => item.shouldBeUpdated)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  .map(({ shouldBeUpdated, ...item }) => item);

export const selectCreatedLocationsOpeningHours = (
  specialOpeningHours,
  closedDays
) => {
  const newSpecialHours = specialOpeningHours
    .filter(({ id }) => !id)
    .map(item => createSpecialOpeningHours(item));
  const newClosedDays = closedDays
    .filter(({ id }) => !id)
    .map(item => createClosedDays(item));

  return [...newSpecialHours, ...newClosedDays];
};
