export const fontFace = (name, src) => `
  @font-face{
    font-family: "${name}";
    src: url("${src}.eot");
    src: url("${src}.eot?#iefix") format("embedded-opentype"),
         url("${src}.woff2") format("woff2"),
         url("${src}.woff") format("woff"),
         url("${src}.ttf") format("truetype");
  }
`;

export const fonts = fonts => Object.keys(fonts)
  .map(f => fontFace(fonts[f], '/fonts/' + fonts[f]))
  .join('');
