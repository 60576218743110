import { combineReducers } from 'redux';

import {
  SET_SCANNER_ACTION,
  UNSET_SCANNER_ACTION,
  EQUIPMENT_SCANNED,
  EQUIPMENT_SCAN_STARTED,
  EQUIPMENT_SCAN_COMPLETED,
  SCANNER_SPINNER_VISIBLE,
} from './PubSub.actions';

const scannerAction = (state = null, action) => {
  switch(action.type) {
    case SET_SCANNER_ACTION:
      return action.label;
    case UNSET_SCANNER_ACTION:
      return state === action.label ? null : state;
    default:
      return state;
  }
};

const scannedMessage = (state = null, action) => {
  switch(action.type) {
    case EQUIPMENT_SCANNED:
      return action.message;
    case SET_SCANNER_ACTION:
    case UNSET_SCANNER_ACTION:
      return null;
    default:
      return state;
  }
}

const scannerLoading = (state = false, action) => {
  switch(action.type) {
    case EQUIPMENT_SCAN_STARTED:
      return true;
    case EQUIPMENT_SCAN_COMPLETED:
      return false;
    default:
      return state;
  }
};

const scannerSpinnerVisible = (state = false, action) => {
  switch(action.type) {
    case SCANNER_SPINNER_VISIBLE:
      return action.visible;
    default:
      return state;
  }
};

// MAIN
const reducer = combineReducers({
  scannerAction,
  scannedMessage,
  scannerLoading,
  scannerSpinnerVisible
});

reducer.reducer = 'pubsub';

export default reducer;
