import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import StyledNotifications from './Notification.styled';
import Notification from './Notification.component';
import { hideNotification, clearNotifications } from '../../../../../shared/components/common/Dialogs/Dialogs.actions';

class NotificationContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      notifications: props.notifications
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.notifications !== nextProps.notifications) {
      this.setState({
        notifications: nextProps.notifications
      });
    }
  }

  render() {
    let { notifications } = this.state,
        { clearAll, clickClose } = this.props;

    return (
      !notifications.length ? null :
        <StyledNotifications>
          <div className="tools">
            {notifications.length < 2 ? null : (
              <a href="#" onClick={clearAll}>
                <FormattedMessage id="actions.remove_all" />
              </a>
            )}
          </div>
          {notifications.map((item, index) =>
            <Notification
              item={item}
              key={index}
              clickClose={clickClose} />)}
        </StyledNotifications>
    )
  }
}

const mapStateToProps = ({ dialogs }) => ({
  notifications: dialogs.notifications.filter(
    notification =>
      typeof notification == 'string' ||
      notification.notificationText ||
      (notification && notification.response && notification.response.body &&
        (notification.response.body.message || notification.response.body.errorData)
      )
  )
});

const mapDispatchToProps = (dispatch) => ({
  clickClose() {
    dispatch(
      hideNotification()
    );
  },
  clearAll(e) {
    e.preventDefault();

    dispatch(
      clearNotifications()
    );
  }
});

NotificationContainer.propTypes = {
  clickClose: PropTypes.func,
  clearAll: PropTypes.func,
  notifications: PropTypes.array
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationContainer);
