export const SEND_VERIFY_MESSAGE          = '@@users/SEND_VERIFY_MESSAGE';
export const CANCEL_SEND_VERIFY_MESSAGE   = '@@users/CANCEL_SEND_VERIFY_MESSAGE';
export const SEND_VERIFY_MESSAGE_SUCCESS  = '@@users/SEND_VERIFY_MESSAGE_SUCCESS';
export const SEND_VERIFY_MESSAGE_ERROR    = '@@users/SEND_VERIFY_MESSAGE_ERROR';

export const SEND_VERIFY_CODE             = '@@users/SEND_VERIFY_CODE';
export const CANCEL_SEND_VERIFY_CODE      = '@@users/CANCEL_SEND_VERIFY_CODE';
export const SEND_VERIFY_CODE_SUCCESS     = '@@users/SEND_VERIFY_CODE_SUCCESS';
export const SEND_VERIFY_CODE_ERROR       = '@@users/SEND_VERIFY_CODE_ERROR';

export const VALIDATE_VERIFY_CODE             = '@@users/VALIDATE_VERIFY_CODE';
export const CANCEL_VALIDATE_VERIFY_CODE      = '@@users/CANCEL_VALIDATE_VERIFY_CODE';
export const VALIDATE_VERIFY_CODE_SUCCESS     = '@@users/VALIDATE_VERIFY_CODE_SUCCESS';
export const VALIDATE_VERIFY_CODE_ERROR       = '@@users/VALIDATE_VERIFY_CODE_ERROR';

export const RESET_VERIFICATION_USER      = '@@users/RESET_VERIFICATION_USER';

// SEND VERIFICATION MESSAGE

export const sendVerificationMessage = payload => ({
  type: SEND_VERIFY_MESSAGE,
  payload
});

export const sendVerificationMessageSuccess = payload => ({
  type: SEND_VERIFY_MESSAGE_SUCCESS,
  payload
});

export const sendVerificationMessageError = err => ({
  type: SEND_VERIFY_MESSAGE_ERROR,
  payload: err
});

// SEND VERIFY CODE

export const sendVerifyCode = payload => ({
  type: SEND_VERIFY_CODE,
  payload
});

export const sendVerifyCodeSuccess = payload => ({
  type: SEND_VERIFY_CODE_SUCCESS,
  payload
});

export const sendVerifyCodeError = err => ({
  type: SEND_VERIFY_CODE_SUCCESS,
  payload: err
});

// VALIDATE VERIFY CODE

export const validateVerifyCode = payload => ({
  type: VALIDATE_VERIFY_CODE,
  payload
});

export const validateVerifyCodeSuccess = payload => ({
  type: VALIDATE_VERIFY_CODE_SUCCESS,
  payload
});

export const validateVerifyCodeError = err => ({
  type: VALIDATE_VERIFY_CODE_ERROR,
  payload: err
});

// RESET VERIFICATION USER

export const resetVerificationUser = () => ({
  type: RESET_VERIFICATION_USER,
});
