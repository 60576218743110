import { combineReducers } from 'redux';

import {
  FETCH_EQUIPMENT,
  FETCH_EQUIPMENT_SUCCESS,
  FETCH_EQUIPMENT_ERROR,
  RESET_EQUIPMENT
} from './EquipmentManager.actions';

// Meta
const loading = (state = false, action) => {
  switch(action.type) {
    case FETCH_EQUIPMENT:
      return true;
    case FETCH_EQUIPMENT_SUCCESS:
    case FETCH_EQUIPMENT_ERROR:
      return false;
    default:
      return state;
  }
};

const meta = combineReducers({
  loading
});

// Items
const items = (state = [], action) => {
  switch(action.type) {
    case FETCH_EQUIPMENT_SUCCESS:
      return action.payload.data.map(
        v => ({
          id: v.id,
          type: v.type,
          ...v.attributes,
          equipment: equipment(v, action),
          attributeValues: attributeValues(v, action),
          instances: instances(v, action),
          availability: availability(v, action)
        })
      );
    case FETCH_EQUIPMENT_ERROR:
      return state;
    case RESET_EQUIPMENT:
      return [];
    default:
      return state;
  }
};

const equipment = (state = {}, action) => {
  switch(action.type) {
    case FETCH_EQUIPMENT_SUCCESS: {
      let current = action.payload.data.find(item => String(item.id) === String(state.id));
      let relationships = current.relationships;

      if (!relationships.equipment) {
        return state.attributes.equipment;
      }

      return {
        id: relationships.equipment.data.id,
        type: relationships.equipment.data.type,
        ...relationships.equipment.data.attributes
      }
    }
    case FETCH_EQUIPMENT_ERROR:
      return state;
    default:
      return state;
  }
};

const attributeValues = (state = [], action) => {
  switch(action.type) {
    case FETCH_EQUIPMENT_SUCCESS: {
      let current = action.payload.data.find(item => String(item.id) === String(state.id));
      let relationships = current.relationships;

      if (!relationships.attributeValues) {
        return [];
      }

      return relationships.attributeValues.data.map(
        v => ({
          id: v.id,
          type: v.type,
          ...v.attributes
        })
      );
    }
    case FETCH_EQUIPMENT_ERROR:
      return state;
    default:
      return state;
  }
};

const instances = (state = [], action) => {
  switch(action.type) {
    case FETCH_EQUIPMENT_SUCCESS: {
      let current = action.payload.data.find(item => String(item.id) === String(state.id));
      let relationships = current.relationships;

      if (!relationships.instances) {
        return [];
      }

      return relationships.instances.data.map(
        attr => ({
          id: attr.id,
          type: attr.type,
          ...attr.attributes
        })
      );
    }
    case FETCH_EQUIPMENT_ERROR:
      return state;
    default:
      return state;
  }
};

const availability = (state = {}, action) => {
  switch(action.type) {
    case FETCH_EQUIPMENT_SUCCESS: {
      let current = action.payload.data.find(item => String(item.id) === String(state.id));
      let relationships = current.relationships;

      if (!relationships.availability) {
        return state.attributes.availability;
      }

      return {
        ...relationships.availability
      };
    }
    case FETCH_EQUIPMENT_ERROR:
      return {};
    default:
      return state;
  }
};

const reducer = combineReducers({
  meta,
  items
});

reducer.reducer = 'equipmentManager';

export default reducer;
