import styled from 'styled-components';

const qrHeight = 800,
      textWidth = 130,
      textMargin = 12;

const StyledQR = styled.div`
  width: ${qrHeight+textWidth+textMargin}px;
  margin: 5px 0 0 0;
  float: left;

  img {
    float: left;
    max-width: ${qrHeight}px;
    max-height: ${qrHeight}px;
  }

  h2 {
    float: left;
    line-height: ${qrHeight}px;
    margin-left: ${textMargin}px;
    font-size: 18px;
    vertical-align: middle;
    letter-spacing: 3px;
    width: ${textWidth}px;
    font-family: ${props => props.theme.fonts.medium};
  }
`;

export default StyledQR;
