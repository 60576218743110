export const getRoles = (state) => state.login?.data?.roles;

export const getCurrentLocation = (state) => state.table?.currentLocation || null;

export const getRolesOfLocation = state => {
  const roles = getRoles(state);
  const currentLocation = getCurrentLocation(state);

  if (!currentLocation || !roles) {
    return null;
  }

  return roles[currentLocation];
};

export const getAdminAccess = (state) => ({
  craft: !!state.login?.data?.craft_access,
  grafana: !!state.login?.data?.grafana_access,
  toolboxPublic: !!state.login?.data?.toolbox_public_access,
  toolboxAdmin: !!state.login?.data?.toolbox_admin_access,
});
