import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import { getFormValues } from 'redux-form';
import moment from 'moment';

import { checkClosedDate } from '../Locations/Locations.selectors.js';
import {
  getCategoryBreadcrumbs,
  createBreadcrumbString,
  getSelectedCategory
} from '../Categories/Categories.selectors';

export const getEquipment = state => state.equipment?.items || null;

export const getAttributeValues = selected => selected?.attributeValues || [];

export const getSelected = state => state.equipment?.selected || null;

export const getVariantSelected = state => state.equipment?.variants?.selected || null;

export const getSelectedInstance = state => state.equipment?.instances?.selected || null;

export const getEquipmentVariants = state => state.equipment?.variants?.items || null;

export const getListTotal = state => state.equipment?.meta?.list?.total || 0;

export const getEquipmentVariantsListTotal = state => state.equipment?.variants?.meta?.list?.total || 0;

export const getPreparedCustomAttributes = customAttributes => {
  return customAttributes.map(attr => ({
    label: attr.attributes.label,
    required: attr.attributes.required,
    items: attr.attributes.values.length
      ? attr.attributes.values.map(item => ({
          id: item.id,
          name: item.attributes.value
        }))
      : []
  }));
};

export const getSelectedWithImageFallback = createSelector(
  [getSelected, state => state],
  (item, state) =>
    !item
      ? {}
      : {
          ...item,
          images: getEquipmentImagesWithFallback(state, item)
        }
);

export const getEquipmentWithImageFallback = createSelector(
  [getEquipment, state => state],
  (items = [], state) =>
    items.map(item => ({
      ...item,
      images: getEquipmentImagesWithFallback(state, item)
    }))
);

export const getEquipmentImagesWithFallback = createCachedSelector(
  [state => state, (state, equipment) => equipment],
  (state, equipment) => {
    let images = equipment?.images 
      ? equipment.images 
      : [];

    if (!images.length && equipment.category) {
      const categories = getCategoryBreadcrumbs(
        state,
        equipment.category.id,
        true
      );

      if (categories) {
        categories
          .slice()
          .reverse()
          .forEach(category => {
            if (!images.length && category.images.length) {
              images.push(category.images[0]);
            }
          });
      }
    }
    return images;
  }
)((state, equipment) => `equipment-images-${equipment.id}`);

export const getSelectedWithBreadcrumbs = createSelector(
  [getSelected, state => state],
  (selected = {}, state) =>
    !selected || !selected.category || !selected.category.id
      ? selected
      : {
          ...selected,
          category: {
            ...selected.category,
            breadcrumbs: createBreadcrumbString(
              selected.category,
              getCategoryBreadcrumbs(state, selected.category.id)
            )
          }
        }
);

export const getEquipmentWithBreadcrumbs = createSelector(
  [getEquipmentWithImageFallback, state => state],
  (items = [], state) =>
    !items || !items.length
      ? items
      : items.map(item =>
          !item.category || !item.category.id
            ? item
            : {
                ...item,
                category: {
                  ...item.category,
                  breadcrumbs: createBreadcrumbString(
                    item.category,
                    getCategoryBreadcrumbs(state, item.category.id)
                  )
                }
              }
        )
);

export const getEquipmentWithAvailability = createSelector(
  [
    getEquipmentWithImageFallback,
    state => state,
    (state, startDate) => startDate,
    (state, startDate, endDate) => endDate
  ],
  (items = [], state, startDate, endDate) =>
    !items || !items.length
      ? items
      : items.map(item => {
          if (!startDate || !endDate) {
            return item;
          }
          const availability = getEquipmentAvailability(
            item,
            startDate,
            endDate
          );
          return {
            ...item,
            availability,
            nextAvailable: !availability
              ? getEquipmentNextAvailable(
                  item,
                  startDate,
                  state.locations.selected
                )
              : null
          };
        })
);

export const getEquipmentExtended = createSelector(
  [getEquipmentWithBreadcrumbs, getSelected],
  (items = [], selected = {}) => {
    if (selected && items && items.length) {
      var expandedIndex = items.findIndex(item => String(item.id) === String(selected.id));

      if (~expandedIndex) {
        items = [
          ...items.slice(0, expandedIndex + 1),
          {
            expanded: true,
            id: 'expanded'
          },
          ...items.slice(expandedIndex + 1)
        ];
      }
    }

    return items;
  }
);

export const getAttributeData = createSelector(
  [getAttributeValues],
  (attributeValues = []) => {
    let attributes = {};

    attributeValues.forEach(item => {
      attributes[item.attributes.attributeLabel] = item.id;
    });

    return attributes;
  }
);

export const getPreparedEquipmentVariants = createSelector(
  [getEquipmentVariants],
  (items = []) => {
    return items.map(item => {
      return {
        ...item,
        attribute: {
          ...item.attributeValues.reduce(
            (prev, curr) =>
              Object.assign({}, prev, curr['variant_attribute_value']),
            {}
          )
        },
        attributeValues: item.attributeValues.reduce(
          (prev, curr) => `${prev}, ${curr.attributes.value}`,
          ''
        ),
        total: item.instances.length
      };
    });
  }
);

export const getValidVariantFormValues = createSelector(
  [
    getEquipmentVariants,
    (state, formName) => getFormValues(formName)(state),
    (state, formName, attrPrefix) => attrPrefix
  ],
  (variants, currentSelections, attrPrefix) =>
    !variants || !variants.length
      ? []
      : !currentSelections
        ? variants
        : // Filtering on variants that have the same attributeValues as currentSelections
          variants.filter(variant => {
            let valid = true;

            variant.attributeValues.forEach(value => {
              if (
                currentSelections[`${attrPrefix}${value.attributes.attribute}`] 
                && String(currentSelections[`${attrPrefix}${value.attributes.attribute}`]) !== String(value.id)
              ) {
                valid = false;
              }
            });

            return valid;
          })
);

export const getValidVariantAttributeFormValues = createSelector(
  [
    getEquipmentVariants,
    getValidVariantFormValues,
    getSelectedCategory,
    (state, formName) => getFormValues(formName)(state),
    (state, formName, attrPrefix) => attrPrefix
  ],
  (variants, validVariants, category, currentSelections, attrPrefix) =>
    !variants || !variants.length
      ? []
      : // Filter out available variantAttributes and attributeValues based on variants
        category.variantAttributes
          .map(categoryAttr => {
            const values = categoryAttr.attributes.values.filter(
              categoryAttrValue =>
                variants.filter(
                  variant =>
                    variant.attributeValues.filter(
                      variantValue => String(categoryAttrValue.id) === String(variantValue.id)
                    ).length
                ).length
            );
            return !values || !values.length
              ? null
              : {
                  ...categoryAttr,
                  attributes: {
                    ...categoryAttr.attributes,
                    values
                  }
                };
          })
          // Filter out null from array
          .filter(categoryAttr => categoryAttr)
          // Creating form inputs based on available variantAttributes and attributeValues in validVariants or selected attributes
          .map(categoryAttr => {
            const values = categoryAttr.attributes.values.filter(
              categoryAttrValue => (
                (
                  currentSelections 
                  && currentSelections[`${attrPrefix}${categoryAttr.id}`]
                ) || (
                  validVariants.filter(
                    variant => variant.attributeValues.filter(
                      variantValue => String(categoryAttrValue.id) === String(variantValue.id)
                    ).length
                  )
                ).length
              )
            );

            return {
              ...categoryAttr,
              attributes: {
                ...categoryAttr.attributes,
                values
              }
            };
          })
);

const generateAvailablilityCacheString = (prefix = '') => (
  item,
  startDate,
  endDate
) => `${prefix}_${item.id}_${startDate}${endDate ? `_${endDate}` : ''}`;

const getAvailability = (startDateString, endDateString, availability) => {
  const now = moment().startOf('day');
  const beginningOfYear = now.startOf('year');
  const startDate = !startDateString
    ? moment()
    : moment(startDateString, 'DD-MM-YYYY');
  const endDate = !endDateString ? moment() : moment(endDateString, 'DD-MM-YYYY');
  const daysFromBeginningOfYear = startDate.diff(beginningOfYear, 'days');
  const daysInDateRange = endDate.diff(startDate, 'days');

  let quantity = 0;

  if (
    availability &&
    availability[daysFromBeginningOfYear] &&
    availability[daysFromBeginningOfYear + daysInDateRange]
  ) {
    quantity = availability[daysFromBeginningOfYear];

    if (daysInDateRange) {
      for (
        var d = daysFromBeginningOfYear + 1;
        d <= daysFromBeginningOfYear + daysInDateRange;
        d++
      ) {
        quantity = availability[d] < quantity ? availability[d] : quantity;
      }
    }
  }

  return quantity;
};

const getNextAvailable = (startDateString, availability, location) => {
  const now = moment().startOf('day'),
    startDate = !startDateString
      ? moment()
      : moment(startDateString, 'DD-MM-YYYY');
  let daysFromBeginningOfYear = startDate.diff(now.startOf('year'), 'days'),
    quantity = 0;
  if (availability) {
    while (
      !quantity &&
      typeof availability[daysFromBeginningOfYear + 1] !== 'undefined'
    ) {
      const currQuantity = availability[++daysFromBeginningOfYear];
      quantity =
        currQuantity &&
        (!location ||
          !checkClosedDate(
            location,
            moment().dayOfYear(daysFromBeginningOfYear)
          ))
          ? currQuantity
          : 0;
    }
  }
  return quantity
    ? moment()
        .dayOfYear(daysFromBeginningOfYear)
        .format('Do MMM')
    : null;
};

export const getVariantAdminAvailability = createCachedSelector(
  [
    variant => variant,
    (variant, startDate, endDate) => ({ startDate, endDate })
  ],
  (variant, { startDate, endDate }) =>
    getAvailability(startDate, endDate, variant.adminAvailability)
)(generateAvailablilityCacheString('variant-admin-availability'));

export const getVariantAvailability = createCachedSelector(
  [
    variant => variant,
    (variant, startDate, endDate) => ({ startDate, endDate })
  ],
  (variant, { startDate, endDate }) =>
    getAvailability(startDate, endDate, variant.availability)
)(generateAvailablilityCacheString('variant-availability'));

export const getVariantNextAvailable = createCachedSelector(
  [
    variant => variant,
    (variant, startDate) => startDate,
    (variant, startDate, location) => location
  ],
  (variant, startDate, location) =>
    getNextAvailable(startDate, variant.availability, location)
)(generateAvailablilityCacheString('variant-next-available'));

export const getEquipmentAvailability = createCachedSelector(
  [
    equipment => equipment,
    (variant, startDate, endDate) => ({ startDate, endDate })
  ],
  (equipment, { startDate, endDate }) =>
    getAvailability(startDate, endDate, equipment.reservationAvailability)
)(generateAvailablilityCacheString('equipment'));

export const getEquipmentNextAvailable = createCachedSelector(
  [
    equipment => equipment,
    (equipment, startDate) => startDate,
    (variant, startDate, location) => location
  ],
  (equipment, startDate, location) =>
    getNextAvailable(startDate, equipment.reservationAvailability, location)
)(generateAvailablilityCacheString('equipment-next-available'));
