export const parseInitialValues = entrance => entrance && entrance.attributes.type === 'lock' && entrance.attributes.config && entrance.attributes.config.provider === 'unloc' ? {
  entranceUnlocInfo: entrance.attributes.content,
  entranceUnlocVendor: entrance.attributes.config.vendor,
  entranceUnlocUsername: entrance.attributes.config.username,
  entranceUnlocPassword: entrance.attributes.config.password,
  entranceUnlocLockName: entrance.attributes.config.lockName,
  entranceUnlocDeviceIdentifier: entrance.attributes.config.deviceIdentifier,
  entranceUnlocActivationCode: entrance.attributes.config.activationCode,
  entranceAdminsExisting: !!entrance.relationships && !!entrance.relationships.admins && entrance.relationships.admins.data.map(a => a.attributes),
} : {};

export const formValues = [
  'entranceUnlocInfo',
  'entranceUnlocVendor',
  'entranceUnlocUsername',
  'entranceUnlocPassword',
  'entranceAdminsExisting',
  'entranceAdminsNew',
  'entranceUnlocLockName',
  'entranceUnlocDeviceIdentifier',
  'entranceUnlocActivationCode',
];
