export const defaultStatusFilter = 'picked_up,packed,returned,overdue,extended,stolen,damaged';

export const table = {
  name: 'loans',
  columns: [
    'id',
    'userAndOrg',
    'pickupAt',
    'returnAt',
    'status',
    'icon'
  ],
  disabledColumns: [
    'userAndOrg',
    'icon'
  ]
};

export const tableLoanItems = {
  name: 'loan_items',
  columns: [
    'instance.identifier',
    'name',
    'variantValues',
    'returnAt',
    'status'
  ],
  disabledColumns: [
    'instance',
    'name',
    'variantValues',
    'returnAt',
    'status'
  ]
};

export const tableLoanItemsStep2 = {
  name: 'loan_items',
  columns: [
    'identifier',
    'name',
    'variantValues',
    'icon',
  ],
  disabledColumns: [
    'identifier',
    'name',
    'variantValues',
    'icon',
  ]
};
