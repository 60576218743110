import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { cancelConfirm, closeConfirm } from '../components/common/Dialogs/Dialogs.actions';

const mapStateToProps = ({ dialogs }) => ({
  action: dialogs && dialogs.confirm ? dialogs.confirm : null
});

const mapDispatchToProps = dispatch => ({
  clickConfirm(action) {
    if (action.acceptAction) {
      if (Array.isArray(action.acceptAction)) {
        action.acceptAction.forEach(a => {
          dispatch(a);
        });
      } else {
        dispatch(action.acceptAction);
      }
    }
    dispatch(closeConfirm());
  },
  clickCancel() {
    dispatch(cancelConfirm());
  }
});

const withConfirmDialog = Component => {
  class withConfirmDialog extends React.Component {
    static propTypes = {
      action: PropTypes.shape({
        acceptAction: PropTypes.oneOfType([
          PropTypes.arrayOf(
            PropTypes.shape({
              type: PropTypes.string
            })
          ),
          PropTypes.shape({
            type: PropTypes.string
          })
        ]),
        content: PropTypes.any
      }),
      clickConfirm: PropTypes.func.isRequired,
      clickCancel: PropTypes.func.isRequired
    };

    render() {
      const { action } = this.props;

      return !action ? null : <Component {...this.props} />;
    }
  }
  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(withConfirmDialog);
};

export default withConfirmDialog;
