import styled from 'styled-components';
import { transparentize } from 'polished';

const StyledScannerSpinner = styled.div`
  pointer-events: none;
  display: inline-block;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  margin-top: -12px;

  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    
    100% {
      opacity: 0;
    }
  }

  &::before {
    content: " ";
    background-color: ${props => transparentize(.5, props.theme.colorGrayDark)};
    position: absolute;
    margin-left: -8px;
    margin-top: -8px;
    width: 42px;
    height: 42px;
    border-radius: 2px;
  }

  div {
    transform-origin: 12px 12px;
    animation: lds-spinner 0.6s linear infinite;
  }

  div::after {
    content: " ";
    display: block;
    position: absolute;
    top: 1px;
    left: 12px;
    width: 2px;
    height: 6px;
    border-radius: 20%;
    background: ${props => props.theme.colorWhite};
  }

  div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -0.55s;
  }

  div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -0.5s;
  }

  div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.45s;
  }

  div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.4s;
  }

  div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.35s;
  }

  div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.3s;
  }

  div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.25s;
  }

  div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.2s;
  }

  div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.15s;
  }

  div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.1s;
  }

  div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.05s;
  }

  div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
`;

export default StyledScannerSpinner;
