import React from 'react';

import StyledNoAccessPage from './NoAccessPage.styled';

const NoAccessPage = () => (
  <StyledNoAccessPage>
    <h2>No Access Rights</h2>
  </StyledNoAccessPage>
);

export default NoAccessPage;
