import { createGlobalStyle } from 'styled-components';

const TextGlobalStyles = createGlobalStyle`
  * {
    -webkit-font-smoothing: antialiased;
    /* stylelint-disable-next-line */
    font-smoothing: antialiased;
    text-rendering: optimizelegibility;
  }
`;

export default TextGlobalStyles;
