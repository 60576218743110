import { parseInitialValues as parseInitialInfoValues, formValues as formValuesInfo } from './EntranceInfo/EntranceInfo.selectors';
import { parseInitialValues as parseInitialUnlocValues, formValues as formValuesUnloc } from './EntranceUnloc/EntranceUnloc.selectors';

export const getPrioritizedEntrance = location => !!location.entrances && !!location.entrances.length && (location.entrances.find(({attributes:{ type }}) => type === 'lock') || location.entrances.find(({attributes:{ type }}) => type === 'info') || location.entrances[0]);

export const getCurrentEntrance = (location, entranceType) => !location.entrances || !location.entrances.length ? null : entranceType === 'info' ? location.entrances.find(({attributes:{ type }}) => type === 'info') : location.entrances.find(({attributes:{ type }}) => type === 'lock');

export const getEntranceType = entrance => entrance && entrance.attributes.type === 'lock' && entrance.attributes.config ? entrance.attributes.config.provider : entrance ? entrance.attributes.type : null;

export const parseInitialValues = entrance => ({
  ...parseInitialInfoValues(entrance),
  ...parseInitialUnlocValues(entrance),
});

export const formFields = [
  ...formValuesInfo,
  ...formValuesUnloc,
];
