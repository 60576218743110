export const FETCH_LOAN_ITEMS = '@@loans/FETCH_LOAN_ITEMS';
export const FETCH_LOAN_ITEMS_SUCCESS = '@@loans/FETCH_LOAN_ITEMS_SUCCESS';
export const FETCH_LOAN_ITEMS_ERROR = '@@loans/FETCH_LOAN_ITEMS_ERROR';
export const CANCEL_FETCH_LOAN_ITEMS = '@@loans/CANCEL_FETCH_LOAN_ITEMS';

export const UPDATE_LOAN_ITEMS = '@@loans/UPDATE_LOAN_ITEMS';
export const UPDATE_LOAN_ITEMS_SUCCESS = '@@loans/UPDATE_LOAN_ITEMS_SUCCESS';
export const UPDATE_LOAN_ITEMS_ERROR = '@@loans/UPDATE_LOAN_ITEMS_ERROR';
export const CANCEL_UPDATE_LOAN_ITEMS = '@@loans/CANCEL_UPDATE_LOAN_ITEMS';

export const PATCH_LOAN_ITEMS = '@@loans/PATCH_LOAN_ITEMS';
export const PATCH_LOAN_ITEMS_SUCCESS = '@@loans/PATCH_LOAN_ITEMS_SUCCESS';
export const PATCH_LOAN_ITEMS_ERROR = '@@loans/PATCH_LOAN_ITEMS_ERROR';
export const CANCEL_PATCH_LOAN_ITEMS = '@@loans/CANCEL_PATCH_LOAN_ITEMS';

export const RESET_LOAN_ITEMS = '@@loans/RESET_LOAN_ITEMS';

export const ADD_LOAN_ITEM_BY_IDENTIFIER = '@@loans/ADD_LOAN_ITEM_BY_IDENTIFIER';
export const CANCEL_ADD_LOAN_ITEM_BY_IDENTIFIER = '@@loans/CANCEL_ADD_LOAN_ITEM_BY_IDENTIFIER';
export const ADD_LOAN_ITEM_BY_IDENTIFIER_SUCCESS = '@@loans/ADD_LOAN_ITEM_BY_IDENTIFIER_SUCCESS';
export const ADD_LOAN_ITEM_BY_IDENTIFIER_ERROR = '@@loans/ADD_LOAN_ITEM_BY_IDENTIFIER_ERROR';

export const updateLoanItems = (id, items, nextStep) => ({
  type: UPDATE_LOAN_ITEMS,
  payload: {
    id,
    items,
    nextStep
  }
});

export const updateLoanItemsSuccess = (id, payload, nextStep) => ({
  type: UPDATE_LOAN_ITEMS_SUCCESS,
  id,
  payload,
  nextStep
});

export const updateLoanItemsError = err => ({
  type: UPDATE_LOAN_ITEMS_ERROR,
  payload: err
});

export const patchLoanItems = (id, items, publishLoan, loanShouldBeClosed) => ({
  type: PATCH_LOAN_ITEMS,
  payload: {
    id,
    items,
    publishLoan,
    loanShouldBeClosed
  }
});

export const patchLoanItemsSuccess = (id, payload, loanShouldBeClosed) => ({
  type: PATCH_LOAN_ITEMS_SUCCESS,
  id,
  payload,
  loanShouldBeClosed
});

export const patchLoanItemsError = err => ({
  type: PATCH_LOAN_ITEMS_ERROR,
  payload: err
});

export const fetchLoanItems = config => ({
  type: FETCH_LOAN_ITEMS,
  ...config
});

export const fetchLoanItemsSuccess = resp => ({
  type: FETCH_LOAN_ITEMS_SUCCESS,
  payload: resp
});

export const fetchLoanItemsError = err => ({
  type: FETCH_LOAN_ITEMS_ERROR,
  payload: err
});

export const resetLoanItems = () => ({
  type: RESET_LOAN_ITEMS
});

export const addLoanItemByIdentifier = identifier => ({
  type: ADD_LOAN_ITEM_BY_IDENTIFIER,
  identifier
});

export const addLoanItemByIdentifierSuccess = ({ variant, instance }) => ({
  type: ADD_LOAN_ITEM_BY_IDENTIFIER_SUCCESS,
  variant,
  instance
});

export const addLoanItemByIdentifierError = () => ({
  type: ADD_LOAN_ITEM_BY_IDENTIFIER_ERROR
});
