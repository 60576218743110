import React from 'react';
import styled, { css } from 'styled-components';

const Styles = css`
  box-sizing: content-box;
  border: 1px solid ${props => props.theme.colorGray};
  border-radius: 50%;
  padding: 3px;
  width: 7px;
  height: 7px;

  path,
  rect {
    fill: ${props => props.theme.colorGray};
  }
`;

const surroundedIcon = WrappedIcon => {
  const StyledSurroundedIcon = styled(WrappedIcon)`
    ${Styles};
  `;

  return class surroundedIcon extends React.Component {
    render() {
      return <StyledSurroundedIcon {...this.props} />;
    }
  };
};

export default surroundedIcon;
