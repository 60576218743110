import { combineReducers } from 'redux';

import { images } from '../common/Images/Images.reducers';
import {
  specialOpeningHours,
  closedDays,
  openingHours
} from '../Locations/LocationsOpeningHours/LocationsOpeningHours.reducers';
import {
  saveImageSuccess,
  deleteImageSuccess,
  patchImagesSuccess
} from '../common/Images/Images.actions';
import {
  RESET_LOCATION,
  FETCH_LOCATIONS,
  FETCH_LOCATIONS_SUCCESS,
  FETCH_LOCATIONS_ERROR,
  FETCH_LOCATION,
  FETCH_LOCATION_SUCCESS,
  FETCH_LOCATION_ERROR,
  SAVE_LOCATION_SUCCESS,
  SAVE_LOCATION_IMAGE_SUCCESS,
  DELETE_LOCATION_IMAGE_SUCCESS,
  PATCH_LOCATION_IMAGES_SUCCESS,
  REMOVE_LOCATION_SUCCESS,
  SAVE_LOCATION,
  SAVE_LOCATION_ERROR,
  REMOVE_GEOPOINT_SUCCESS
} from '../Locations/Locations.actions';
import {
  SAVE_SETTINGS_SUCCESS,
  FETCH_SETTINGS_OPENING_HOURS_SUCCESS
} from '../../config/locations';
import {
  SAVE_LOCATIONS_OPENING_HOURS,
  FETCH_LOCATIONS_OPENING_HOURS_SUCCESS,
  SAVE_LOCATIONS_OPENING_HOURS_ERROR
} from '../Locations/LocationsOpeningHours/LocationsOpeningHours.actions';
import {
  SAVE_LOCATIONS_ENTRANCE,
  SAVE_LOCATIONS_ENTRANCE_SUCCESS,
  SAVE_LOCATIONS_ENTRANCE_ERROR,
  FETCH_LOCATIONS_ENTRANCES,
  FETCH_LOCATIONS_ENTRANCES_SUCCESS,
  DELETE_LOCATIONS_ENTRANCE_SUCCESS
} from '../Locations/LocationsEntrance/LocationsEntrance.actions';

// META

const loading = (state = false, action) => {
  switch (action.type) {
    case FETCH_LOCATIONS:
      return true;
    case FETCH_LOCATIONS_SUCCESS:
    case FETCH_LOCATIONS_ERROR:
      return false;
    default:
      return state;
  }
};

const list = (state = {}, action) => {
  switch (action.type) {
    case FETCH_LOCATIONS_SUCCESS:
      return action.payload.meta;
    case FETCH_LOCATIONS_ERROR:
      return {};
    case SAVE_LOCATION_SUCCESS:
      return {
        ...state,
        total: !action.id ? state.total + 1 : state.total
      };
    case REMOVE_LOCATION_SUCCESS:
      return {
        ...state,
        total: state.total - 1
      };
    default:
      return state;
  }
};

const meta = combineReducers({
  loading,
  list
});

// ITEMS

const location = (state = {}, action) => {
  switch (action.type) {
    case SAVE_LOCATION_SUCCESS:
      return {
        id: action.payload.data.id,
        type: action.payload.data.type,
        loading: false,
        ...action.payload.data.attributes
      };
    case SAVE_SETTINGS_SUCCESS:
      if (String(action.payload.data.id) === String(state.id)) {
        return Object.assign({}, state, action.payload.data.attributes);
      }
      return state;
    case FETCH_LOCATIONS_SUCCESS:
      // lat / lng is added so supercluster can generate clusters from the items array
      return Object.assign(
        {
          id: state.id,
          type: state.type,
          lat: state.attributes.geoPoint ? state.attributes.geoPoint[0] : null,
          lng: state.attributes.geoPoint ? state.attributes.geoPoint[1] : null
        },
        state.attributes
      );
    default:
      return state;
  }
};

const items = (state = [], action) => {
  switch (action.type) {
    case FETCH_LOCATIONS_SUCCESS:
      return action.payload.data.map(l => location(l, action));
    case FETCH_LOCATION_SUCCESS:
    case SAVE_SETTINGS_SUCCESS:
      // TODO: Add created locations to array
      return state.map(l => location(l, action));
    case SAVE_LOCATION_SUCCESS: {
      const current = state.find(l => String(l.id) === String(action.payload.data.id));

      if (current) {
        return state.map(l => {
          if (String(action.payload.data.id) === String(l.id)) {
            return location(l, action);
          }
          return l;
        });
      } else {
        return [...state, location({}, action)];
      }
    }
    case REMOVE_LOCATION_SUCCESS:
      return state.filter(item => item.id !== parseInt(action.id));
    default:
      return state;
  }
};

// SELECTED

const selected = (state = {}, action) => {
  switch (action.type) {
    case SAVE_LOCATION:
    case SAVE_LOCATIONS_OPENING_HOURS:
    case SAVE_LOCATIONS_ENTRANCE:
    case FETCH_LOCATIONS_ENTRANCES:
      return {
        ...state,
        loading: true
      };
    case REMOVE_LOCATION_SUCCESS:
    case RESET_LOCATION:
      return {};
    case FETCH_LOCATION: {
      if (action.payload.isForMarker) {
        return state;
      } else {
        return {
          ...action.payload,
          loading: true
        };
      }
    }
    case FETCH_LOCATION_SUCCESS:
      return Object.assign({}, action.payload.data.attributes, {
        id: action.payload.data.id,
        loading: false,
        images:
          action.payload.data.relationships &&
          action.payload.data.relationships.images
            ? action.payload.data.relationships.images.data
            : [],
        openingHours: openingHours(state.openingHours, action),
        specialOpeningHours: specialOpeningHours(
          state.specialOpeningHours,
          action
        ),
        closedDays: closedDays(state.closedDays, action)
      });
    case SAVE_LOCATION_SUCCESS:
    case SAVE_SETTINGS_SUCCESS:
      if (String(action.payload.data.id) === String(state.id)) {
        return Object.assign({}, state, action.payload.data.attributes, {
          loading: false,
          openingHours: openingHours(state.openingHours, action),
          specialOpeningHours: specialOpeningHours(
            state.specialOpeningHours,
            action
          ),
          closedDays: closedDays(state.closedDays, action)
        });
      }
      return state;
    case SAVE_LOCATION_ERROR:
    case SAVE_LOCATIONS_OPENING_HOURS_ERROR:
    case SAVE_LOCATIONS_ENTRANCE_ERROR:
      return {
        ...state,
        loading: false
      };
    case FETCH_LOCATIONS_ERROR:
    case FETCH_LOCATION_ERROR:
      return {
        loading: false
      };
    case SAVE_LOCATION_IMAGE_SUCCESS:
      if (String(state.id) !== String(action.location)) {
        return state;
      }
      return Object.assign({}, state, {
        images: images(state.images, saveImageSuccess(action))
      });
    case DELETE_LOCATION_IMAGE_SUCCESS:
      if (String(state.id) !== String(action.location)) {
        return state;
      }
      return Object.assign({}, state, {
        images: images(state.images, deleteImageSuccess(action))
      });
    case PATCH_LOCATION_IMAGES_SUCCESS:
      if (String(state.id) !== String(action.location)) {
        return state;
      }
      return Object.assign({}, state, {
        images: images(state.images, patchImagesSuccess(action))
      });
    case FETCH_SETTINGS_OPENING_HOURS_SUCCESS:
    case FETCH_LOCATIONS_OPENING_HOURS_SUCCESS: {
      if (String(state.id) !== String(action.id)) {
        return {
          ...state,
          loading: false
        };
      }
      return {
        ...state,
        loading: false,
        openingHours: openingHours(state.openingHours, action),
        closedDays: closedDays(state.closedDays, action),
        specialOpeningHours: specialOpeningHours(
          state.specialOpeningHours,
          action
        )
      };
    }
    case FETCH_LOCATIONS_ENTRANCES_SUCCESS: {
      if (String(state.id) !== String(action.id)) {
        return {
          ...state,
          loading: false
        };
      }

      return {
        ...state,
        loading: false,
        entrances: action.payload.data
      };
    }
    case SAVE_LOCATIONS_ENTRANCE_SUCCESS: {
      if (String(state.id) !== String(action.id)) {
        return {
          ...state,
          loading: false
        };
      }

      const existing = state.entrances?.find(({ id }) => String(id) === String(action.payload.data.id));
      const entrance = action.payload.data;

      return {
        ...state,
        loading: false,
        entrances:
          state.entrances && existing
            ? state.entrances.map(e => (String(e.id) !== String(existing.id) ? e : entrance))
            : state.entrances
            ? [...state.entrances, entrance]
            : [entrance]
      };
    }
    case DELETE_LOCATIONS_ENTRANCE_SUCCESS: {
      if (String(state.id) === String(action.locationId)) {
        return {
          ...state,
          entrances:
            state.entrances && state.entrances.length
              ? state.entrances.filter(e => String(e.id) !== String(action.entranceId))
              : state.entrances
        };
      }
      return state;
    }
    case REMOVE_GEOPOINT_SUCCESS:
      if (String(action.id) === String(state.id)) {
        return {
          ...state,
          geoPoint: null
        };
      }
      return state;
    default:
      return state;
  }
};

// MAIN

const reducer = combineReducers({
  items,
  selected,
  meta
});

reducer.reducer = 'locations';

export default reducer;
