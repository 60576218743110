import React from 'react';
import styled, { css, useTheme } from 'styled-components';

const svgStyle = css`
  path,
  circle,
  ellipse,
  rect {
    transition: all 0.1s linear;
  }
`;

const withSvg = WrappedSvg => {    
  const WithSvg = (props) => {
    const theme = useTheme();

    return <WrappedSvg {...props} theme={theme} />
  };

  const WithSvgStyled = styled(WithSvg)`
    ${svgStyle};
  `;

  return WithSvgStyled;
};

export default withSvg;
