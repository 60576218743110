import React from 'react';
import PropTypes from 'prop-types';

import StyledScannerSpinner from './ScannerSpinner.styled';

const ScannerSpinner = ({ scannerSpinnerVisible }) => !scannerSpinnerVisible ? null : (
  <StyledScannerSpinner>
    <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
  </StyledScannerSpinner>
);

ScannerSpinner.propTypes = {
  scannerSpinnerVisible: PropTypes.bool
};

export default ScannerSpinner;
