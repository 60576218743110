import { createRouterReducer } from '@lagunovsky/redux-react-router';

import history from '../../shared/store/history';
import localeReducer from '../../shared/locale';

const clientReducers = {
  router: createRouterReducer(history),
  locale: localeReducer,
};

export default clientReducers;