import React from 'react';

import { useModalClose } from '../hooks';

// TODO Deprecated in favour of the useModalClose hook
const withModalClose = Component => {
  const WithModalClose = (props) => {
    const { clickClose } = useModalClose();

    return (
      <Component 
        clickClose={ clickClose } 
        { ...props } 
      />
    )
  }

  return WithModalClose;
};

export default withModalClose;
