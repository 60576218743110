import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import useStoreState from '../../../../shared/hooks/useStoreState';
import { url, qrCode as qrCodeApiConfig } from '../../../config/api';

import QR from './QR.component';

const QRContainer = () => {
  const { login } = useStoreState();
  const params = useParams();

  const [ src, setSrc ] = useState('');

  const createSrc = useCallback(() => {
    const size = params.size;
    const path = `${params.path}/${params['*']}`;

    return path && login.loggedIn
      ? `${url}/${path}?size=${size}&${qrCodeApiConfig.tokenParamName}=${login.token}` 
      : '';
  }, [ login, params ]);

  useEffect(() => {
    setSrc(createSrc());
  }, [ createSrc ]);

  return <QR src={src} />;
}

export default QRContainer;
