import React from 'react';
import PropTypes from 'prop-types';

import StyledErrorMessage from './ErrorMessage.styled';

const ErrorMessage = ({ children }) => (
  <StyledErrorMessage>
    { children }
  </StyledErrorMessage>
);

ErrorMessage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ])
};

export default ErrorMessage;
