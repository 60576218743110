import styled, { css } from 'styled-components';

import StyledSelect from '../../../Form/Select/Select.styled';

const alignLeft = css`
  .Select {
    .Select-control {
      display: flex;
      text-align: left;
    }

    .Select-placeholder {
      position: static;
      padding-right: 4px;
    }

    .Select-input {
      position: absolute;
      left: 0;
      top: 0;
    }
  }
`;

const dark = css`
  .Select {
    .Select-placeholder {
      color: ${props => props.theme.colorGray};
    }
  }
`;

const standalone = css`
  float: right;
  margin-top: -85px;
`;

const numbers = css`
  display: block;
  height: 15px;

  .Select {
    width: auto;
    
    .Select-menu-outer {
      width: 76px;
      left: auto;
      right: 0;
      margin-top: 2px;
      margin-right: -3px;
    }
    
    .Select-option {
      text-align:right;
    }
  }
`;

const StyledTableFilterSelect = styled(StyledSelect)`
  display: inline-block;

  ${props => props.$standalone ? standalone : null};

  .Select {
    display: inline-block;
    width: 150px;

    .Select-control {
      text-align: right;
      height: auto;
      color: inherit;
      font-size: inherit;
      border: 0;
      background: transparent;
      box-shadow: none;
    }

    &.is-open,
    &.is-focused,
    &.is-pseudo-focused {
      .Select-control {
        border: 0;
        background: transparent;
        box-shadow: none;
      }
    }

    .Select-input,
    .Select-value,
    .Select-placeholder,
    &.Select--single > .Select-control .Select-value {
      height: auto;
      padding: 0 12px 0 0;
    }

    .Select-arrow-zone {
      width: 10px;
      border: 0;

      svg path {
        stroke: ${props => props.theme.colorGrayDark};
      }
    }

    .Select-input,
    .Select-value,
    .Select-placeholder,
    &.Select--single > .Select-control .Select-value,
    &.has-value.Select--single > .Select-control .Select-value .Select-value-label,
    &.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
      line-height: inherit;
      color: inherit;
      font-size: inherit;
    }
  }
  
  ${props => props.$numbers ? numbers : null};
  ${props => props.$dark ? dark : null};
  ${props => props.$alignLeft ? alignLeft : null};
`;

export default StyledTableFilterSelect;
