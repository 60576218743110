export const table = {
  name: 'staff',
  columns: [
    'email',
    'type',
    'firstName',
    'lastName',
    'lastActivity',
  ],
  disabledColumns: [
    'type',
  ]
};

export const OPERATOR_ROLE = 'operator';
export const LOCATION_ADMIN_ROLE = 'location_admin';
export const NON_ADMIN_ROLE = 'non_admin';

export const nonAdminRoles = [
  "ROLE_USER", 
  "ROLE_BUA_LOCATION_VIEW_ALL", 
  "ROLE_BUA_LOCATION_REPRESENT_{id}",
];

export const operatorRoles = [
  "ROLE_BUA_SETTINGS_VIEW_{id}",
  "ROLE_BUA_EQUIPMENTCATEGORY_VIEW_ALL",
  "ROLE_BUA_EQUIPMENT_CREATE_{id}",
  "ROLE_BUA_EQUIPMENT_VIEW_{id}",
  "ROLE_BUA_EQUIPMENT_EDIT_{id}",
  "ROLE_BUA_EQUIPMENT_REMOVE_{id}",
  "ROLE_BUA_USER_CREATE_ALL",
  "ROLE_BUA_USER_VIEW_ALL",
  "ROLE_BUA_USER_EDIT_ALL",
  "ROLE_BUA_USER_REMOVE_ALL",
  "ROLE_BUA_RESERVATION_CREATE_{id}",
  "ROLE_BUA_RESERVATION_VIEW_{id}",
  "ROLE_BUA_RESERVATION_EDIT_{id}",
  "ROLE_BUA_RESERVATION_REMOVE_{id}",
  "ROLE_BUA_LOAN_CREATE_{id}",
  "ROLE_BUA_LOAN_VIEW_{id}",
  "ROLE_BUA_LOAN_EDIT_{id}",
  "ROLE_BUA_LOAN_REMOVE_{id}",
  "ROLE_BUA_ORGANIZATION_CREATE_{id}",
  "ROLE_BUA_ORGANIZATION_VIEW_{id}",
  "ROLE_BUA_ORGANIZATION_EDIT_{id}",
  "ROLE_BUA_ORGANIZATION_REMOVE_{id}",
];

export const adminRoles = [
  "ROLE_BUA_SETTINGS_EDIT_{id}",
  "ROLE_BUA_ADMIN_USER_VIEW_{id}",
  "ROLE_BUA_ADMIN_USER_EDIT_{id}",
  "ROLE_BUA_ADMIN_USER_CREATE_{id}",
  "ROLE_BUA_ADMIN_USER_REMOVE_{id}",
]

export const generateNonAdminRoles = locationId => {
  return nonAdminRoles.map(r => r.replace("{id}", locationId))
}

export const generateOperatorRoles = locationId => {
  return [
    ...generateNonAdminRoles(locationId),
    ...operatorRoles.map(r => r.replace("{id}", locationId)), 
  ]
}

export const generateAdminRoles = (locationId) => {
  return [
    ...generateOperatorRoles(locationId),
    ...adminRoles.map(r => r.replace("{id}", locationId))
  ]
}
