import { InMemoryCache, createHttpLink } from '@apollo/client';
import { ApolloClient } from '@apollo/client';

import possibleTypes from './possibleTypes.json';

const gql_url = window?.GQL_API_PATH;

const client = new ApolloClient({
  link: createHttpLink({ uri: gql_url }),
  cache: new InMemoryCache({ possibleTypes })
});

export default client;
