import { createGlobalStyle } from 'styled-components';

import { fonts } from '../../../shared/styles/fonts';
import vars from '../variables';

const TypographyGlobalStyles = createGlobalStyle`
  ${fonts(vars.fonts)};

  button,
  input,
  optgroup,
  select,
  textarea,
  html,
  body {
    font-family: '${vars.fonts.regular}', sans-serif;
  }

  html {
    font-size: 10px;
    color: ${vars.colorGrayDarker};
  }

  body {
    font-size: 13px;
    line-height: 1.23;
  }
  
  b,
  strong {
    font-weight: normal;
    font-family: '${vars.fonts.medium}';
  }

  h1,
  h2 {
    font-weight: normal;
    line-height: 1.2;
  }

  h1 {
    font-size: 28px;
    margin: 0 0 48px;
  }

  h2 {
    font-size: 24px;
    margin: 0 0 6px;
    font-weight: normal;
  }

  h3,
  h4 {
    font-weight: normal;
  }

  p {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export default TypographyGlobalStyles;
