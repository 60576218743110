export const table = {
  name: 'equipment',
  columns: [
    'name',
    'category.breadcrumbs',
    'brand.value',
    'variants.length',
    'icon'
  ],
  disabledColumns: [
    'brand',
    'variants',
    'icon'
  ]
};

export const tableVariants = {
  name: 'equipment_variant',
  columns: [
    'total',
    'instanceBuffer',
    'icon'
  ],
  disabledColumns: [
    'icon'
  ]
};

export const tableInstance = {
  name: 'equipment_instance',
  columns: [
    'identifier',
    'name',
    'variantAttributeValues',
    'status'
  ],
  disabledColumns: [
    'identifier',
    'name',
    'variantAttributeValues',
    'status'
  ]
};

export const equipmentSearchQueryFields = 'name,attributeValues.value,variantAttributeValues.value,category.name,categoryParentName,variants.instances.identifier,variants.instances.labelIdentifier';

export const variantSearchQueryFields = 'attributeValues.value,instances.identifier,equipment.name,equipment.category.name,equipment.attributeValues.value,instances.labelIdentifier';

