export const POST_LOGIN = '@@login/POST_LOGIN';
export const CHECK_LOGIN_TOKEN = '@@login/CHECK_LOGIN_TOKEN';
export const NO_LOGIN_TOKEN = '@@login/NO_LOGIN_TOKEN';
export const SET_LOGIN_TOKEN = '@@login/SET_LOGIN_TOKEN';
export const FETCH_LOGIN_INFO = '@@login/FETCH_LOGIN_INFO';
export const SET_LOGIN_INFO = '@@login/SET_LOGIN_INFO';
export const LOGIN_SUCCESS = '@@login/LOGIN_SUCCESS';
export const LOGIN_ERROR = '@@login/LOGIN_ERROR';
export const CANCEL_POST_LOGIN = '@@login/CANCEL_POST_LOGIN';
export const LOGOUT = '@@login/LOGOUT';
export const LOGOUT_SUCCESS = '@@login/LOGOUT_SUCCESS';
export const LOGOUT_ERROR = '@@login/LOGOUT_ERROR';
export const REFRESH_TOKEN = '@login/REFRESH_TOKEN';
export const REFRESH_TOKEN_SUCCESS = '@login/REFRESH_TOKEN_SUCCESS';

export const REFRESH_LOGIN_SESSION = '@login/REFRESH_LOGIN_SESSION';
export const REFRESH_LOGIN_SESSION_SUCCESS = '@login/REFRESH_LOGIN_SESSION_SUCCESS';

export const FORGOT_PASSWORD = '@login/FORGOT_PASSWORD';
export const CANCEL_FORGOT_PASSWORD = '@login/CANCEL_FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = '@login/FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = '@login/FORGOT_PASSWORD_ERROR';

export const RESET_PASSWORD = '@login/RESET_PASSWORD';
export const CANCEL_RESET_PASSWORD = '@login/CANCEL_RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = '@login/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = '@login/RESET_PASSWORD_ERROR';

export function postLogin(username, password) {
  return {
    type: POST_LOGIN,
    username,
    password
  };
}

export function loginSuccess(payload, time, vendor) {
  return {
    type: LOGIN_SUCCESS,
    payload,
    time,
    vendor,
  };
}

export function noLoginToken() {
  return {
    type: NO_LOGIN_TOKEN
  };
}

export function checkLoginToken() {
  return {
    type: CHECK_LOGIN_TOKEN
  };
}

export function setLoginToken(payload) {
  return {
    type: SET_LOGIN_TOKEN,
    payload
  };
}

export function fetchLoginInfo() {
  return {
    type: FETCH_LOGIN_INFO
  };
}

export function setLoginInfo(payload, time) {
  return {
    type: SET_LOGIN_INFO,
    payload,
    time
  };
}

export function loginError(err) {
  return {
    type: LOGIN_ERROR,
    error: true,
    payload: err
  };
}

export function logout(message) {
  return {
    type: LOGOUT,
    message
  };
}

export function logoutSuccess(message) {
  return {
    type: LOGOUT_SUCCESS,
    message
  };
}

export function logoutError(err) {
  return {
    type: LOGOUT_ERROR,
    payload: err
  };
}

export function refreshToken(payload) {
  return {
    type: REFRESH_TOKEN,
    payload
  };
}

export function refreshTokenSuccess(payload) {
  return {
    type: REFRESH_TOKEN_SUCCESS,
    payload
  };
}

export function refreshLoginSession() {
  return {
    type: REFRESH_LOGIN_SESSION
  };
}

export function refreshLoginSessionSuccess(payload) {
  return {
    type: REFRESH_LOGIN_SESSION_SUCCESS,
    payload
  };
}

export function forgotPassword(username) {
  return {
    type: FORGOT_PASSWORD,
    username
  };
}

export function forgotPasswordSuccess(payload) {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    payload
  };
}

export function forgotPasswordError(err) {
  return {
    type: FORGOT_PASSWORD_ERROR,
    payload: err
  };
}

export function resetPassword(token, password, password_repeat) {
  return {
    type: RESET_PASSWORD,
    token,
    password,
    password_repeat
  };
}

export function resetPasswordSuccess(payload) {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload
  };
}

export function resetPasswordError(err) {
  return {
    type: RESET_PASSWORD_ERROR,
    payload: err
  };
}
