import { createRouterMiddleware } from '@lagunovsky/redux-react-router';

import { urlQueryMiddleware } from '../../shared/libs/react-url-query';
import history from '../../shared/store/history';
import logic from '../components/App/App.logic';

const middleware = [
  logic,
  createRouterMiddleware(history),
  urlQueryMiddleware()
];

export default middleware;