import styled from 'styled-components';

const StyledForm = styled.form`
  display: block;
  max-width: ${props => props.$full ? '476px' : 'none' };

  p {
    margin-bottom: 16px;
    font-size: 14px;
    color: ${props => props.theme.colorGrayDark};
  }

  .section {
    border-bottom: ${props => props.theme.colorGrayLight} solid 1px;
    padding: 0 0 ${props => props.$full ? 64 : 24 }px 0;
    margin: ${props => props.$full ? 64 : 0 }px 0 ${props => props.$full ? 32 : 24 }px 0;

    &:last-child {
      padding-bottom: 0;
      border-bottom: 0;
    }

    & > h3 {
      text-transform: uppercase;
      font-size: 11px;
      margin-bottom: 24px;
    }
  }

  /* stylelint-disable-next-line */
  ::-webkit-input-placeholder {
    color: ${props => props.theme.colorGray};
  }

  /* stylelint-disable-next-line */
  ::-moz-placeholder {
    color: ${props => props.theme.colorGray};
  }

  /* stylelint-disable-next-line */
  :-ms-input-placeholder {
    color: ${props => props.theme.colorGray};
  }

  /* stylelint-disable-next-line */
  :-moz-placeholder {
    color: ${props => props.theme.colorGray};
  }
`;

export default StyledForm;
