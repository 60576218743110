import Promise from 'pinkie-promise';
import superagent from 'superagent';

export const LOAD_LOCALE_SUCCESS = '@@locale/LOAD_LOCALE_SUCCESS';
export const LOAD_LOCALE_ERROR = '@@locale/LOAD_LOCALE_ERROR';

export const loadLocaleSuccess = locale => {
  return {
    type: LOAD_LOCALE_SUCCESS,
    locale
  };
};

export const loadLocaleError = error => {
  return {
    type: LOAD_LOCALE_ERROR,
    error
  };
};

export const LocaleLoader = (file, store) => {
  return new Promise((resolve, reject) => {
    superagent.get(file).end((err, resp) => {
      if (err) {
        store.dispatch(loadLocaleError(err));
        return reject(err);
      }
      store.dispatch(loadLocaleSuccess(resp.body));
      return resolve(resp.body);
    });
  });
};

export const reducer = (state = {}, action) => {
  switch (action.type) {
    case LOAD_LOCALE_SUCCESS:
      return action.locale;
    default:
      return state;
  }
};

export default reducer;
