import { DELETE_USER_SUCCESS } from '../../config/users';
import {
  SAVE_LOCATION_SUCCESS,
  REMOVE_LOCATION_SUCCESS
} from '../Locations/Locations.actions';

import {
  LOGIN_SUCCESS,
  SET_LOGIN_TOKEN,
  SET_LOGIN_INFO,
  LOGOUT_SUCCESS,
  POST_LOGIN,
  LOGIN_ERROR,
  REFRESH_TOKEN_SUCCESS,
  CHECK_LOGIN_TOKEN,
  NO_LOGIN_TOKEN
} from './Login.actions';

const initialState = { loggedIn: false };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_LOGIN:
      return {
        ...initialState,
        loading: true
      };
      
    case LOGIN_SUCCESS:
      return {
        ...action.payload,
        loggedIn: true,
        time: action.time
      };

    case SET_LOGIN_TOKEN:
      return {
        ...state,
        ...action.payload
      };

    case SET_LOGIN_INFO:
      return {
        ...state,
        ...action.payload,
        loggedIn: true,
        time: action.time,
        loading: false
      };

    case LOGOUT_SUCCESS:
    case DELETE_USER_SUCCESS:
      return initialState;

    case LOGIN_ERROR:
      return {
        ...initialState,
        error: action.payload
      };

    case REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        ...action.payload
      };

    case CHECK_LOGIN_TOKEN:
      return {
        ...state,
        loading: true
      };

    case NO_LOGIN_TOKEN:
      return {
        state,
        loading: false
      };

    case REMOVE_LOCATION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          represents: state.data.represents.filter(r => String(r.id) !== String(action.id))
        }
      };

    case SAVE_LOCATION_SUCCESS: {
      let {
        id,
        attributes: { name }
      } = action.payload.data;

      return {
        ...state,
        data: {
          ...state.data,
          represents: !action.payload.id
            ? [...state.data.represents, { id, name }]
            : state.data.represents
        }
      };
    }
    default:
      return state;
  }
};

reducer.reducer = 'login';

export default reducer;
