import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import withConfirmDialog from '../../../../../shared/hoc/withConfirmDialog';
import Modal from '../../Modal/Modal.container';
import StyledForm from '../../Form/Form.styled';
import StyledButton from '../../Form/Button/Button.styled';

const ConfirmDialog = ({ action, clickConfirm, clickCancel }) => (
  <Modal onClose={clickCancel}>
    <StyledForm>
      <FormattedMessage tagName="h2" id="forms.confirm" />
      {action.content}
      <div className="buttons">
        <StyledButton type="button" $discrete onClick={ clickCancel }>
          <FormattedMessage id="forms.cancel" />
        </StyledButton>

        <StyledButton type="button" onClick={ () => clickConfirm( action ) }>
          <FormattedMessage id="forms.confirm" />
        </StyledButton>
      </div>
    </StyledForm>
  </Modal>
);

ConfirmDialog.propTypes = {
  clickConfirm: PropTypes.func,
  clickCancel: PropTypes.func,
  action: PropTypes.object
};

export default withConfirmDialog(
  ConfirmDialog
);
