import styled from 'styled-components';

const StyledLoadingAnimation = styled.div`
  padding: 10px;
  margin: ${props => (props.$margin ? props.$margin : 'auto')};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  small {
    all: unset;
    font-size: 13px;
    color: ${props => props.theme.colorGrayDarker};
    margin-top: 4px;
  }
`;

export default StyledLoadingAnimation;
