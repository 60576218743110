export const FETCH_EQUIPMENT_VARIANTS = '@@equipment/FETCH_EQUIPMENT_VARIANTS';
export const FETCH_EQUIPMENT_VARIANTS_SUCCESS = '@@equipment/FETCH_EQUIPMENT_VARIANTS_SUCCESS';
export const FETCH_EQUIPMENT_VARIANTS_ERROR = '@@equipment/FETCH_EQUIPMENT_VARIANTS_ERROR';
export const CANCEL_FETCH_EQUIPMENT_VARIANTS = '@@equipment/CANCEL_FETCH_EQUIPMENT_VARIANTS_ERROR';

export const FETCH_EQUIPMENT_VARIANT = '@@equipment/FETCH_EQUIPMENT_VARIANT';
export const FETCH_EQUIPMENT_VARIANT_SUCCESS = '@@equipment/FETCH_EQUIPMENT_VARIANT_SUCCESS';
export const FETCH_EQUIPMENT_VARIANT_ERROR = '@@equipment/FETCH_EQUIPMENT_VARIANT_ERROR';
export const CANCEL_FETCH_EQUIPMENT_VARIANT = '@@equipment/CANCEL_EQUIPMENT_VARIANT_ERROR';

export const SAVE_EQUIPMENT_VARIANT = '@@equipment/SAVE_EQUIPMENT_VARIANT';
export const SAVE_EQUIPMENT_VARIANT_SUCCESS = '@@equipment/SAVE_EQUIPMENT_VARIANT_SUCCESS';
export const SAVE_EQUIPMENT_VARIANT_ERROR = '@@equipment/SAVE_EQUIPMENT_VARIANT_ERROR';
export const CANCEL_SAVE_EQUIPMENT_VARIANT = '@@equipment/CANCEL_SAVE_EQUIPMENT_VARIANT';

export const REMOVE_EQUIPMENT_VARIANT = '@@equipment/REMOVE_EQUIPMENT_VARIANT';
export const REMOVE_EQUIPMENT_VARIANT_SUCCESS = '@@equipment/REMOVE_EQUIPMENT_VARIANT_SUCCESS';
export const REMOVE_EQUIPMENT_VARIANT_ERROR = '@@equipment/REMOVE_EQUIPMENT_VARIANT_ERROR';
export const CANCEL_REMOVE_EQUIPMENT_VARIANT = '@@equipment/CANCEL_REMOVE_EQUIPMENT_VARIANT';

export const REMOVE_EQIPMENT_VARIANT = '@@equipment/REMOVE_EQIPMENT_VARIANT';
export const REMOVE_EQIPMENT_VARIANT_SUCCESS = '@@equipment/REMOVE_EQIPMENT_VARIANT_SUCCESS';
export const REMOVE_EQIPMENT_VARIANT_ERROR = '@@equipment/REMOVE_EQIPMENT_VARIANT_ERROR';
export const CANCEL_REMOVE_EQIPMENT_VARIANT = '@@equipment/CANCEL_REMOVE_EQIPMENT_VARIANT';

export const SET_EQUIPMENT_VARIANT_MODE = '@@equipment/SET_EQUIPMENT_VARIANT_MODE';

export const RESET_SELECTED = '@@equipment/RESET_SELECTED';
export const CHANGE_SORT = '@@equipment/CHANGE_SORT';

export function changeSort(sort, desc) {
  return {
    type: CHANGE_SORT,
    sort,
    desc
  };
}

export const fetchEquipmentVariant = config => ({
  type: FETCH_EQUIPMENT_VARIANT,
  ...config
});

export const fetchEquipmentVariantSuccess = payload => ({
  type: FETCH_EQUIPMENT_VARIANT_SUCCESS,
  payload
});

export const fetchEquipmentVariantError = err => ({
  type: FETCH_EQUIPMENT_VARIANT_ERROR,
  payload: err
});

export const saveEquipmentVariant = (payload, nextStep, id) => ({
  type: SAVE_EQUIPMENT_VARIANT,
  payload,
  nextStep,
  id
});

export const saveEquipmentVariantSuccess = (payload, nextStep) => ({
  type: SAVE_EQUIPMENT_VARIANT_SUCCESS,
  payload,
  nextStep
});

export const saveEquipmentVariantError = err => ({
  type: SAVE_EQUIPMENT_VARIANT_ERROR,
  payload: err
});

export const fetchEquipmentVariants = config => ({
  type: FETCH_EQUIPMENT_VARIANTS,
  ...config
});

export const fetchEquipmentVariantsSuccess = payload => ({
  type: FETCH_EQUIPMENT_VARIANTS_SUCCESS,
  payload
});

export const fetchEquipmentVariantsError = err => ({
  type: FETCH_EQUIPMENT_VARIANTS_ERROR,
  payload: err
});

export const removeEquipmentVariant = payload => ({
  type: REMOVE_EQUIPMENT_VARIANT,
  ...payload
});

export const removeEquipmentVariantSuccess = payload => ({
  type: REMOVE_EQUIPMENT_VARIANT_SUCCESS,
  ...payload
});

export const removeEquipmentVariantError = err => ({
  type: REMOVE_EQUIPMENT_VARIANT_ERROR,
  payload: err
});

export const setVariantCreatingMode = payload => ({
  type: SET_EQUIPMENT_VARIANT_MODE,
  payload,
});

export const resetSelected = () => ({
  type: RESET_SELECTED
});
