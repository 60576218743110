import { replace, push } from '@lagunovsky/redux-react-router';
import omit from 'lodash/omit';
import _ from 'lodash';

import { createLogicWithApi, createLogicWithRouteChecks } from '../../../../shared/logicCreators';
import { URL_CHANGE } from '../../../../shared/components/App/App.actions';
import { VERIFICATION_PUBSUB } from '../../common/PubSub/PubSub.actions';
import { table as tableConfig } from '../../../config/users';
import { users as apiConfig } from '../../../config/api';

import {
  FETCH_USERS,
  FETCH_USERS_SUCCESS,
  CANCEL_FETCH_USERS,
  FETCH_USER,
  CANCEL_FETCH_USER,
  SAVE_USER,
  SAVE_USERS,
  CANCEL_SAVE_USERS,
  SAVE_USER_SUCCESS,
  SAVE_USERS_SUCCESS,
  CANCEL_SAVE_USER,
  REMOVE_USER,
  REMOVE_USER_SUCCESS,
  CANCEL_REMOVE_USER,
  PATCH_USER,
  CANCEL_PATCH_USER,
  REMOVE_USERS,
  REMOVE_USERS_SUCCESS,
  CANCEL_REMOVE_USERS,
  selectUserTable,
  fetchUsersSuccess,
  fetchUsersError,
  fetchUser,
  fetchUserSuccess,
  fetchUserError,
  saveUserSuccess,
  saveUsersSuccess,
  saveUserError,
  removeUserSuccess,
  removeUserError,
  patchUserError,
  patchUserSuccess,
  removeUsersSuccess,
  removeUsersError,
  resetUsers,
} from './Users.actions';

const checkUserRouteLogic = createLogicWithRouteChecks({
  type: URL_CHANGE,
  process: ({ checkUnselectedIdParam, checkInitSelect }, dispatch, done) => {
    const selectId = checkInitSelect('users');
    
    if (selectId) {
      dispatch(replace(`${apiConfig.path}/${selectId}${window.location.search}`));

    } else {
      const newIdParam = checkUnselectedIdParam('users');

      if (newIdParam !== false) {
        dispatch(selectUserTable(newIdParam));
        dispatch(fetchUser(newIdParam));
      }
    }

    done();
  }
});

const fetchUsersLogic = createLogicWithApi({
  type: FETCH_USERS,
  cancelType: CANCEL_FETCH_USERS,
  latest: true,
  process: ({ Api, getState, action }, dispatch, done) => {
    const state = getState();
    const search = typeof action.search !== 'undefined' ? action.search : state.table.search;

    if (!search || search.length < 2) {
      dispatch(resetUsers());
      done();

      return false;
    }

    return Api({
      method: 'get',
      path: apiConfig.path,
      handle: r => {
        const sort = action.sort ? action.sort : state.table.items[tableConfig.name].sort;
        const desc = typeof action.desc !== 'undefined' ? action.desc : state.table.items[tableConfig.name].desc;
        const limit =  typeof action.limit !== 'undefined' ? action.limit : state.table.items[tableConfig.name].limit;
        const offset =  typeof action.offset !== 'undefined' ? action.offset : state.table.items[tableConfig.name].offset;
        let filters = typeof action.filters !== 'undefined' ? action.filters : state.table.filters;

        if (typeof filters.banned !== 'number') {
          filters = omit(filters, ['banned']);
        }

        if (offset) {
          r.query({'offset': offset});
        }

        r
          .query({'limit': limit})
          .query({'filters': filters})
          .query({'query': search})
          .query({'order[field]': sort})
          .query({'order[dir]': desc ? 'desc' : 'asc' });

        return r;
      }
    })
    .then(resp => {
      dispatch(fetchUsersSuccess(resp));
      done();
    })
    .catch(err => {
      dispatch(fetchUsersError(err));
      done();
    });
  }
});

const checkFetchedUserSelectionLogic = createLogicWithRouteChecks({
  type: FETCH_USERS_SUCCESS,
  process: ({ checkInitSelect }, dispatch, done) => {
    const selectId = checkInitSelect('users');

    if (selectId) {
      dispatch(replace(`${apiConfig.path}/${selectId}${window.location.search}`));
    }

    done();
  }
});

const fetchUserLogic = createLogicWithApi({
  type: [ FETCH_USER, VERIFICATION_PUBSUB ],
  cancelType: CANCEL_FETCH_USER,
  latest: true,
  process: ({ action, Api }, dispatch, done) => {
    return Api({
      method: 'get',
      path: `${apiConfig.path}/${action.id}`
    })
      .then(resp => {
        dispatch(fetchUserSuccess(resp));
        done();
      })
      .catch(err => {
        dispatch(fetchUserError(err));
        done();
      });
  }
});

const saveUserLogic = createLogicWithApi({
  type: SAVE_USER,
  cancelType: CANCEL_SAVE_USER,
  process: ({ action, Api }, dispatch, done) => {
    const method = action.payload.id ? 'PATCH' : 'post';
    const path = `${apiConfig.path}${action.payload.id ? `/${action.payload.id}` : ''}`;

    return Api({
      method: method,
      path: path,
      handle: r => {
        return r.send(
          action.payload.id ?
          {
            attributes: action.payload,
            type: 'user',
            id: action.payload.id ? action.payload.id : null
          } : {
            attributes: {...action.payload, locale: 'no'}
          }
        );
      }
    })
    .then(resp => {
      resp.data.attributes = {
        ...resp.data.attributes,
        ...(_.omit(action.payload, 'bannedLocation'))
      };
      dispatch(saveUserSuccess(resp, action.nextStep, action.payload.id));
      done();
    })
    .catch(err => {
      dispatch(saveUserError(err));
      done();
    });
  }
});

const saveUserSuccessLogic = createLogicWithRouteChecks({
  type: SAVE_USER_SUCCESS,
  process: ({ state, action }, dispatch, done) => {
    if (action.nextStep !== false) {
      if (action.nextStep) {
        dispatch(push(`${apiConfig.path}/${action.payload.data.id}${state.locale.actions.create.path}/${action.nextStep}${window.location.search}`));

      } else {
        dispatch(push(`${apiConfig.path}/${action.payload.data.id}${window.location.search}`));
      }
    }

    done();
  }
});

const removeUserLogic = createLogicWithApi({
  type: REMOVE_USER,
  cancelType: CANCEL_REMOVE_USER,
  latest: true,
  process: ({ action, Api }, dispatch, done) => {
    return Api({
      method: 'DELETE',
      path: `${apiConfig.path}/${action.payload.id}`,
    })
      .then(() => {
        dispatch(removeUserSuccess({id: action.payload.id, children: action.payload.children}));
        done();
      })
      .catch(err => {
        dispatch(removeUserError(err));
        done();
      })
  }
});

const checkRemoveUserLogic = createLogicWithRouteChecks({
  type: REMOVE_USER_SUCCESS,
  process: (_, dispatch, done) => {
    dispatch(push(`${apiConfig.path}${window.location.search}`));
    done();
  }
});

const removeUsersLogic = createLogicWithApi({
  type: REMOVE_USERS,
  cancelType: CANCEL_REMOVE_USERS,
  process: (({ action, Api }, dispatch, done) => {
    const requests = action.payload.map(id => {
      return Api({
        method: 'DELETE',
        path: `${apiConfig.path}/${id}`
      })
    });

    return Promise.all(requests)
      .then(() => {
        dispatch(removeUsersSuccess(action.payload));
        done();
      })
      .catch(err => {
        dispatch(removeUsersError(err));
        done();
      });
  })
});

const checkRemoveUsersLogic = createLogicWithRouteChecks({
  type: REMOVE_USERS_SUCCESS,
  process: (_, dispatch, done) => {
    dispatch(push(`${apiConfig.path}${window.location.search}`));
    done();
  }
});

const saveUsersLogic = createLogicWithApi({
  type: SAVE_USERS,
  cancelType: CANCEL_SAVE_USERS,
  process: (({ action, Api }, dispatch, done) => {
    return Api({
      method: 'POST',
      path: `${apiConfig.listPath}`,
      handle: r => {
        return r.send(action.payload);
      }
    })
      .then(resp => {
        dispatch(saveUsersSuccess(resp, action.nextStep));
        done();
      })
      .catch(err => {
        dispatch(saveUserError(err));
        done();
      });
  })
});

const saveUsersSuccessLogic = createLogicWithRouteChecks({
  type: SAVE_USERS_SUCCESS,
  process: ({ state, action }, dispatch, done) => {
    if (action.nextStep) {
      dispatch(push(`${apiConfig.path}/${state.users.selected.id}${state.locale.actions.create.path}/${action.nextStep}${window.location.search}`));

    } else {
      dispatch(push(`${apiConfig.path}${window.location.search}`));
    }

    done();
  }
});

const patchUserLogic = createLogicWithApi({
  type: PATCH_USER,
  cancelType: CANCEL_PATCH_USER,
  process: ({ action, Api }, dispatch, done) => {
    return Api({
      method: 'PATCH',
      path: `${apiConfig.path}/${action.payload.id}`,
      handle: r => {
        return r.send({
          attributes: action.payload,
          type: 'user',
          id: action.payload.id
        });
      }
    })
      .then(resp => {
        dispatch(patchUserSuccess(resp));
        done();
      })
      .catch(err => {
        dispatch(patchUserError(err));
        done();
      });
  }
});

const logics = [
  checkFetchedUserSelectionLogic,
  checkUserRouteLogic,
  fetchUsersLogic,
  fetchUserLogic,
  saveUserLogic,
  saveUsersSuccessLogic,
  saveUserSuccessLogic,
  removeUserLogic,
  saveUsersLogic,
  patchUserLogic,
  removeUsersLogic,
  checkRemoveUsersLogic,
  checkRemoveUserLogic
];

export default logics;