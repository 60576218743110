import { URL_CHANGE } from '../../../../shared/components/App/App.actions';
import { createLogicWithRouteChecks } from '../../../../shared/logicCreators';
import { routesWithLocation } from '../../../config/routes';
import { changeLocation } from '../Table/Table.actions';

const checkLocationChange = createLogicWithRouteChecks({
  type: URL_CHANGE,
  process: ({ isRelativeLocation, checkInitLocation, getState}, dispatch, done) => {
    if (isRelativeLocation(routesWithLocation)) {
      let state = getState();
      let location = checkInitLocation();

      if (location && String(location) !== String(state.table.currentLocation)) {
        dispatch(changeLocation(location));
      }
    }
    done();
  }
});

const logics = [
  checkLocationChange
];

export default logics;
