import React from 'react';

import NormalizeStyles from "../../shared/styles/globals/normalize";
import BoxSizingStyles from "../../shared/styles/globals/box-sizing";
import UtilitiesStyles from "../../shared/styles/globals/utilities";
import TextStyles from "../../shared/styles/globals/text";

import AttributesInlineGlobalStyles from "./globals/attributes-inline";
import FormPlaceholderGlobalStyles from "./globals/form/placeholder";
import TypographyGlobalStyles from "./globals/typography";
import FormHelperGlobalStyles from "./globals/form/helper";
import HtmlGlobalStyles from "./globals/html";

const GlobalStyles = () => (
  <>
    <NormalizeStyles />
    <BoxSizingStyles />
    <UtilitiesStyles />
    <TextStyles />
    <HtmlGlobalStyles />
    <TypographyGlobalStyles />
    <AttributesInlineGlobalStyles />
    <FormPlaceholderGlobalStyles />
    <FormHelperGlobalStyles />
  </>
);

export default GlobalStyles;
