import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _has from 'lodash/has';

import { getRoles, getRolesOfLocation } from '../components/common/AccessRights/AccessRights.selectors';

function withAccessRights(Component) {
  class Wrapper extends React.Component {
    static propTypes = {
      roles: PropTypes.object,
      rolesOfLocation: PropTypes.object
    };

    constructor(props) {
      super(props);

      this.checkAccessRights = this.checkAccessRights.bind(this);
      this.checkAuthorization = this.checkAuthorization.bind(this);
    }

    checkAccessRights(rules) {
      let {
        rolesOfLocation,
        roles
      } = this.props;

      return (rolesOfLocation && rules.every(rule => _has(rolesOfLocation, rule)))
        ? rules.every(rule => _get(rolesOfLocation, rule))
        : rules.every(rule => _get(roles.all, rule));
    }

    checkAuthorization(rules) {
      const { roles } = this.props;

      if (!roles) {
        return false;
      }

      const ruleKeys = Object.keys(rules);

      return !!Object.keys(roles).some(loc => ruleKeys.filter(rule => {
        return rules[rule] === !!_get(roles[loc], rule) || (loc !== 'all' && roles.all && rules[rule] === !!_get(roles.all, rule));
      }).length === ruleKeys.length);
    }

    render() {
      return (
        <Component {...this.props}
          checkAccessRights = {this.checkAccessRights}
          checkAuthorization = {this.checkAuthorization} 
        />
      );
    }
  }

  const mapStateToProps = (state) => ({
    rolesOfLocation: getRolesOfLocation(state),
    roles: getRoles(state)
  });

  return connect(
    mapStateToProps
  )(Wrapper);
}

export default withAccessRights;
