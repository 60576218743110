import { useSelector } from 'react-redux';

const selectStore = (state) => state;

const useStoreState = () => {
    const state = useSelector(selectStore);

    return state;
};

export default useStoreState;