import Promise from 'pinkie-promise';
import { replace, push } from '@lagunovsky/redux-react-router';

import { createLogicWithApi, createLogicWithRouteChecks, createLogic } from '../../../../shared/logicCreators';
import { SCANNER_ACTION_LOAN_OVERVIEW, equipmentScanCompleted } from '../../common/PubSub/PubSub.actions';
import { showNotification } from '../../../../shared/components/common/Dialogs/Dialogs.actions';
import { loans as apiConfig, locations as locationsApiConfig } from '../../../config/api';
import { table as tableConfig, defaultStatusFilter } from '../../../config/loans';
import { URL_CHANGE } from '../../../../shared/components/App/App.actions';
import { defaultFilters } from '../../../config/status';
import {
  FETCH_LOANS,
  CANCEL_FETCH_LOANS,
  FETCH_LOANS_SUCCESS,
  FETCH_LOAN,
  CANCEL_FETCH_LOAN,
  SAVE_LOAN,
  CANCEL_SAVE_LOAN,
  SAVE_LOAN_SUCCESS,
  SAVE_LOAN_PROGRESS,
  SAVE_LOAN_INTERNAL_NOTES,
  REMOVE_LOAN,
  CANCEL_REMOVE_LOAN,
  REMOVE_LOAN_SUCCESS,
  CLOSE_LOAN,
  CANCEL_CLOSE_LOAN,
  removeLoanSuccess,
  removeLoanError,
  fetchLoansSuccess,
  fetchLoansError,
  selectLoanTable,
  fetchLoan,
  fetchLoanSuccess,
  fetchLoanError,
  saveLoanSuccess,
  saveLoanError,
  saveLoanProgress,
  fetchLoanItemsSuccess,
  resetLoanItems,
  closeLoan,
  closeLoanSuccess,
  closeLoanError,
} from '../../../../shared/components/Loans/Loans.actions';

import LoanItemsLogic from './LoanItems/LoanItems.logic';

const checkLoansRouterLogic = createLogicWithRouteChecks({
  type: URL_CHANGE,
  process: ({ checkUnselectedIdParam, checkInitSelectWithLocation, getUrl, checkInitLocation, getState}, dispatch, done) => {
    const state = getState();
    const location = checkInitLocation();

    if (location) {
      const select = checkInitSelectWithLocation('loans');

      if (select && String(location) === String(state.table.currentLocation)) {
        dispatch(replace(`${getUrl('loans')}/${location}/${select}${window.location.search}`));

      } else {
        const newIdParam = checkUnselectedIdParam('loans', location);

        if (newIdParam !== false) {
          dispatch(selectLoanTable(newIdParam));
          dispatch(
            fetchLoan({
              id: newIdParam,
              location: location
            })
          );
        }
      }
    }
    done();
  }
});

const fetchLoansLogic = createLogicWithApi({
  type: FETCH_LOANS,
  cancelType: CANCEL_FETCH_LOANS,
  latest: true,
  process: ({ Api, getState, action }, dispatch, done) => {
    const state = getState();
    const location = action.location || state.table.currentLocation;

    return Api({
      method: 'GET',
      path: `${locationsApiConfig.path}/${location}${apiConfig.path}`,
      handle: r => {
        const state = getState();
        const sort = action.sort ? action.sort : state.table.items[tableConfig.name].sort;
        const desc = action.desc ? action.desc : state.table.items[tableConfig.name].desc;
        const search = typeof action.search !== 'undefined' ? action.search : state.table.search;
        const limit =  typeof action.limit !== 'undefined' ? action.limit : state.table.items[tableConfig.name].limit;
        const offset =  typeof action.offset !== 'undefined' ? action.offset : state.table.items[tableConfig.name].offset;
        let filters = typeof action.filters !== 'undefined' ? action.filters : state.table.filters;

        filters = filters || {};
        filters = {
          ...defaultFilters,
          status: defaultStatusFilter,
          ...filters
        };

        if (offset) {
          r.query({ 'offset': offset})
        }

        r
          .query({'limit': limit})
          .query({'filters': filters})
          .query({'query': search})
          .query({'order[field]': sort})
          .query({'order[dir]': desc ? 'desc' : 'asc'});

        return r;
      }
    })
    .then(resp => {
      dispatch(fetchLoansSuccess(resp));
      done();
    })
    .then(err => {
      dispatch(fetchLoansError(err));
      done();
    })
  }
});

const checkFetchedLoansSelectionLogic = createLogicWithRouteChecks({
  type: FETCH_LOANS_SUCCESS,
  process: ({ checkInitSelectWithLocation, checkInitLocation, getUrl}, dispatch, done) => {
    const location = checkInitLocation();
    const select = checkInitSelectWithLocation('loans');

    if (select) {
      dispatch(replace(`${getUrl('loans')}/${location}/${select}${window.location.search}`));
    }

    done();
  }
});

const fetchLoanLogic = createLogicWithApi({
  type: FETCH_LOAN,
  cancelType: CANCEL_FETCH_LOAN,
  latest: true,
  process: ({ action, Api, getState }, dispatch, done) => {
    const state = getState();
    const location = action.location ? action.location : state.table.currentLocation;

    const loan = Api({
      method: 'GET',
      path: `${locationsApiConfig.path}/${location}${apiConfig.path}/${action.id}`,
    });

    const items = Api({
      method: 'GET',
      path: `${locationsApiConfig.path}/${location}${apiConfig.path}/${action.id}${apiConfig.itemsPath}`,
      params: {
        limit: 0,
        filters: {
          state: 'temporary,published'
        }
      }
    });

    return Promise.all([loan, items])
      .then(resp => {
        dispatch(fetchLoanSuccess(resp[0]));
        dispatch(fetchLoanItemsSuccess(resp[1]));
        done();
      })
      .catch(err => {
        dispatch(fetchLoanError(err));
        done();
      })
  }
});

const saveLoanLogic = createLogicWithApi({
  type: [SAVE_LOAN, SAVE_LOAN_INTERNAL_NOTES ],
  cancelType: CANCEL_SAVE_LOAN,
  process: ({action, Api, getState}, dispatch, done) => {
    const state = getState();
    const method = action.payload.id ? 'PATCH': 'POST';

    if (action.payload.id) {
      dispatch(saveLoanProgress(
        'started',
        {
          id: action.payload.id,
          progress: 0
        }
      ));
    }

    return Api({
      method,
      path: `${locationsApiConfig.path}/${state.table.currentLocation}${apiConfig.path}${action.payload.id ? `/${action.payload.id}` : ''}${action.type === SAVE_LOAN_INTERNAL_NOTES ? apiConfig.internalNotesPath : ''}`,
      handle: r => {
        return r.send(
          action.payload.id ?
            {
              attributes: action.payload,
              type: 'loan',
              id: action.payload.id ? action.payload.id : null
            } : {
              attributes: {
                ...action.payload
              }
            }
        );
      }
    })
      .then(resp => {
        if (!action.payload.id) {
          dispatch(resetLoanItems());
        }

        if (resp && resp.statusCode && resp.statusCode !== 202) {
          dispatch(saveLoanProgress(
            'finished_success',
            {
              id: resp.data.id,
              progress: 100
            }
          ));
        }

        dispatch(saveLoanSuccess(resp, action.nextStep, action.payload.id));
        done();
      })
      .catch(err => {
        dispatch(saveLoanError(action.payload.id, err));
        done();
      })
  }
});

const saveLoanSuccessLogic = createLogicWithRouteChecks({
  type: SAVE_LOAN_SUCCESS,
  process: ({ getUrl, state, action }, dispatch, done) => {
    if (action.nextStep) {
      dispatch(push(`${getUrl('loans')}/${state.table.currentLocation}/${action.payload.data.id}${state.locale.actions.create.path}/${action.nextStep}${window.location.search}`));

    } else {
      dispatch(push(`${getUrl('loans')}/${state.table.currentLocation}/${action.payload.data.id}${window.location.search}`));
    }

    done();
  }
});

const saveLoanProgressLogic = createLogic({
  type: SAVE_LOAN_PROGRESS,
  process: ({ action, getState }, dispatch, done) => {
    if (action.status.indexOf('finished_') === 0) {
      const { loans } = getState();
      const current = loans.meta.processing.find(p => String(p.id) === String(action.data.id));

      if (current && current.loanShouldBeClosed) {
        dispatch(closeLoan({ id: action.data.id }))

      } else if (loans && loans.selected && String(loans.selected.id) === String(action.data.id)) {
        dispatch(fetchLoan({ id:action.data.id }))
      }
    }

    done();
  }
});

const removeLoanLogic = createLogicWithApi({
  type: REMOVE_LOAN,
  cancelType: CANCEL_REMOVE_LOAN,
  latest: true,
  process: ({ getState, action, Api }, dispatch, done) => {
    const state = getState(),
      location = state.table.currentLocation;

    return Api({
      method: 'DELETE',
      path: `${locationsApiConfig.path}/${location}${apiConfig.path}/${action.id}`,
    })
      .then(() => {
        dispatch(removeLoanSuccess({id: action.id}));
        done();
      })
      .catch(err => {
        dispatch(removeLoanError(err));
        done();
      })
  }
});

const checkRemoveLoanLogic = createLogicWithRouteChecks({
  type: REMOVE_LOAN_SUCCESS,
  process: ({ getState }, dispatch, done) => {
    var state = getState();
    var location = state.table.currentLocation || state.router.location.pathname.split('/')[2] || null;

    dispatch(
      push(`${apiConfig.path}/${location}${window.location.search}`)
    );

    done();
  }
});

const closeLoanLogic = createLogicWithApi({
  type: CLOSE_LOAN,
  cancelType: CANCEL_CLOSE_LOAN,
  latest: true,
  process: ({ getState, action, Api }, dispatch, done) => {
    const state = getState(),
          location = state.table.currentLocation,
          loan = Api({
            method: 'POST',
            path: `${locationsApiConfig.path}/${location}${apiConfig.path}/${action.id}${apiConfig.closePath}`,
          }),
          items = Api({
            method: 'GET',
            path: `${locationsApiConfig.path}/${location}${apiConfig.path}/${action.id}${apiConfig.itemsPath}`,
            params: {
              limit: 0,
              filters: {
                state: 'temporary,published'
              }
            }
          });
    return Promise.all([loan, items])
      .then(resp => {
        dispatch(closeLoanSuccess(resp[0].data.id, resp[0]));
        dispatch(fetchLoanItemsSuccess(resp[1]));
        done();
      })
      .catch(err => {
        dispatch(closeLoanError(err));
        done();
      })
  }
});

const scannerActionLoanOverviewLogic = createLogicWithRouteChecks({
  type: SCANNER_ACTION_LOAN_OVERVIEW,
  process: ({ getUrl, state, action }, dispatch, done) => {
    if (action.payload.data.attributes.lastLoanId) {
      dispatch(push(`${getUrl('loans')}/${state.table.currentLocation}/${action.payload.data.attributes.lastLoanId}${state.locale.actions.deliver.path}${window.location.search}`));
    } else {
      dispatch(showNotification(state.locale.loans.messages.instance_not_in_loan));
    }
    dispatch(equipmentScanCompleted());
    done();
  }
});

const logics = [
  checkLoansRouterLogic,
  fetchLoansLogic,
  checkFetchedLoansSelectionLogic,
  fetchLoanLogic,
  saveLoanLogic,
  saveLoanSuccessLogic,
  saveLoanProgressLogic,
  removeLoanLogic,
  checkRemoveLoanLogic,
  closeLoanLogic,
  scannerActionLoanOverviewLogic,
  ...LoanItemsLogic
];

export default logics;