export const SHOW_CONFIRM = '@@dialogs/SHOW_CONFIRM';
export const CANCEL_CONFIRM = '@@dialogs/CANCEL_CONFIRM';
export const CLOSE_CONFIRM = '@@dialogs/CLOSE_CONFIRM';
export const SHOW_NOTIFICATION = '@@dialogs/SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = '@@dialogs/HIDE_NOTIFICATION';
export const CLEAR_NOTIFICATIONS = '@@dialogs/CLEAR_NOTIFICATIONS';

export const showConfirm = payload => ({
  type: SHOW_CONFIRM,
  payload
});

export const cancelConfirm = () => ({
  type: CANCEL_CONFIRM
});

export const closeConfirm = () => ({
  type: CLOSE_CONFIRM
});

export const showNotification = payload => ({
  type: SHOW_NOTIFICATION,
  payload
});

export const hideNotification = payload => ({
  type: HIDE_NOTIFICATION,
  payload
});

export const clearNotifications = () => ({
  type: CLEAR_NOTIFICATIONS
});
