import { omit } from 'lodash';
import { replace, push } from '@lagunovsky/redux-react-router';

import { createLogicWithApi, createLogicWithRouteChecks } from '../../../../shared/logicCreators';
import { URL_CHANGE } from '../../../../shared/components/App/App.actions';
import {
  categories as categoryApiConfig,
  equipmentAttributes as equipmentAttributesApiConfig,
  variantAttributes as variantAttributesApiConfig,
  images as imagesApiConfig
} from '../../../config/api';
import {
  CATEGORIES_FORM_STEP,
  FETCH_CATEGORIES,
  CANCEL_FETCH_CATEGORIES,
  fetchCategoriesSuccess,
  fetchCategoriesError,
  FETCH_CATEGORIES_SUCCESS,
  selectCategory,
  fetchCategory,
  FETCH_CATEGORY,
  CANCEL_FETCH_CATEGORY,
  fetchCategorySuccess,
  fetchCategoryError,
  SAVE_CATEGORY,
  CANCEL_SAVE_CATEGORY,
  saveCategorySuccess,
  saveCategoryError,
  SAVE_CATEGORY_SUCCESS,
  SAVE_CATEGORIES,
  CANCEL_SAVE_CATEGORIES,
  saveCategoriesSuccess,
  saveCategoriesError,
  SAVE_EQUIPMENT_ATTRIBUTE,
  CANCEL_SAVE_EQUIPMENT_ATTRIBUTE,
  saveEquipmentAttributeSuccess,
  saveEquipmentAttributeError,
  SAVE_VARIANT_ATTRIBUTE,
  CANCEL_SAVE_VARIANT_ATTRIBUTE,
  saveVariantAttributeSuccess,
  saveVariantAttributeError,
  removeCategoryError,
  REMOVE_CATEGORY,
  CANCEL_REMOVE_CATEGORY,
  REMOVE_CATEGORY_SUCCESS,
  SAVE_CATEGORY_IMAGE,
  CANCEL_SAVE_CATEGORY_IMAGE,
  saveCategoryImageSuccess,
  saveCategoryImageError,
  DELETE_CATEGORY_IMAGE,
  CANCEL_DELETE_CATEGORY_IMAGE,
  deleteCategoryImageSuccess,
  deleteCategoryImageError,
  PATCH_CATEGORY_IMAGES,
  CANCEL_PATCH_CATEGORY_IMAGES,
  patchCategoryImagesSuccess,
  patchCategoryImagesError,
  removeEquipmentAttributeError,
  removeEquipmentAttributeSuccess,
  REMOVE_EQUIPMENT_ATTRIBUTE,
  CANCEL_REMOVE_EQUIPMENT_ATTRIBUTE,
  removeVariantAttributeError,
  removeVariantAttributeSuccess,
  REMOVE_VARIANT_ATTRIBUTE,
  CANCEL_REMOVE_VARIANT_ATTRIBUTE,
  fetchCategories,
  SAVE_EQUIPMENT_ATTRIBUTE_SUCCESS,
  SAVE_VARIANT_ATTRIBUTE_SUCCESS,
  REMOVE_EQUIPMENT_ATTRIBUTE_SUCCESS,
  REMOVE_VARIANT_ATTRIBUTE_SUCCESS,
  SAVE_EQUIPMENT_ATTRIBUTES,
  CANCEL_SAVE_EQUIPMENT_ATTRIBUTES,
  saveEquipmentAttributesSuccess,
  saveEquipmentAttributesError,
  SAVE_VARIANT_ATTRIBUTES,
  CANCEL_SAVE_VARIANT_ATTRIBUTES,
  saveVariantAttributesSuccess,
  saveVariantAttributesError,
} from '../../../../shared/components/Categories/Categories.actions';

const fetchCategoriesLogic = createLogicWithApi({
  type: FETCH_CATEGORIES,
  cancelType: CANCEL_FETCH_CATEGORIES,
  latest: true,
  process: ({ Api, action, getState }, dispatch, done) => {
    return Api({
      method: 'get',
      path: categoryApiConfig.path,
      handle: r => {
        const state = getState();
        const filters = typeof action.filters !== 'undefined' 
          ? action.filters 
          : state.table.filters;

        r
          .query({'limit': 0})
          .query({'filters': filters});

        return r;
      }
    })
    .then(resp => {
      dispatch(fetchCategoriesSuccess(resp));
      done();
    })
    .catch(err => {
      dispatch(fetchCategoriesError(err));
      done();
    });
  }
});

const checkFetchedCategorySelectionLogic = createLogicWithRouteChecks({
  type: FETCH_CATEGORIES_SUCCESS,
  process: ({ checkInitSelect, getUrl }, dispatch, done) => {
    const selectId = checkInitSelect('categories');

    if (selectId) {
      dispatch(replace(`${getUrl('categories')}/${selectId}${window.location.search}`));
    }

    done();
  }
});

const checkLocationRouteLogic = createLogicWithRouteChecks({
  type: URL_CHANGE,
  process: ({ checkUnselectedIdParam, checkInitSelect, getUrl }, dispatch, done) => {
    const selectId = checkInitSelect('categories');

    if (selectId) {
      dispatch(replace(`${getUrl('categories')}/${selectId}${window.location.search}`));

    } else {
      const newIdParam = checkUnselectedIdParam('categories');

      if (newIdParam !== false) {
        dispatch(selectCategory(newIdParam));
        dispatch(fetchCategory(newIdParam));
      }
    }

    done();
  }
});

const fetchCategoryLogic = createLogicWithApi({
  type: FETCH_CATEGORY,
  cancelType: CANCEL_FETCH_CATEGORY,
  latest: true,
  process: ({ action, Api }, dispatch, done) => {
    return Api({
      method: 'get',
      path: `${categoryApiConfig.path}/${action.id}`
    })
    .then(resp => {
      dispatch(fetchCategorySuccess(resp));
      done();
    })
    .catch(err => {
      dispatch(fetchCategoryError(err));
      done();
    });
  }
});

const saveCategoryLogic = createLogicWithApi({
  type: SAVE_CATEGORY,
  cancelType: CANCEL_SAVE_CATEGORY,
  process: ({ action, Api }, dispatch, done) => {
    const method = action.payload.id ? 'patch' : 'post',
          path = `${categoryApiConfig.path}${action.payload.id ? `/${action.payload.id}` : ''}`;
    return Api({
      method: method,
      path: path,
      handle: r => {
        return r.send({
          attributes: action.payload,
          type: 'category',
          id: action.payload.id ? action.payload.id : null
        });
      }
    })
    .then(resp => {
      dispatch(saveCategorySuccess(resp, action.nextStep));
      done();
    })
    .catch(err => {
      dispatch(saveCategoryError(err));
      done();
    });
  }
});

const saveCategoriesLogic = createLogicWithApi({
  type: SAVE_CATEGORIES,
  cancelType: CANCEL_SAVE_CATEGORIES,
  process: ({ action, Api }, dispatch, done) => Api({
    method: 'patch',
    path: `${categoryApiConfig.path}${categoryApiConfig.listPath}`,
    handle: r => {
      return r.send(action.payload);
    }
  })
  .then(resp => {
    dispatch(saveCategoriesSuccess(resp));
    done();
  })
  .catch(err => {
    dispatch(saveCategoriesError(err));
    done();
  })
});

const saveEquipmentAttributesLogic = createLogicWithApi({
  type: SAVE_EQUIPMENT_ATTRIBUTES,
  cancelType: CANCEL_SAVE_EQUIPMENT_ATTRIBUTES,
  process: ({action, Api}, dispatch, done) => {
    let {payload: {category, attributes}} = action;

    return Api({
      method: 'post',
      path: `${categoryApiConfig.path}/${category}${equipmentAttributesApiConfig.listPath}`,
      handle: r => {
        return r.send({
          attributes: {
            equipmentAttributes: attributes
          }
        })
      }
    })
    .then(resp => {
      dispatch(saveEquipmentAttributesSuccess(resp));
      done();
    })
    .catch(err => {
      dispatch(saveEquipmentAttributesError(err));
      done();
    });
  }
});

const saveEquipmentAttributeLogic = createLogicWithApi({
  type: SAVE_EQUIPMENT_ATTRIBUTE,
  cancelType: CANCEL_SAVE_EQUIPMENT_ATTRIBUTE,
  process: ({ action, Api }, dispatch, done) => {
    const method = action.payload.id ? 'patch' : 'post',
          path = `${categoryApiConfig.path}/${action.category}${equipmentAttributesApiConfig.path}${action.payload.id ? `/${action.payload.id}` : ''}`;
    return Api({
      method: method,
      path: path,
      handle: r => {
        return r.send({
          attributes: {
            label: action.payload.attributes.label,
            required: action.payload.attributes.required,
            values: action.payload.attributes.values.map(value => ({
              value: value
            }))
          },
          type: 'equipment_attribute',
          id: action.payload.id ? action.payload.id : null
        });
      }
    })
    .then(resp => {
      dispatch(saveEquipmentAttributeSuccess(resp, action.nextStep));
      done();
    })
    .catch(err => {
      dispatch(saveEquipmentAttributeError(err));
      done();
    });
  }
});

const saveVariantAttributesLogic = createLogicWithApi({
  type: SAVE_VARIANT_ATTRIBUTES,
  cancelType: CANCEL_SAVE_VARIANT_ATTRIBUTES,
  process: ({action, Api}, dispatch, done) => {
    let {payload: {category, attributes}} = action;

    return Api({
      method: 'post',
      path: `${categoryApiConfig.path}/${category}${variantAttributesApiConfig.listPath}`,
      handle: r => {
        return r.send({
          attributes: {
            variantAttributes: attributes
          }
        })
      }
    })
    .then(resp => {
      dispatch(saveVariantAttributesSuccess(resp));
      done();
    })
    .catch(err => {
      dispatch(saveVariantAttributesError(err));
      done();
    });
  }
});

const saveVariantAttributeLogic = createLogicWithApi({
  type: SAVE_VARIANT_ATTRIBUTE,
  cancelType: CANCEL_SAVE_VARIANT_ATTRIBUTE,
  process: ({ action, Api }, dispatch, done) => {
    const method = action.payload.id ? 'patch' : 'post',
          path = `${categoryApiConfig.path}/${action.category}${variantAttributesApiConfig.path}${action.payload.id ? `/${action.payload.id}` : ''}`;
    return Api({
      method: method,
      path: path,
      handle: r => {
        return r.send({
          attributes: {
            label: action.payload.attributes.label,
            required: action.payload.attributes.required,
            values: action.payload.attributes.values.map(value => ({
              value: value
            }))
          },
          type: 'variant_attribute',
          id: action.payload.id ? action.payload.id : null
        });
      }
    })
    .then(resp => {
      dispatch(saveVariantAttributeSuccess(resp, action.nextStep));
      done();
    })
    .catch(err => {
      dispatch(saveVariantAttributeError(err));
      done();
    });
  }
});

const saveCategorySuccessLogic = createLogicWithRouteChecks({
  type: [SAVE_CATEGORY_SUCCESS, CATEGORIES_FORM_STEP],
  process: ({ getUrl, state, action }, dispatch, done) => {
    const category = action.id || action.payload.data.id;

    if (action.nextStep) {
      if (action.nextStep === 2) {
        dispatch(replace(`${getUrl('categories')}/${category}${state.locale.actions.create.path}/1${window.location.search}`));
      }

      dispatch(push(`${getUrl('categories')}/${category}${state.locale.actions.create.path}/${action.nextStep}${window.location.search}`));

    } else {
      dispatch(push(`${getUrl('categories')}/${category}${window.location.search}`));
    }
    done();
  }
});

var isRefetched = false;

const refetchCategoriesLogic = createLogicWithApi({
  type: [
    SAVE_CATEGORY_SUCCESS,
    SAVE_EQUIPMENT_ATTRIBUTE_SUCCESS,
    SAVE_VARIANT_ATTRIBUTE_SUCCESS,
    REMOVE_CATEGORY_SUCCESS,
    REMOVE_EQUIPMENT_ATTRIBUTE_SUCCESS,
    REMOVE_VARIANT_ATTRIBUTE_SUCCESS
  ],
  process: (Api, dispatch, done) => {
    isRefetched = true;

    /**
     * TODO: use timeout to prevent double fetching categories
     */
    setTimeout(() => {
      if (isRefetched) {
        dispatch(fetchCategories());
        done();
      }

      isRefetched = false;
    }, 2000);
  }
});

const removeCategoryLogic = createLogicWithApi({
  type: REMOVE_CATEGORY,
  cancelType: CANCEL_REMOVE_CATEGORY,
  latest: true,
  process: ({ action, Api }, dispatch, done) => {
    return Api({
      method: 'DELETE',
      path: `${categoryApiConfig.path}/${action.id}`,
      handle: r => {
        if (action.data) return r.send({ ...action.data });
      },
    })
    .then(() => done())
    .catch(err => {
      dispatch(removeCategoryError(err));
      done();
    });
  },
});

const checkRemoveCategoryLogic = createLogicWithRouteChecks({
  type: REMOVE_CATEGORY_SUCCESS,
  process: ({ action }, dispatch, done) => {
    dispatch(
      push(`${categoryApiConfig.path}/${action.targetCategory}`),
    );

    done();
  },
});

const saveCategoryImageLogic = createLogicWithApi({
  type: SAVE_CATEGORY_IMAGE,
  cancelType: CANCEL_SAVE_CATEGORY_IMAGE,
  process: ({ action, Api }, dispatch, done) => {
    const method = action.payload.id ? 'PATCH' : 'POST',
      path = `${categoryApiConfig.path}/${action.category}${imagesApiConfig.path}${action.payload.id ? `/${action.payload.id}` : ''}`;
    return Api({
      method: method,
      path: path,
      handle: r => {
        if (action.payload.imageFile) {
          r
            .field('data[attributes][image][state]', 'published')
            .attach('data[attributes][image][imageFile]',action.payload.imageFile);
        } else {
          r.send({
            attributes: { image: omit(action.payload, ['id']) },
            type: 'category_image',
            id: action.payload.id ? action.payload.id : null
          });
        }
        return r;
      }
    })
      .then(resp => {
        dispatch(saveCategoryImageSuccess(action.category, resp));
        done();
      })
      .catch(err => {
        dispatch(saveCategoryImageError(action.category, err));
        done();
      });
  }
});

const deleteCategoryImageLogic = createLogicWithApi({
  type: DELETE_CATEGORY_IMAGE,
  cancelType: CANCEL_DELETE_CATEGORY_IMAGE,
  process: ({ action, Api }, dispatch, done) => {
    return Api({
      method: 'DELETE',
      path: `${categoryApiConfig.path}/${action.category}${imagesApiConfig.path}/${action.image}`
    })
      .then(() => {
        dispatch(deleteCategoryImageSuccess(action.category, action.image));
        done();
      })
      .catch(err => {
        dispatch(deleteCategoryImageError(action.category, err));
        done();
      });
  }
});

const patchCategoryImagesLogic = createLogicWithApi({
  type: PATCH_CATEGORY_IMAGES,
  cancelType: CANCEL_PATCH_CATEGORY_IMAGES,
  process: ({ action, Api }, dispatch, done) => {
    return Api({
      method: 'patch',
      path: `${categoryApiConfig.path}/${action.category}${imagesApiConfig.path}`,
      handle: r => {
        return r.send(action.images);
      }
    })
      .then(() => {
        dispatch(patchCategoryImagesSuccess(action.category, action.images));
        done();
      })
      .catch(err => {
        dispatch(patchCategoryImagesError(action.category, err));
        done();
      });
  }
});

const removeEquipmentAttributeLogic = createLogicWithApi({
  type: REMOVE_EQUIPMENT_ATTRIBUTE,
  cancelType: CANCEL_REMOVE_EQUIPMENT_ATTRIBUTE,
  latest: true,
  process: ({ action, Api }, dispatch, done) => {
    let {
      id,
      category
    } = action;

    return Api({
      method: 'DELETE',
      path: `${categoryApiConfig.path}/${category}/equipment-attributes/${id}`,
    })
      .then(() => {
        dispatch(removeEquipmentAttributeSuccess({id, category}));
        done();
      })
      .catch(err => {
        dispatch(removeEquipmentAttributeError(err));
        done();
      })
  }
});

const removeVariantAttributeLogic = createLogicWithApi({
  type: REMOVE_VARIANT_ATTRIBUTE,
  cancelType: CANCEL_REMOVE_VARIANT_ATTRIBUTE,
  latest: true,
  process: ({ action, Api }, dispatch, done) => {
    let {
      id,
      category
    } = action;

    return Api({
      method: 'DELETE',
      path: `${categoryApiConfig.path}/${category}/variant-attributes/${id}`,
    })
      .then(() => {
        dispatch(removeVariantAttributeSuccess({id, category}));
        done();
      })
      .catch(err => {
        dispatch(removeVariantAttributeError(err));
        done();
      })
  }
});

const logics = [
  fetchCategoriesLogic,
  checkFetchedCategorySelectionLogic,
  checkLocationRouteLogic,
  fetchCategoryLogic,
  saveCategoryLogic,
  saveCategoriesLogic,
  saveEquipmentAttributeLogic,
  saveVariantAttributeLogic,
  saveCategorySuccessLogic,
  removeCategoryLogic,
  checkRemoveCategoryLogic,
  saveCategoryImageLogic,
  deleteCategoryImageLogic,
  patchCategoryImagesLogic,
  removeEquipmentAttributeLogic,
  removeVariantAttributeLogic,
  refetchCategoriesLogic,
  saveEquipmentAttributesLogic,
  saveVariantAttributesLogic
];

export default logics;