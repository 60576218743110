import styled from 'styled-components';

const StyledInputField = styled.span`
  display: inline-block;
  margin: auto;
  animation: ${props => (props.$fadeStyle ? 'fade' : 'spin')} 1.4s infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes fade {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.2;
    }

    100% {
      opacity: 1;
    }
  }
`;

export default StyledInputField;
