import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';

import { getAdminAccess, getRoles } from '../../../../shared/components/common/AccessRights/AccessRights.selectors';
import withAccessRights from '../../../../shared/hoc/withAccessRights';
import { logout } from '../../../../shared/components/Login/Login.actions';
import { adminLinks } from '../../../config/app';
import routes from '../../../config/routes';
import Header from './Header.component';

class HeaderContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      publicLinks: [],
      adminLinks: [],
      subMenu: [{
        value: 'logout',
        label: <FormattedMessage id="header.logout" />
      }]
    };

    this.handleChangeSubMenu = this.handleChangeSubMenu.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.roles && !isEqual(nextProps.roles, this.props.roles)) {
      this.createLinkProps();
    }
  }

  handleChangeSubMenu({value}) {
    switch (value) {
      case 'logout':
        return this.props.clickLogout();
      default:
        return;
    }
  }

  createLinkProps () {
    let { intl } = this.props;

    let links = routes
      .filter(({inMenu, accessRights}) => {
        return !accessRights ? inMenu : this.props.checkAuthorization(accessRights.rules) && inMenu;
      })
      .map(({key, end}) => {
        return {
          key,
          end,
          to: intl.formatMessage({ id: `${key}.route.path` }),
          name: intl.formatMessage({ id: `${key}.route.name` }),
        };
      });

    this.setState({
      publicLinks: links.filter(({key}) => adminLinks.indexOf(key) === -1),
      adminLinks: links.filter(({key}) => adminLinks.indexOf(key) > -1)
    });
  }

  render() {
    return (
      <Header {...this.props}
        adminLinks={this.state.adminLinks}
        publicLinks={this.state.publicLinks}
        subMenu={this.state.subMenu}
        onChangeSubMenu={this.handleChangeSubMenu} />
    )
  }
}

const mapStateToProps = (state, { intl }) => {
  const adminAccess = getAdminAccess(state);
  const { login, locale } = state;
  const externalLinks = [];

  if (locale) {
    externalLinks.push(...locale.header.linkList);
  }

  if (adminAccess.toolboxPublic) {
    externalLinks.push({
      name: intl.formatMessage({id: 'dashboard.plates.toolboxPublic.title'}),
      path: window.TOOLBOX_PUBLIC_LOGIN_URL
    })
  }

  if (adminAccess.grafana) {
    externalLinks.push({
      name: intl.formatMessage({id: 'dashboard.plates.grafana.title'}),
      path: window.GRAFANA_LOGIN_URL
    })
  }

  if (adminAccess.toolboxAdmin) {
    externalLinks.push({
      name: intl.formatMessage({id: 'dashboard.plates.toolboxAdmin.title'}),
      path: window.TOOLBOX_ADMIN_LOGIN_URL
    })
  }

  if (adminAccess.craft) {
    externalLinks.push({
      name: intl.formatMessage({id: 'dashboard.plates.craft.title'}),
      path: window.CRAFT_LOGIN_URL
    })
  }

  return {
    login,
    roles: getRoles(state),
    email: login.data?.email || null,
    externalLinks
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    clickLogout: () => {
      dispatch(logout());
    }
  }
};

HeaderContainer.propTypes = {
  login: PropTypes.object,
  roles: PropTypes.object,
  intl: PropTypes.object,
  checkAuthorization: PropTypes.func,
  clickLogout: PropTypes.func,
  username: PropTypes.string
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withAccessRights(HeaderContainer))
);
