import { Auth0Client } from '@auth0/auth0-spa-js';

export const auth0Config = {
    domain: window.AUTH0_DOMAIN,
    clientId: window.AUTH0_CLIENT_ID,
    cacheLocation: 'localstorage',
};
  
export const authClient = new Auth0Client(auth0Config);

export const createAuthParams = () => ({
    authorizationParams: {
        redirect_uri: window.location.href,
        audience: window.AUTH0_AUDIENCE,
        appState: {
            redirect: `${window.location.pathname}`,
        },
    },
});

export const tokenCheck = async (Api) => {
    const token = await authClient.getTokenSilently(createAuthParams());
    Api.setTokens(token);
    return token;
};
