import styled from 'styled-components';

const StyledErrorMessage = styled.div`
  font-size: 12px;
  line-height: 1.24;
  overflow: hidden;
  color: ${props => props.theme.colorRed};
  padding: 4px 4px 0 0;

  &:empty {
    display: none;
  }
`;

export default StyledErrorMessage;
