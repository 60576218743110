export const FETCH_ORGANIZATIONS                = '@@organizations/FETCH_ORGANIZATIONS';
export const FETCH_ORGANIZATIONS_SUCCESS        = '@@organizations/FETCH_ORGANIZATIONS_SUCCESS';
export const FETCH_ORGANIZATIONS_ERROR          = '@@organizations/FETCH_ORGANIZATIONS_ERROR';
export const CANCEL_FETCH_ORGANIZATIONS         = '@@organizations/CANCEL_FETCH_ORGANIZATIONS';

export const FETCH_ORGANIZATION                 = '@@organizations/FETCH_ORGANIZATION';
export const FETCH_ORGANIZATION_SUCCESS         = '@@organizations/FETCH_ORGANIZATION_SUCCESS';
export const FETCH_ORGANIZATION_ERROR           = '@@organizations/FETCH_ORGANIZATION_ERROR';
export const CANCEL_FETCH_ORGANIZATION          = '@@organizations/CANCEL_FETCH_ORGANIZATION';
export const RESET_ORGANIZATION                 = '@@organizations/RESET_ORGANIZATION';

export const SAVE_ORGANIZATION                  = '@@organizations/SAVE_ORGANIZATION';
export const SAVE_ORGANIZATION_SUCCESS          = '@@organizations/SAVE_ORGANIZATION_SUCCESS';
export const SAVE_ORGANIZATION_ERROR            = '@@organizations/SAVE_ORGANIZATION_ERROR';
export const CANCEL_SAVE_ORGANIZATION           = '@@organizations/CANCEL_SAVE_ORGANIZATION';

export const REMOVE_ORGANIZATION                = '@@organizations/REMOVE_ORGANIZATION';
export const REMOVE_ORGANIZATION_SUCCESS        = '@@organizations/REMOVE_ORGANIZATION_SUCCESS';
export const REMOVE_ORGANIZATION_ERROR          = '@@organizations/REMOVE_ORGANIZATION_ERROR';
export const CANCEL_REMOVE_ORGANIZATION         = '@@organizations/CANCEL_REMOVE_ORGANIZATION';

export const SAVE_ORGANIZATION_FILE             = '@@organizations/SAVE_ORGANIZATION_FILE';
export const SAVE_ORGANIZATION_FILE_SUCCESS     = '@@organizations/SAVE_ORGANIZATION_FILE_SUCCESS';
export const SAVE_ORGANIZATION_FILE_ERROR       = '@@organizations/SAVE_ORGANIZATION_FILE_ERROR';
export const CANCEL_SAVE_ORGANIZATION_FILE      = '@@organizations/CANCEL_SAVE_ORGANIZATION_FILE';

export const REMOVE_ORGANIZATION_FILE           = '@@organizations/REMOVE_ORGANIZATION_FILE';
export const REMOVE_ORGANIZATION_FILE_SUCCESS   = '@@organizations/REMOVE_ORGANIZATION_FILE_SUCCESS';
export const REMOVE_ORGANIZATION_FILE_ERROR     = '@@organizations/REMOVE_ORGANIZATION_FILE_ERROR';
export const CANCEL_REMOVE_ORGANIZATION_FILE    = '@@organizations/CANCEL_REMOVE_ORGANIZATION_FILE';

export const OPEN_ORGANIZATION_FILE           = '@@organizations/OPEN_ORGANIZATION_FILE';
export const OPEN_ORGANIZATION_FILE_SUCCESS   = '@@organizations/OPEN_ORGANIZATION_FILE_SUCCESS';
export const OPEN_ORGANIZATION_FILE_ERROR     = '@@organizations/OPEN_ORGANIZATION_FILE_ERROR';

export const TABLE_SELECT_ORGANIZATION          = '@@organizations/TABLE_SELECT_ORGANIZATION';

export const SAVE_ORGANIZATIONS                 = '@@organizations/SAVE_ORGANIZATIONS';
export const SAVE_ORGANIZATIONS_SUCCESS         = '@@organizations/SAVE_ORGANIZATIONS_SUCCESS';
export const SAVE_ORGANIZATIONS_ERROR           = '@@organizations/SAVE_ORGANIZATIONS_ERROR';
export const CANCEL_SAVE_ORGANIZATIONS          = '@@organizations/CANCEL_SAVE_ORGANIZATIONS';

export const REMOVE_ORGANIZATIONS               = '@@organizations/REMOVE_ORGANIZATIONS';
export const REMOVE_ORGANIZATIONS_SUCCESS       = '@@organizations/REMOVE_ORGANIZATIONS_SUCCESS';
export const REMOVE_ORGANIZATIONS_ERROR         = '@@organizations/REMOVE_ORGANIZATIONS_ERROR';
export const CANCEL_REMOVE_ORGANIZATIONS        = '@@organizations/CANCEL_REMOVE_ORGANIZATIONS';

export const RESET_ORGANIZATIONS                = '@@organizations/RESET_ORGANIZATIONS';

export const selectOrganizationTable = (id) => ({
  type: TABLE_SELECT_ORGANIZATION,
  id
});

export const fetchOrganizations = (config) => ({
  type: FETCH_ORGANIZATIONS,
  ...config
});

export const fetchOrganizationsSuccess = (resp) => ({
  type: FETCH_ORGANIZATIONS_SUCCESS,
  payload: resp
});

export const fetchOrganizationsError = (err) => ({
  type: FETCH_ORGANIZATIONS_ERROR,
  payload: err
});

export const fetchOrganization = (id) => ({
  type: FETCH_ORGANIZATION,
  id
});

export const fetchOrganizationSuccess = (resp) => ({
  type: FETCH_ORGANIZATION_SUCCESS,
  payload: resp
});

export const fetchOrganizationError = (err) => ({
  type: FETCH_ORGANIZATION_ERROR,
  payload: err
});

export const saveOrganization = (payload, nextStep) => ({
  type: SAVE_ORGANIZATION,
  payload,
  nextStep
});

export const saveOrganizationSuccess = (payload, nextStep, id) => ({
  type: SAVE_ORGANIZATION_SUCCESS,
  payload,
  nextStep,
  id
});

export const saveOrganizationError = (err) => ({
  type: SAVE_ORGANIZATION_ERROR,
  payload: err
});


// SAVE ORGANIZATION FILE

export const saveOrganizationFile = (organizationId, file) => ({
  type: SAVE_ORGANIZATION_FILE,
  organizationId,
  payload: {
    file,
  }
});

export const saveOrganizationFileSuccess = (organizationId, payload) => ({
  type: SAVE_ORGANIZATION_FILE_SUCCESS,
  organizationId,
  payload,
});

export const saveOrganizationFileError = (err) => ({
  type: SAVE_ORGANIZATION_FILE_ERROR,
  payload: err
});

export const removeOrganizationFile = (organization, turnOffLoaderWhenFinish = true) => ({
  type: REMOVE_ORGANIZATION_FILE,
  turnOffLoader: turnOffLoaderWhenFinish,
  payload: organization
});

export const removeOrganizationFileSuccess = (payload) => ({
  type: REMOVE_ORGANIZATION_FILE_SUCCESS,
  payload
});

export const removeOrganizationFileError = (err) => ({
  type: REMOVE_ORGANIZATION_FILE_ERROR,
  payload: err
});

export const openOrganizationFile = () => ({
  type: OPEN_ORGANIZATION_FILE,
});

export const openOrganizationFileSuccess = () => ({
  type: OPEN_ORGANIZATION_FILE_SUCCESS,
});

export const openOrganizationFileError = () => ({
  type: OPEN_ORGANIZATION_FILE_ERROR,
});

// REMOVE ORGANIZATION

export const removeOrganization = (payload) => ({
  type: REMOVE_ORGANIZATION,
  payload
});

export const removeOrganizationSuccess = (payload) => ({
  type: REMOVE_ORGANIZATION_SUCCESS,
  payload
});

export const removeOrganizationError = (err) => ({
  type: REMOVE_ORGANIZATION_ERROR,
  payload: err
});

// REMOVE ORGANIZATIONS

export const resetOrganization = () => ({
  type: RESET_ORGANIZATION
});

export const resetOrganizations = () => ({
  type: RESET_ORGANIZATIONS
});
