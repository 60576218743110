import React from 'react';
import PropTypes from 'prop-types';

import StyledTextInput, { StyledTextInputWrapper } from '../TextInput/TextInput.styled';
import ErrorMessage from '../ErrorMessage/ErrorMessage.component';
import Check from '../../../../assets/icons/check';
import vars from '../../../../styles/variables';

const TextInput = ({
  // eslint-disable-next-line no-unused-vars
  meta,
  input,
  maxLength,
  success,
  invalid,
  ...props
}) => (
  <StyledTextInputWrapper $success={success}>
    <StyledTextInput {...input} {...props} maxLength={maxLength} $invalid={invalid || (meta?.touched && meta?.error)} />
    {meta && meta.touched && meta.error &&
      <ErrorMessage>
        {meta.error}
      </ErrorMessage>
    }
    {success && <Check stroke={vars.colorGreenDark} />}
  </StyledTextInputWrapper>
);

TextInput.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  maxLength: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  success: PropTypes.bool,
  invalid: PropTypes.bool
};

TextInput.defaultProps = {
  maxLength: 256
};

export default TextInput;
