import React from 'react';

const routeComponents = {
    login: React.lazy(() => import('../components/pages/Login/Login.component')),
    logout: React.lazy(() => import('../components/pages/Logout/Logout.component')),
    dashboard: React.lazy(() => import('../components/pages/Dashboard/Dashboard.container')),
    loans: React.lazy(() => import('../components/pages/Loans/Loans.container')),
    equipment: React.lazy(() => import('../components/pages/Equipment/Equipment.container')),
    reservations: React.lazy(() => import('../components/pages/Reservations/Reservations.container')),
    users: React.lazy(() => import('../components/pages/Users/Users.container')),
    categories: React.lazy(() => import('../components/pages/Categories/Categories.container')),
    locations: React.lazy(() => import('../components/pages/Locations/Locations.container')),
    settings: React.lazy(() => import('../components/pages/Settings/Settings.container')),
    staff: React.lazy(() => import('../components/pages/Staff/Staff.container')),
    organizations: React.lazy(() => import('../components/pages/Organizations/Organizations.container')),
};

export default routeComponents;