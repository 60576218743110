import 'core-js/actual';
import React, { useCallback, useEffect, useState } from 'react';
import { ReduxRouter } from '@lagunovsky/redux-react-router';
import { ThemeProvider } from 'styled-components';
import { ApolloProvider } from '@apollo/client';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import RouterToUrlQuery from '../../libs/react-url-query/react/RouterToUrlQuery';
import { configureUrlQuery } from '../../libs/react-url-query';
import flattenMessages from '../../locale/flattenMessages';
import AppComponent from '../App/App.component';
import client from '../../api/apollo-client';
import { LocaleLoader } from '../../locale';
import createStore from '../../store/store';
import Api from '../../api';
import { AuthProvider } from '../../auth';

const IndexComponent = ({ Component, options, store }) => {
  const { theme, routes, routeComponents, history, GlobalStyles } = options;

  const [ translations, setTranslations ] = useState({});

  const intlErrorHandler = useCallback((err) => {
    if (err.code === 'MISSING_TRANSLATION' && err.descriptor.defaultMessage) {
      return;
    }

    console.error(err);
  }, []);

  useEffect(() => {
    configureUrlQuery({ history });
  }, [ history ]);

  useEffect(() => {
    Api.cookieConfig = options.cookieConfig;
    Api.authConfig = options.authConfig;
    Api.setUrls({
      baseUrl: options.apiBaseUrl,
      refreshUrl: options.apiRefreshUrl
    });
  }, [ options ]);

  useEffect(() => {
    const { locales } = options;
    const localeUrl = `/${locales}${window.PACKAGE_VERSION ? `?c=${window.PACKAGE_VERSION}` : ''}`;

    LocaleLoader(localeUrl, store)
      .catch(err => {
        console.error('Failed loading locale data', err);
      })
      .then(translations => {
        setTranslations(flattenMessages(translations));
      });
  }, [ options, store ]);

  if (!translations || !Object.keys(translations).length) {
    return null;
  }

  return (
    <Provider store={ store }>
      <ApolloProvider client={ client }>
        <ThemeProvider theme={ theme }>
          <IntlProvider 
            locale="nb"
            messages={ translations } 
            onError={ intlErrorHandler }
          >
            <ReduxRouter history={ history }>
              <RouterToUrlQuery>
                <AuthProvider>
                  <AppComponent routes={ routes } routeComponents={ routeComponents }>
                    {({ routes: asyncRoutes }) => (
                      <>
                        <GlobalStyles />
                        <Component routes={ asyncRoutes } />
                      </>
                    )}
                  </AppComponent>
                </AuthProvider>
              </RouterToUrlQuery>
            </ReduxRouter>
          </IntlProvider>
        </ThemeProvider>
      </ApolloProvider>
    </Provider>
  );
};

IndexComponent.propTypes = {
  Component: PropTypes.func.isRequired,
  options: PropTypes.object,
  store: PropTypes.object,
};

IndexComponent.create = (Component, options) => {
  if (!options?.elementId) {
    throw new Error('Unable to instantiate the App. The \'elementId\' option is required');
  }

  const store = createStore(
    window && window.__PRELOADED_STATE__ 
      ? window.__PRELOADED_STATE__ 
      : {},
    options.middlewares,
    options.reducers
  );

  if (window.SENTRY_DSN) {
    Sentry.init({
      dsn: window.SENTRY_DSN,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }

  // TODO: get rid of ReactDOM.render approach when 'google-map-react' lib 
  // will support React 18 normally without markers flickering
  // Link to the issue: https://github.com/google-map-react/google-map-react/issues/1117
  ReactDOM.render(
    <IndexComponent 
      Component={ Component } 
      options={ options }
      store={ store }
    />,
    document.getElementById(options.elementId)
  );
}

export default IndexComponent;
