import { createGlobalStyle } from 'styled-components';

import vars from '../variables';

const AttributesInlineGlobalStyles = createGlobalStyle`
  .attributes-inline {
    list-style-type: none;
    margin: 0;
    padding: 0;
    color: ${vars.colorGrayDark};

    > li {
      display: inline-block;

      &::after {
        content: ",";
        display: inline-block;
        margin-right: 4px;
      }

      &:last-of-type {
        &::after {
          content: "";
        }
      }
    }
  }
`;

export default AttributesInlineGlobalStyles;