import { 
  SAVE_SETTINGS_SUCCESS as SAVE_SETTINGS_SUCCESS_VALUE, 
  FETCH_SETTINGS_SUCCESS as FETCH_SETTINGS_SUCCESS_VALUE, 
  FETCH_SETTINGS_OPENING_HOURS_SUCCESS as FETCH_SETTINGS_OPENING_HOURS_SUCCESS_VALUE 
} from '../../../../shared/config/locations';

export const FETCH_SETTINGS           = '@@settings/FETCH_SETTINGS';
export const FETCH_SETTINGS_SUCCESS   = FETCH_SETTINGS_SUCCESS_VALUE;
export const FETCH_SETTINGS_ERROR     = '@@settings/FETCH_SETTINGS_ERROR';
export const CANCEL_FETCH_SETTINGS    = '@@settings/CANCEL_FETCH_SETTINGS';

export const FETCH_SETTINGS_IMAGES_SUCCESS         = '@@settings/FETCH_SETTINGS_IMAGES_SUCCESS';
export const FETCH_SETTINGS_IMAGES_ERROR           = '@@settings/FETCH_SETTINGS_IMAGES_ERROR';

export const SAVE_SETTINGS           = '@@settings/SAVE_SETTINGS';
export const SAVE_SETTINGS_SUCCESS   = SAVE_SETTINGS_SUCCESS_VALUE;
export const SAVE_SETTINGS_ERROR     = '@@settings/SAVE_SETTINGS_ERROR';
export const CANCEL_SAVE_SETTINGS    = '@@settings/CANCEL_SAVE_SETTINGS';

export const SAVE_SETTINGS_OPENING_HOURS         = '@@settings/SAVE_SETTINGS_OPENING_HOURS';
export const SAVE_SETTINGS_OPENING_HOURS_SUCCESS   = '@@settings/SAVE_SETTINGS_OPENING_HOURS_SUCCESS';
export const SAVE_SETTINGS_OPENING_HOURS_ERROR     = '@@settings/SAVE_SETTINGS_OPENING_HOURS_ERROR';
export const CANCEL_SAVE_SETTINGS_OPENING_HOURS  = '@@settings/CANCEL_SAVE_SETTINGS_OPENING_HOURS';

export const FETCH_SETTINGS_OPENING_HOURS           = '@@settings/FETCH_SETTINGS_OPENING_HOURS';
export const FETCH_SETTINGS_OPENING_HOURS_SUCCESS   = FETCH_SETTINGS_OPENING_HOURS_SUCCESS_VALUE;
export const FETCH_SETTINGS_OPENING_HOURS_ERROR     = '@@settings/FETCH_SETTINGS_OPENING_HOURS_ERROR';
export const CANCEL_FETCH_SETTINGS_OPENING_HOURS    = '@@settings/CANCEL_FETCH_SETTINGS_OPENING_HOURS';

export const FETCH_SETTINGS_ENTRANCES           = '@@settings/FETCH_SETTINGS_ENTRANCES';
export const FETCH_SETTINGS_ENTRANCES_SUCCESS   = '@@settings/FETCH_SETTINGS_ENTRANCES_SUCCESS';
export const FETCH_SETTINGS_ENTRANCES_ERROR     = '@@settings/FETCH_SETTINGS_ENTRANCES_ERROR';
export const CANCEL_FETCH_SETTINGS_ENTRANCES    = '@@settings/CANCEL_FETCH_SETTINGS_ENTRANCES';

export const SAVE_SETTINGS_ENTRANCE         = '@@locations/SAVE_SETTINGS_ENTRANCE';
export const SAVE_SETTINGS_ENTRANCE_SUCCESS = '@@locations/SAVE_SETTINGS_ENTRANCE_SUCCESS';
export const SAVE_SETTINGS_ENTRANCE_ERROR   = '@@locations/SAVE_SETTINGS_ENTRANCE_ERROR';
export const CANCEL_SAVE_SETTINGS_ENTRANCE  = '@@locations/CANCEL_SAVE_SETTINGS_ENTRANCE';

export function fetchSettings(id) {
  return {
    type: FETCH_SETTINGS,
    id
  };
}

export function fetchSettingsSuccess(resp) {
  return {
    type: FETCH_SETTINGS_SUCCESS,
    payload: resp
  };
}

export function fetchSettingsError(err) {
  return {
    type: FETCH_SETTINGS_ERROR,
    payload: err
  };
}

export function fetchSettingsImagesSuccess(resp) {
  return {
    type: FETCH_SETTINGS_IMAGES_SUCCESS,
    payload: resp
  };
}

export function fetchSettingsImagesError(err) {
  return {
    type: FETCH_SETTINGS_IMAGES_ERROR,
    payload: err
  };
}

export function saveSettings(data, nextStep) {
  return {
    type: SAVE_SETTINGS,
    payload: data,
    nextStep
  };
}

export function saveSettingsSuccess(resp) {
  return {
    type: SAVE_SETTINGS_SUCCESS,
    payload: resp
  };
}

export function saveSettingsError(err) {
  return {
    type: SAVE_SETTINGS_ERROR,
    payload: err
  };
}

export function saveSettingsOpeningHours(id, data) {
  return {
    type: SAVE_SETTINGS_OPENING_HOURS,
    id,
    payload: data
  };
}

export const saveSettingsOpeningHoursError = (id, err) => ({
  type: SAVE_SETTINGS_OPENING_HOURS_ERROR,
  id,
  payload: err
});

export const fetchSettingsOpeningHours = id => ({
  type: FETCH_SETTINGS_OPENING_HOURS,
  id
});

export const fetchSettingsOpeningHoursSuccess = (id, payload) => ({
  type: FETCH_SETTINGS_OPENING_HOURS_SUCCESS,
  id,
  payload
});

export const fetchSettingsEntrances = id => ({
  type: FETCH_SETTINGS_ENTRANCES,
  id
});

export const fetchSettingsEntrancesSuccess = (id, data) => ({
  type: FETCH_SETTINGS_ENTRANCES_SUCCESS,
  id,
  payload: data,
});

export const fetchSettingsEntrancesError = (id, err) => ({
  type: FETCH_SETTINGS_ENTRANCES_ERROR,
  id,
  payload: err
});

export const saveSettingsEntrance = (id, data, saveLocation, nextStep) => ({
  type: SAVE_SETTINGS_ENTRANCE,
  id,
  payload: data,
  saveLocation,
  nextStep,
})

export const saveSettingsEntranceSuccess = (resp, id) => ({
  type: SAVE_SETTINGS_ENTRANCE_SUCCESS,
  id,
  payload: resp,
});

export const saveSettingsEntranceError = (id, err) => ({
  type: SAVE_SETTINGS_ENTRANCE_ERROR,
  id,
  payload: err
});