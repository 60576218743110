import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import momentTz from 'moment-timezone';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import moment from 'moment';
import 'moment/locale/nb';

import LoadingAnimation from '../common/LoadingAnimation/LoadingAnimation.component';
import { getRoles } from '../common/AccessRights/AccessRights.selectors';
import NoAccessPage from '../NoAccessPage/NoAccessPage.component';
import withAccessRights from '../../hoc/withAccessRights';
import { checkLoginToken } from '../Login/Login.actions';
import { timezone } from '../../config/app';
import { useAuth } from '../../auth';

import { urlChange, pathChange } from './App.actions';

const AppComponent = ({ routes, routeComponents, checkAuthorization, children }) => {
  const roles = useSelector(getRoles);
  const location = useLocation();
  const dispatch = useDispatch();
  const { checkAuth } = useAuth();
  const intl = useIntl();

  const [ createdAsyncRoutes, setCreatedAsyncRoutes ] = useState([]);
  
  const locationPrevRef = useRef();
  const rolesPrevRef = useRef();

  const createRoutes = useCallback(() => {
    return routes.map(route => {
      const { 
        key,
        reducer: reducerLoader, 
        accessRights, 
        ...routeConfig 
      } = route;

      const Component = routeComponents[key];

      return {...route, ...{
        path: intl.formatMessage({ id: `${key}.route.path` }),
        Component: checkAuth(
          accessRights && !checkAuthorization(accessRights.rules)
            ? NoAccessPage
            : () => (
              <React.Suspense fallback={<LoadingAnimation info="general.loading" />}>
                <Component loadReducers={ reducerLoader } routeConfig={ routeConfig } />
              </React.Suspense>
            ),
          route.loginRequired,
          route.logoutRequired
        ),
      }};
    });
  }, [ routes, intl, routeComponents, checkAuthorization, checkAuth ]);

  useEffect(() => {
    moment.locale('nb');
    momentTz.tz.setDefault(timezone);

    dispatch(checkLoginToken());
    setCreatedAsyncRoutes(createRoutes());
  }, [ dispatch, createRoutes ]);

  useEffect(() => {
    if (roles && !isEqual(roles, rolesPrevRef.current)) {
      setCreatedAsyncRoutes(createRoutes());

      rolesPrevRef.current = roles;
    }
  }, [ roles, createRoutes ]);

  useEffect(() => {
    if (location.pathname !== locationPrevRef.current?.pathname) {
      dispatch(pathChange(location));
    }
  }, [ location, location.pathname, dispatch ])

  useEffect(() => {
    dispatch(urlChange(location));
  }, [ location, dispatch ]);

  useEffect(() => {
    locationPrevRef.current = location;
  }, [ location ]);

  return children({ routes: createdAsyncRoutes });
}

AppComponent.propTypes = {
  routes: PropTypes.array.isRequired,
  children: PropTypes.func.isRequired,
  checkAuthorization: PropTypes.func,
};

export default withAccessRights(AppComponent);
